import { useState } from 'react';
import { Col } from 'react-bootstrap';

import { removeMarkdown } from '@/services/global';

import { IBooklet } from '../../../store/ducks/booklets/types';
import BgImage from '../global/Image/BgImage';
import ExpandBookletModal from './ExpandBooklet';

interface Props {
  booklet: IBooklet;
}

function BookletCard({ booklet }: Props) {
  const [showExpandBookletModal, setshowExpandBookletModal] = useState<boolean>(false);
  const handleCloseExpandBookletModal = () => setshowExpandBookletModal(false);

  return (
    <>
      <Col sm={6} md={4} lg={3} className="mb-4">
        <div className="booklet-card h-100 pt-3">
          <div className="booklet-wrapper" onClick={() => setshowExpandBookletModal(true)}>
            <div className="image-wrapper">
              <BgImage src={booklet.link} styles={{ height: '300px' }} />
            </div>

            <div className="d-flex justify-content-center mt-3">
              <p
                className="caption collapsed w-100 px-2"
                title={removeMarkdown(booklet.caption || '')}
              >
                {removeMarkdown(booklet.caption?.toUpperCase() ?? '')}
              </p>
            </div>
          </div>
        </div>
      </Col>

      <ExpandBookletModal
        show={showExpandBookletModal}
        booklet={booklet}
        handleClose={handleCloseExpandBookletModal}
      />
    </>
  );
}

export default BookletCard;
