import { useContext } from 'react';

import { DiscountContext, IDiscountContext } from '../components/contexts/DiscountContext';

export const useDiscountContext = (): IDiscountContext => {
  const context = useContext(DiscountContext);

  if (!context)
    throw new Error('Esse compomente deve ser usado dentro de um <Discount> componente.');

  return context;
};
