import { CSSProperties } from 'react';

interface Props {
  width: string;
  styles?: CSSProperties;
}

function TextLoad({ width, styles }: Props) {
  return <div className="skeleton-load text" style={{ width, ...styles }}></div>;
}

export default TextLoad;
