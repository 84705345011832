/**
 * Action types
 */
export enum TokenTypes {
  SET_TOKEN_VALIDATED = '@token/SET_TOKEN_VALIDATED',
}

/**
 * State type
 */
export interface ITokenState {
  readonly tokenValidated: boolean;
}
