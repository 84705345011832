/**
 * Action types
 */
export enum DeliveryMethodsTypes {
  LOAD_REQUEST = '@delivery/LOAD_REQUEST',
  LOAD_SUCCESS = '@delivery/LOAD_SUCCESS',
  LOAD_FAILURE = '@delivery/LOAD_FAILURE',
}

/**
 * Data Types
 */
export interface IDeliveryIntegration {
  id: number | string;
  name: string | null;
  price: number;
  integration: string;
  delivery_date: string | null;
  integration_id: number;
}

export interface IDeliveryMethodsState {
  readonly data: IDeliveryIntegration[];
  readonly loading: boolean;
  readonly error: boolean;
}
