import { memo, MouseEvent } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { RiMapPinLine } from 'react-icons/ri';

import ChooseCard from '@/resources/components/cart/shipment/chooseCard/ChooseCard';
import { IAddressResponse } from '@/routes/addresses';
import { getFullAddress } from '@/services/global';
import { IIntegration } from '@/store/ducks/integrations/types';
import { IAddress } from '@/store/ducks/order/types';

import StoreAddress from './StoreAddress';

interface Props {
  data: IIntegration | IAddressResponse;
  type: 'in-store' | 'address';
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
}

function IntegrationOption({ data, type, onClick }: Props) {
  const getAttributes = () => {
    if (type === 'in-store') {
      return { 'data-store-id': (data as IIntegration).shop_id };
    }

    return { 'data-address-id': data.id };
  };

  return (
    <button
      key={`item-${data.id}`}
      type="button"
      className={`btn-block m-0 ${type === 'in-store' ? 'btn-list-store' : 'btn-list-address'}`}
    >
      <ChooseCard
        key={data.id}
        atributes={getAttributes()}
        handleClick={(e: MouseEvent<HTMLDivElement>) => onClick(e)}
        styles={{ backgroundColor: '#e0e0e0', border: 'none' }}
        classes="w-100 mb-2 shadow-sm btn-list"
      >
        <div className="d-flex align-items-center m-0">
          <Container className="address-container">
            <Row className="d-flex align-items-center w-100">
              <Col xs={2} className="ml-1 d-flex justify-content-center">
                <RiMapPinLine size={24} />
              </Col>

              <Col>
                <Row className="address-wrapper mr-0 text-justify">
                  {type === 'in-store' && <StoreAddress integration={data as IIntegration} />}

                  {type === 'address' && (
                    <p className="m-0" style={{ fontSize: '14px', fontWeight: 450 }}>
                      {getFullAddress(data as IAddress, true)}
                    </p>
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </ChooseCard>
    </button>
  );
}

export default memo(IntegrationOption);
