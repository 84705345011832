interface Props {
  str: string;
  convert?: 'space-to-hifen' | 'hifen-to-space';
}

export const useSlug = ({ str, convert = 'space-to-hifen' }: Props) => {
  const cleanStr = str
    .replace(/%/g, '%25')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return convert === 'hifen-to-space' ? cleanStr.replace(/-/g, ' ') : cleanStr.replace(/ /g, '-');
};
