import { CSSProperties } from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';

import { useDiscountContext } from '../../hooks/useDiscountContext';

function Tag() {
  const { isAbsolute, style, textStyle, discount } = useDiscountContext();
  const absoluteStyle: CSSProperties = isAbsolute
    ? { position: 'absolute', top: '-8px', right: '-8px' }
    : {};

  return (
    <span
      className="discount"
      style={{
        color: '#fff',
        lineHeight: 1,
        letterSpacing: 0,
        textAlign: 'left',
        padding: '4px 10px',
        borderRight: 'none',
        backgroundColor: '#BD3415',
        borderRadius: '5px',
        fontWeight: 500,
        fontSize: '12px/20px',
        display: 'flex',
        ...absoluteStyle,
        ...style,
      }}
    >
      <AiOutlineArrowDown className="mr-1" />
      <span style={textStyle}>{discount}%</span>
    </span>
  );
}

export default Tag;
