import { createContext } from 'react';

export interface IShipmentCardContext {
  disabled: boolean;
  showModal: boolean;
  warningType: 'cliente' | 'forma de entrega' | 'pagamento';
  handleCloseModal: () => void;
  setShowModal: (show: boolean) => void;
}

export const ShipmentCardContext = createContext<IShipmentCardContext | null>(null);
