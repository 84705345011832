import { useSelector } from 'react-redux';

import { ApplicationState } from '../../store';
import { IOrder } from '../../store/ducks/order/types';

const useOrder = () => {
  const selectOrder = useSelector<ApplicationState, IOrder | null>((state) => state.order.data);

  return { order: selectOrder };
};

export { useOrder };
