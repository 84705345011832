import { action } from 'typesafe-actions';

import { ISearch, SearchesTypes } from './types';

export const loadRequest = () => action(SearchesTypes.LOAD_REQUEST);
export const loadSuccess = (data: ISearch) => action(SearchesTypes.LOAD_SUCCESS, data);
export const loadFailure = () => action(SearchesTypes.LOAD_FAILURE);
export const cleanData = () => action(SearchesTypes.CLEAN_DATA);
export const setTitleSearched = (title: string) =>
  action(SearchesTypes.SET_TITLE_SEARCHED, title.replace(/%/g, ' '));
