import './chooseCard.scss';

import { CSSProperties, MouseEvent, ReactNode } from 'react';
import { Card } from 'react-bootstrap';

interface Props {
  atributes?: {};
  classes?: string;
  children: ReactNode;
  styles?: CSSProperties;
  cardBodyClasses?: string;
  handleClick: (event: MouseEvent<HTMLDivElement>) => void;
}

function ChooseCard({ children, atributes, classes, styles, cardBodyClasses, handleClick }: Props) {
  return (
    <Card
      {...atributes}
      style={styles}
      className={`choose-card ${classes ?? ''}`}
      onClick={(e: MouseEvent<HTMLDivElement>) => handleClick(e)}
    >
      <Card.Body className={`p-1 ${cardBodyClasses ?? ''}`}>{children}</Card.Body>
    </Card>
  );
}

export default ChooseCard;
