/**
 * Action types
 */
export enum ShopsTypes {
  LOAD_REQUEST = '@shops/LOAD_REQUEST',
  LOAD_SUCCESS = '@shops/LOAD_SUCCESS',
  LOAD_FAILURE = '@shops/LOAD_FAILURE',
}

/**
 * Data Types
 */

export interface IShopsAddress {
  id: number;
  name: string;
  address: IAddress;
}

interface IAddress {
  zipcode: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
}

export interface IShopsState {
  readonly error: boolean;
  readonly loading: boolean;
  readonly data: IShopsAddress[];
}
