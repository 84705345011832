import Cookies from 'universal-cookie';

import api from '@/services/api';

const cookies = new Cookies();

export function getToken() {
  const token = cookies.get('token');
  return token ?? null;
}

export function setToken(token: string) {
  const expiresIn = 3600 * 1000; // one hour
  const expireDate = new Date(Date.now() + expiresIn);

  cookies.set('token', token, { path: '/', expires: expireDate, sameSite: 'lax' });
}

export const destroyToken = () => cookies.remove('token');

export async function setHeaderAuthorization(token: string = getToken()) {
  api.defaults.headers.authorization = `Bearer ${token}`;
}
