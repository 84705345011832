import { ReactNode, useEffect } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { canChangeOrder, canUseWebappInStatuses } from '../../../../../services/global';
import { ApplicationState } from '../../../../../store';
import { useShipmentCardContext } from '../../../../hooks/useShipmentCardContext';
import { useTextColor } from '../../../../hooks/useTextColor';
import { useWebContext } from '../../../../hooks/useWebContext';
import { ShipmentCardContext } from '../../../contexts/ShipmentCardContext';

interface ICard {
  title: string;
  children: ReactNode;
  footer?: ReactNode | null;
  actions?: boolean;
}

function CustomCard({ title, children, footer = null, actions = false }: ICard) {
  const { setShowIsCartBlockedModal } = useWebContext();
  const { disabled, warningType, setShowModal } = useShipmentCardContext();

  const selectColor = useSelector<ApplicationState, string>(
    (state) => state.settings.configs.color,
  );
  const textColor = useTextColor(selectColor);

  const customStyle = { backgroundColor: selectColor, borderColor: selectColor, color: textColor };
  const selectStatus = useSelector<ApplicationState, number | undefined>(
    (state) => state.order.data?.status,
  );

  return (
    <>
      <Card.Header style={customStyle}>{title}</Card.Header>

      <Card.Body>
        <div className="wrapper">
          <div className="left">{children}</div>

          {actions && (
            <div className="right my-auto">
              <div className="actions">
                <Button
                  size="sm"
                  className="btn btn-shipment"
                  style={customStyle}
                  disabled={disabled || !canUseWebappInStatuses({ status: selectStatus ?? 0 })}
                  onClick={() => {
                    const { isValid, showIsCartBlockedModal, blockOnlyProduts } = canChangeOrder({
                      type: warningType,
                      action: 'alterado',
                    });

                    if (isValid || (blockOnlyProduts && warningType !== 'cliente')) {
                      setShowModal(true);
                    }

                    if (showIsCartBlockedModal) setShowIsCartBlockedModal(true);
                  }}
                >
                  Mudar
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card.Body>

      {footer && <Card.Footer className="border-0 px-2 pb-2 pt-0">{footer}</Card.Footer>}
    </>
  );
}

interface IMyModal {
  id?: string;
  centered?: boolean;
  children?: ReactNode;
  size?: 'sm' | 'lg' | 'xl' | undefined;
}

function CustomModal({ id, children, centered = true, size = undefined }: IMyModal) {
  const { showModal, setShowModal, handleCloseModal } = useShipmentCardContext();

  useEffect(() => setShowModal(showModal), [showModal, setShowModal]);

  return (
    <Modal
      show={showModal}
      animation={false}
      centered={centered}
      size={size}
      onHide={() => {
        handleCloseModal();
        setShowModal(false);
      }}
      id={id ? `${id}-modal` : ''}
      className={id ? `${id}-modal` : ''}
    >
      {children}
    </Modal>
  );
}

interface Props {
  children?: ReactNode;
  disabled?: boolean;
  warningType: 'cliente' | 'forma de entrega' | 'pagamento';
  showModal: boolean;
  handleCloseModal?: () => void;
  setShowModal: (show: boolean) => void;
  isPaid?: boolean;
}

function ShipmentCard({
  children,
  disabled,
  warningType,
  showModal,
  setShowModal,
  handleCloseModal = () => {},
  isPaid,
}: Props) {
  return (
    <Card
      className={`shipment-card ${disabled && !isPaid ? 'disabled' : ''} ${isPaid ? 'paid' : ''}`}
    >
      <ShipmentCardContext.Provider
        value={{
          disabled: disabled ?? false,
          warningType,
          showModal,
          setShowModal,
          handleCloseModal,
        }}
      >
        {isPaid && (
          <div className="w-100 paid-alert-header">
            Pagamento realizado! <FaCheckCircle />
          </div>
        )}

        {children}
      </ShipmentCardContext.Provider>
    </Card>
  );
}

ShipmentCard.Modal = CustomModal;
ShipmentCard.Content = CustomCard;

export { ShipmentCard };
