import { createContext } from 'react';

import { IBooklet } from '../../../store/ducks/booklets/types';
import { ICategory } from '../../../store/ducks/categories/types';

export interface INavbarContext {
  pathname: string;
  textColor: string;
  booklets: IBooklet[];
  categories: ICategory[];
  highlightedCategories: ICategory[];
}

export const NavbarContext = createContext<INavbarContext | null>(null);
