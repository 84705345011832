import { IProductData } from '../../../../store/ducks/products/types';

interface Props {
  data: IProductData | null;
}

function ListSpecs({ data }: Props) {
  return (
    <>
      {data && (
        <ul className="list-unstyled mt-1" style={{ lineHeight: 1 }}>
          {data.spec?.size && (
            <li key={'spec-size'}>
              <small className="text-secondary">Tamanho: </small>
              <strong style={{ fontSize: '11pt' }}>{data.spec.size}</strong>
            </li>
          )}

          {data.spec?.color && (
            <li key={'spec-color'}>
              <small className="text-secondary">Cor: </small>
              <strong style={{ fontSize: '11pt' }}>{data.spec.color}</strong>
            </li>
          )}
        </ul>
      )}
    </>
  );
}

export default ListSpecs;
