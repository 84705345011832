import './description.scss';

import { CSSProperties } from 'react';

import Markdown from '../../global/Markdown';

interface Props {
  limit?: boolean;
  limitNumber?: number;
  description?: string | null;
}

function Description({ description, limit = false, limitNumber = 8 }: Props) {
  const descriptionStyle: CSSProperties = limit
    ? { WebkitLineClamp: limitNumber, display: '-webkit-box', WebkitBoxOrient: 'vertical' }
    : {};

  return (
    <>
      {description && (
        <div className="description-wrapper">
          <p className="description-text" style={descriptionStyle}>
            <Markdown text={description} />
          </p>
        </div>
      )}
    </>
  );
}

export default Description;
