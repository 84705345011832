import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Badge, Button, Col, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { IShopsAddress } from '@/store/ducks/shops/types';

import { setShipmentPickupAt } from '../../../../../routes/order';
import { getTimeBlocks, ITimeBlocks } from '../../../../../routes/schedules';
import { humanizeDate } from '../../../../../services/dates';
import { getFullAddress } from '../../../../../services/global';
import { ApplicationState } from '../../../../../store';
import { IShipment } from '../../../../../store/ducks/order/types';
import ChooseCard from '../../../cart/shipment/chooseCard/ChooseCard';
import { IAddressModalStep } from '../AddressModal';

interface Props {
  orderId: number;
  shopIdDst: number;
  shipment: IShipment;
  calendarDate: Date | null;
  setShowModal: (show: boolean) => void;
  setModalStep: (step: IAddressModalStep) => void;
}

function ListSchedules({
  orderId,
  shopIdDst,
  shipment,
  calendarDate,
  setShowModal,
  setModalStep,
}: Props) {
  const [timeBlocks, setTimeBlocks] = useState<ITimeBlocks[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [clickedTime, setClickedTime] = useState<string | null>(null);

  const selectShops = useSelector<ApplicationState, IShopsAddress[]>((state) => state.shops.data);
  const shopAddress = selectShops.find((shop) => shop.id === shipment.address.id);

  const handleChooseTime = (event: MouseEvent<HTMLDivElement>, buttonClicked: string) => {
    setDisabled(true);
    const { time } = event.currentTarget.dataset;
    const day = event.currentTarget.dataset.timeBlockDay?.split('T').shift();
    const date = `${day} ${time}`;

    setClickedTime(buttonClicked);

    setShipmentPickupAt(orderId, date).then(() => {
      setDisabled(false);
      setShowModal(false);
      setModalStep('type');
      toast('Você selecionou o horário!', { type: 'success' });
    });
  };

  const fillBlockTimes = useCallback(
    (date: string | null = null) => {
      const today = date ? new Date(date) : new Date();
      const tomorrow = new Date(today);
      const step: IAddressModalStep | null = date ? 'scheduling' : null;
      setIsLoading(true);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const getOnlyDate = (date: Date): string => date.toISOString().split('T').shift()!;

      getTimeBlocks(shopIdDst, getOnlyDate(today)).then((todayBlock) => {
        setTimeBlocks(todayBlock);
        if (step) setModalStep(step);
        setIsLoading(false);
      });
    },
    [shopIdDst, setModalStep],
  );

  useEffect(() => {
    const date = calendarDate ? calendarDate.toISOString() : null;

    fillBlockTimes(date);
  }, [calendarDate, fillBlockTimes]);

  return (
    <>
      {shipment && shipment.type === 'shop' && shopAddress && (
        <h6 className="d-flex justify-content-center text-break my-2">
          Endereço:{getFullAddress(shopAddress.address)}
        </h6>
      )}
      {isLoading ? (
        <div className="d-flex justify-content-center my-4">
          <span className="mr-2 text-secondary">Buscando horários</span>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          {timeBlocks.map((timeBlock) => {
            const formatUtcDay = (day: string): string => {
              const formatedDay = day.split('/').reverse().join('-');
              const timeNow = new Date().toTimeString().split(':');
              const formatedUTC = `${formatedDay}T${timeNow[0]}:${timeNow[1]}`;

              return new Date(formatedUTC).toISOString();
            };

            const { day } = humanizeDate(formatUtcDay(timeBlock.day));

            return (
              <div key={timeBlock.day}>
                {timeBlock.times.length > 0 && (
                  <Row className="d-flex justify-content-center">
                    <Col xs={12} className="text-center mb-1">
                      <Badge variant="secondary">{day}</Badge>
                    </Col>

                    {timeBlock.times.map((block) => {
                      const loadingBlock = clickedTime
                        ? timeBlock.times.find((block) => block.time === clickedTime)
                        : null;

                      const shouldShowLoading =
                        disabled && loadingBlock && loadingBlock.time === block.time;

                      return (
                        <Col xs={6} key={block.time}>
                          <button
                            key={`item-${block.time}`}
                            disabled={disabled}
                            type="button"
                            className="btn-block m-0"
                            style={{ background: 'transparent', border: 'none' }}
                          >
                            <ChooseCard
                              classes="text-center"
                              atributes={{
                                'data-time': block.time,
                                'data-time-block-day': formatUtcDay(timeBlock.day),
                              }}
                              handleClick={(e) => handleChooseTime(e, block.time)}
                            >
                              <div className="d-flex align-items-center justify-content-center m-0">
                                {shouldShowLoading && (
                                  <Spinner
                                    className="mx-2 float-left"
                                    variant="secondary"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                )}

                                <span>{block.time}</span>
                              </div>
                            </ChooseCard>
                          </button>
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </div>
            );
          })}
        </>
      )}

      <Row className="mt-2">
        <Col>
          <Button className="btn-block btn-success" onClick={() => setModalStep('calendar')}>
            Mais horários
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ListSchedules;
