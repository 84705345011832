import api from '../services/api';
import { store } from '../store';
import * as WorldActions from '../store/ducks/worlds/actions';
import { IWorld } from '../store/ducks/worlds/types';

const { dispatch } = store;

export const getWorld = async (worldId: number, shopId: number): Promise<IWorld | null> => {
  dispatch(WorldActions.loadRequest());

  return api
    .get<{ shop_id: number }, IWorld>(`/worlds/${worldId}/list`, {
      params: { shop_id: shopId },
    })
    .then((world) => {
      dispatch(WorldActions.loadSuccess(world));

      return world;
    })
    .catch((e) => {
      console.error(e);
      dispatch(WorldActions.loadFailure());

      return null;
    });
};
