import { IDeliveryDiscountData } from '@/store/ducks/deliveryDiscount/types';
import { IDeliveryIntegration } from '@/store/ducks/deliveryMethods/types';

import api from '../services/api';
import { store } from '../store';
import * as DeliveryDiscountActions from '../store/ducks/deliveryDiscount/actions';
import * as DeliveryActions from '../store/ducks/deliveryMethods/actions';
import * as IntegrationsActions from '../store/ducks/integrations/actions';
import { IIntegration } from '../store/ducks/integrations/types';

type GroupingType = 'communication' | 'general' | 'payment' | 'delivery' | 'packages' | 'erp';

const { dispatch } = store;

export const getIntegrations = async (shopId: number, grouping: GroupingType | null = null) => {
  return api.get<{ grouping: GroupingType }, IIntegration[]>(
    `/shops/${shopId}/integrations/filter`,
    {
      params: { grouping },
    },
  );
};

export const getStoresByCompany = async (companyId: number, grouping: GroupingType) => {
  return api
    .get<{ grouping: GroupingType; type: 'in-store' }, IIntegration[]>(
      `/companies/${companyId}/integrations/filter`,
      {
        params: { grouping, type: 'in-store' },
      },
    )
    .catch((e) => {
      console.error(e);
    });
};

export const getIntegrationsByAddress = async (orderId: number, addressId: number) => {
  dispatch(DeliveryActions.loadRequest());

  return api
    .get<{ orderId: number; address_id: number }, IDeliveryIntegration[]>(
      `/orders/${orderId}/shippings`,
      {
        params: { address_id: addressId },
      },
    )
    .then((methods) => dispatch(DeliveryActions.loadSuccess(methods)))
    .catch((e) => {
      console.error(e);
      dispatch(DeliveryActions.loadFailure());
    });
};

export const getAllDeliveryIntegrations = async (orderId: number) => {
  dispatch(IntegrationsActions.loadRequest());

  return api
    .get<{ order_id: number }, IIntegration[]>('/delivery-integrations', {
      params: { order_id: orderId },
    })
    .then((integrations) => dispatch(IntegrationsActions.loadSuccess(integrations)))
    .catch((e) => {
      console.error(e);
      dispatch(IntegrationsActions.loadFailure());
    });
};

export const getShippingDiscounts = async (shopId: number) => {
  dispatch(DeliveryDiscountActions.loadRequest());
  return api
    .get<{ shop_id: number }, IDeliveryDiscountData[]>(`/shops/${shopId}/delivery-discounts`, {
      params: { shop_id: shopId, filter: 'valid_dates' },
    })
    .then((discounts) => {
      dispatch(DeliveryDiscountActions.loadSuccess(discounts));
      return discounts;
    })
    .catch((e) => {
      console.error(e);
      dispatch(DeliveryDiscountActions.loadFailure());
    });
};
