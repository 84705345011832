import { brDays, brMonths } from '@/constants/dates';

interface IHumanizeDateReturn {
  day?: string | null;
  time: string | null;
}

interface IHumanizeDateReturn {
  day?: string | null;
  time: string | null;
}

const isSameDate = (date1: Date, date2: Date): boolean =>
  date1.toLocaleDateString() === date2.toLocaleDateString();

const isYesterday = (someDate: Date): boolean => {
  const yesterday = new Date();

  yesterday.setDate(yesterday.getDate() - 1);

  return isSameDate(someDate, yesterday);
};

const isToday = (someDate: Date): boolean => isSameDate(someDate, new Date());

const isTomorrow = (someDate: Date): boolean => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return isSameDate(someDate, tomorrow);
};

const renderDayDiff = (dayDiff: number, date: Date): string => {
  const now = new Date();
  const weekDiff = Math.abs(Math.floor(now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24 * 7));

  if (isYesterday(date)) return 'Ontem';
  if (isToday(date)) return 'Hoje';
  if (isTomorrow(date)) return 'Amanhã';
  if (dayDiff <= -1 && dayDiff > -6 && weekDiff === 0) return brDays[date.getDay()] || '';

  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const humanizeTime = (date: Date): string =>
  `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

export const humanizeDate = (dateStr: string | null): IHumanizeDateReturn => {
  if (!dateStr) return { day: null, time: null };

  const now = new Date();
  const date = new Date(dateStr);
  const diffInDays = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

  const day = renderDayDiff(diffInDays, date);
  const time = humanizeTime(date);

  return { day, time };
};

export const sumHoursToMinute = (dateStr: string, interval: number): string => {
  const date = new Date(dateStr);

  const millisecondsInMinute = 60000;
  const intervalMilliseconds = interval * millisecondsInMinute;

  const endTimeMilliseconds = date.getTime() + intervalMilliseconds;
  const endTime = new Date(endTimeMilliseconds);

  const hours = String(endTime.getHours()).padStart(2, '0');
  const minutes = String(endTime.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const humanizedDay = (dateStr: string | null): string | null => {
  if (!dateStr) return null;

  const date = new Date(dateStr);

  const formattedDate = `${date.getDate()} de ${brMonths[date.getMonth()]}`;

  return formattedDate;
};

export const formatDate = (date: string): string => {
  const dateObj = new Date(date);

  const day = String(dateObj.getUTCDate()).padStart(2, '0');
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
  const year = dateObj.getUTCFullYear();

  return `${day}/${month}/${year}`;
};
