import { PageContext } from '../../components/contexts/PageContext';
import { useTemplateByPage } from '../../hooks/useTemplateByPage';
import { useWebContext } from '../../hooks/useWebContext';
import Default from '../../themes/booklets/default/Default';
import NotFound from '../notFound/NotFound';

function Booklet() {
  const { configs } = useWebContext();

  const template = useTemplateByPage('booklet');

  const render = () => {
    switch (template?.template_name) {
      default:
        return <Default />;
    }
  };

  return (
    <>
      {configs.has_booklet ? (
        <PageContext.Provider value={{ template }}>{render()}</PageContext.Provider>
      ) : (
        <NotFound />
      )}
    </>
  );
}

export default Booklet;
