/**
 * Action types
 */
export enum DeliveryDiscountTypes {
  LOAD_REQUEST = '@deliveryDiscount/LOAD_REQUEST',
  LOAD_SUCCESS = '@deliveryDiscount/LOAD_SUCCESS',
  LOAD_FAILURE = '@deliveryDiscount/LOAD_FAILURE',
}

export interface IDeliveryDiscountData {
  id: number;
  max_distance: number;
  min_price_order: number;
  type_discount: 'free' | 'price';
  categories: { id: number; webapp_name: string | null; name: string }[];
  groups: { id: number; webapp_name: string | null; name: string }[];
  brand: string;
  end_date: string;
  start_date: string;
  price_discount: number;
  shop_id: number;
  name: string;
}

export interface IDeliveryDiscountState {
  readonly error: boolean;
  readonly loading: boolean;
  readonly data: IDeliveryDiscountData[];
}
