import './miniImages.scss';

import BgImage from '../../global/Image/BgImage';

interface Props {
  images: string[];
  imageSelected: string;
  changeImageSelected: (source: string) => void;
  styles?: {};
}

function MiniImages({ images, imageSelected, changeImageSelected, styles }: Props) {
  return (
    <ul className="mini-images list-unstyled" style={styles}>
      {images?.map((image, index) => {
        const isActive = imageSelected === image ? 'active' : '';

        return (
          <li
            key={index + image}
            onClick={() => changeImageSelected(image)}
            className={`mini-images-item ${isActive}`}
            onMouseEnter={() => changeImageSelected(image)}
          >
            <BgImage src={image} styles={{ height: '50px' }} />
          </li>
        );
      })}
    </ul>
  );
}

export default MiniImages;
