import { useContext } from 'react';

import { IWebContext, WebContext } from '../components/contexts/WebContext';

export const useWebContext = (): IWebContext => {
  const context = useContext(WebContext);

  if (!context) throw new Error('Esse compomente deve ser usado dentro de um <Web> componente.');

  return context;
};
