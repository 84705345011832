import { useContext } from 'react';

import { INavbarContext, NavbarContext } from '../components/contexts/NavbarContext';

export const useNavbarContext = (): INavbarContext => {
  const context = useContext(NavbarContext);

  if (!context)
    throw new Error('Esse compomente deve ser usado dentro de um <NavbarContext> componente.');

  return context;
};
