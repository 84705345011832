import { useCallback, useEffect, useState } from 'react';

import { IImageGroup } from '../../store/ducks/settings/types';
import { useDevice } from './useDevice';

interface Props {
  imageGroups: IImageGroup[] | undefined;
}

interface IDimensions {
  width: number;
  height: number;
}

export const useImageGroupsByDevice = ({
  imageGroups,
}: Props): { imageGroup: IImageGroup | undefined; width: number; height: number } => {
  const { device } = useDevice();

  const getImageGroup = useCallback(() => {
    const getLocateDevice = (locate: string) => locate.split('_').shift();

    return imageGroups?.find((group) => getLocateDevice(group.name) === device);
  }, [device, imageGroups]);

  const [imageGroup, setImageGroup] = useState<IImageGroup | undefined>(getImageGroup());
  const [dimensions, setDimensions] = useState<IDimensions>({ width: 0, height: 0 });

  useEffect(() => setImageGroup(getImageGroup()), [getImageGroup]);

  useEffect(() => {
    const getGroupDimensions = (locate: string) => {
      const defaultDimensions = { width: 0, height: 0 };

      try {
        const splitedLocate = locate.split('_').pop();
        const dimensions = splitedLocate !== '' ? splitedLocate!.split('x') : ['0', '0'];

        return { width: parseInt(dimensions[0] ?? '0'), height: parseInt(dimensions[1] ?? '0') };
      } catch (e) {
        return defaultDimensions;
      }
    };

    setDimensions(getGroupDimensions(imageGroup?.name ?? ''));
  }, [imageGroup]);

  return { imageGroup, ...dimensions };
};
