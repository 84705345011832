import { lazy, Suspense } from 'react';

import { generateRandomKey } from '../../../services/global';
import { IProductsGroupByCategory } from '../../../store/ducks/products/types';

const Products = lazy(() => import('../products/Products'));

interface Props {
  productsGroup: IProductsGroupByCategory[];
}

function ProductsByCategory({ productsGroup }: Props) {
  return (
    <>
      {productsGroup?.map((groupedProducts) => {
        return (
          <div key={`products-grouped-by-category-${groupedProducts.id}-${generateRandomKey()}`}>
            <Suspense fallback={<div />}>
              <Products keyName="product" products={groupedProducts.products} />
            </Suspense>
          </div>
        );
      })}
    </>
  );
}

export default ProductsByCategory;
