import { IIntegration } from '@/store/ducks/integrations/types';

export const getDeliveryStoreIntegrations = (integrations: IIntegration[]) => {
  return integrations.filter(
    (integration) => integration.grouping === 'delivery' && integration.type === 'in-store',
  );
};

export const getDeliveryTypeIntegrations = (integrations: IIntegration[]) => {
  return integrations.filter(
    (integration) => integration.grouping === 'delivery' && integration.type !== 'in-store',
  );
};
