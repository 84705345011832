import './index.scss';
import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import ScrollToTop from './resources/components/global/scrollToTop/ScrollToTop';
import SkeletonScreen from './resources/components/skeletonScreen/SkeletonScreen';
import { disableReactDevTools } from './services/disableReactDevTools';
import { persistor, store } from './store';

if (process.env.NODE_ENV === 'production') disableReactDevTools();

toast.configure({
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
});

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ScrollToTop>
            <SkeletonScreen />
          </ScrollToTop>
        </Router>
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
