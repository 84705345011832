import { lazy, Suspense, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Breadcrumb from '@/resources/components/global/breadcrumb/Breadcrumb';
import ListProductsLoad from '@/resources/components/loads/ListProductsLoad';
import { useWebContext } from '@/resources/hooks/useWebContext';

import { getWorld } from '../../../../routes/worlds';
import { ApplicationState } from '../../../../store';
import { IWorld } from '../../../../store/ducks/worlds/types';
import ImageGroups from '../../../components/imageGroups/ImageGroups';
import { useImageGroupsByName } from '../../../hooks/useImageGroupsByName';
import { usePageBreakpoints } from '../../../hooks/usePageBreakpoints';
import { usePageContext } from '../../../hooks/usePageContext';

const TemplateProductLists = lazy(
  () => import('../../../components/templateProductLists/TemplateProductLists'),
);

interface Props {
  worldId: number;
}

function Default({ worldId }: Props) {
  const { template } = usePageContext();
  const { breakpoints } = usePageBreakpoints();
  const { order } = useWebContext();

  const [world, setWorld] = useState<IWorld | null>(null);

  const imageGroupOne = useImageGroupsByName({
    locate: 'image_group_1',
    imageGroups: template?.image_groups,
  });
  const imageGroupTwo = useImageGroupsByName({
    locate: 'image_group_2',
    imageGroups: template?.image_groups,
  });
  const imageGroupThree = useImageGroupsByName({
    locate: 'image_group_3',
    imageGroups: template?.image_groups,
  });
  const imageGroupFour = useImageGroupsByName({
    locate: 'image_group_4',
    imageGroups: template?.image_groups,
  });

  const selectLoading = useSelector<ApplicationState, boolean>((state) => state.worlds.loading);

  useEffect(() => {
    if (order?.dst_shop_id) {
      setWorld(null);

      getWorld(worldId, order?.dst_shop_id).then((w) => {
        setWorld(w);
      });
    }
  }, [worldId, order?.dst_shop_id]);

  return (
    <>
      {template && imageGroupOne.length > 0 && (
        <Container fluid className="px-0 mb-4">
          <Row className="justify-content-center mx-0">
            <Col {...breakpoints} className="px-0 breakpoint-page position-relative">
              <Breadcrumb name={world?.name ?? '...'} />

              <ImageGroups imageGroups={imageGroupOne} type="carousel" slideClassName="banners" />
            </Col>
          </Row>
        </Container>
      )}

      <Container fluid>
        <Row className="justify-content-center">
          <Col {...breakpoints} className="px-md-0 breakpoint-page">
            {template && imageGroupTwo.length > 0 && (
              <ImageGroups imageGroups={imageGroupTwo} type="grid" />
            )}

            <Row className="mx-0">
              <Col xs={12} className="px-0">
                {[1, 2].map((position, index) => (
                  <Suspense fallback={<ListProductsLoad />} key={index}>
                    <TemplateProductLists
                      position={position}
                      world={world}
                      componentType="world"
                      worldIsLoading={selectLoading}
                      productList={template?.product_lists}
                    />
                  </Suspense>
                ))}

                {template && imageGroupThree.length > 0 && (
                  <ImageGroups imageGroups={imageGroupThree} type="grid" />
                )}

                <Suspense fallback={<ListProductsLoad />}>
                  <TemplateProductLists
                    position={3}
                    world={world}
                    componentType="world"
                    worldIsLoading={selectLoading}
                    productList={template?.product_lists}
                  />
                </Suspense>

                {template && imageGroupFour.length > 0 && (
                  <ImageGroups imageGroups={imageGroupFour} type="grid" />
                )}

                <Suspense fallback={<ListProductsLoad />}>
                  <TemplateProductLists
                    position={4}
                    world={world}
                    componentType="world"
                    worldIsLoading={selectLoading}
                    productList={template?.product_lists}
                  />
                </Suspense>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Default;
