interface Props {
  fullscreen?: boolean;
  classes?: string;
}

function CircleLoad({ fullscreen = false, classes = '' }: Props) {
  return (
    <div className={`skeleton-circle-load ${fullscreen ? 'fullscreen' : ''} ${classes}`}>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
    </div>
  );
}

export default CircleLoad;
