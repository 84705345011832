import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useBeginCheckout } from '@/resources/hooks/useBeginCheckout';

import { sendCartResume, setStatus } from '../../../../../routes/order';
import { OrderStatus } from '../../../../../services/global';
import { loadFailure } from '../../../../../store/ducks/order/actions';
import { IOrder } from '../../../../../store/ducks/order/types';
import ClosedOrderAlertModal from '../../orderAlertModal/ClosedOrderAlertModal';

interface Props {
  order: IOrder;
  handleFinishOrder: () => boolean;
  forwardedOrderAlert: (alert: boolean) => void;
}

function CloseOrder({ order, handleFinishOrder, forwardedOrderAlert }: Props) {
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [variant, setVariant] = useState<string>('success');
  const [btnLoad, setBtnLoad] = useState<boolean>(false);
  const [showClosedAlert, setShowClosedAlert] = useState<boolean>(false);

  const { payments, is_paid } = order;
  const isAllItemsAvaliable = order.cart.products.every((item) => item.is_available === true);

  const validPayments = payments?.filter((p) => !p.deleted_at && p.status === 'not-paid');

  const canFinishUnpaid = validPayments?.every((p) => p.integration?.data?.can_finish_unpaid);

  useEffect(() => {
    if (!canFinishUnpaid) setDisabled(true);
    else setDisabled(false);
  }, [canFinishUnpaid]);

  useEffect(() => {
    const isFinishAvailable =
      order?.status !== OrderStatus.OPEN && order?.status !== OrderStatus.ABANDONED;

    if (isFinishAvailable && order?.status !== OrderStatus.WAITING_SELLER) {
      setDisabled(true);
      setVariant('secondary');
    } else setVariant('success');
  }, [order?.status]);

  const finishOrder = () => {
    if (order?.status === OrderStatus.WAITING_SELLER) {
      setShowClosedAlert(true);
      return;
    }

    if (handleFinishOrder()) {
      setBtnLoad(true);

      setStatus(order.id, OrderStatus.WAITING_SELLER)
        .then(async () => {
          forwardedOrderAlert(true);
          useBeginCheckout(order);

          await sendCartResume(order.id, 'Resumo do Pedido');
        })
        .then(() => setBtnLoad(false))
        .catch((e) => {
          setBtnLoad(false);
          dispatch(loadFailure());
          toast(e?.response?.data?.message ?? 'Erro ao enviar pedido', {
            type: 'warning',
            position: 'top-center',
          });
        });
    }
  };

  return (
    <>
      {isAllItemsAvaliable && !is_paid && (
        <Button
          variant={variant}
          onClick={finishOrder}
          disabled={disabled || btnLoad}
          className="btn-block font-weight-bold mt-2"
        >
          Comprar
        </Button>
      )}

      <ClosedOrderAlertModal show={showClosedAlert} setShow={setShowClosedAlert} />
    </>
  );
}

export default CloseOrder;
