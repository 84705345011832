import { Field, Form, Formik, FormikProps } from 'formik';
import { ClipboardEvent, KeyboardEvent, useState } from 'react';
import { Button, Col, Container, FormControl, Row, Spinner } from 'react-bootstrap';
import { FiMapPin } from 'react-icons/fi';
import { IoIosArrowBack } from 'react-icons/io';
import usePlacesAutocomplete, { getDetails, Suggestion } from 'use-places-autocomplete';
import * as yup from 'yup';

import { IAddressModalStep } from '@/resources/components/global/address/AddressModal';
import { create, getAddresses, IAddressResponse } from '@/routes/addresses';

import { setShipmentAddress } from '../../../../../routes/order';
import ChooseCard from '../../../cart/shipment/chooseCard/ChooseCard';
import Error from '../../Form/Error';

export interface IAddressValues {
  zipcode: string;
  city: string;
  state: string;
  street: string;
  number: string;
  country: string;
  neighborhood: string;
  client_id: number;
}

interface IFillAddressProps {
  type: string;
  short_name: string;
  long_name: string;
}

interface Props {
  orderId: number;
  clientId: number;
  setModalStep: (step: IAddressModalStep) => void;
  setClientAddresses: (addresses: IAddressResponse[]) => void;
}

function SearchAddress({ orderId, clientId, setModalStep, setClientAddresses }: Props) {
  const [error, setError] = useState<string>('');
  const [addressInput, setAddressInput] = useState<string>('');
  const [submitButton, setSubmitButton] = useState<boolean>(false);
  const [addressSelected, setAddressSelected] = useState<boolean>(false);

  const schema = yup.object().shape({
    zipcode: yup
      .string()
      .required('CEP é obrigatório.')
      .transform((zipcode) => zipcode.replace('-', ''))
      .matches(/^\d{8}$/, 'Insira um cep válido.'),
    number: yup.string().required('Número obrigatório.'),
    city: yup.string(),
    state: yup.string(),
    street: yup.string(),
    country: yup.string(),
    neighborhood: yup.string(),
    complement: yup.string(),
    reference: yup.string(),
    client_id: yup.number().required(),
  });

  const {
    ready,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    cache: 3600,
    debounce: 300,
    requestOptions: { componentRestrictions: { country: 'br' } },
  });

  const handleSelect = ({ place_id }: Suggestion, formikProps: FormikProps<IAddressValues>) => {
    const fillAddress = ({ type, short_name, long_name }: IFillAddressProps) => {
      const { setFieldValue } = formikProps;

      switch (type) {
        case 'route':
          setFieldValue('street', short_name);
          break;
        case 'country':
          setFieldValue('country', long_name);
          break;
        case 'postal_code':
          setFieldValue('zipcode', long_name);
          break;
        case 'street_number':
          setFieldValue('number', long_name);
          break;
        case 'sublocality_level_1':
          setFieldValue('neighborhood', long_name);
          break;
        case 'administrative_area_level_2':
          setFieldValue('city', long_name);
          break;
        case 'administrative_area_level_1':
          setFieldValue('state', short_name);
          break;
        default:
          break;
      }
    };

    setValue('', false);
    clearSuggestions();
    setAddressSelected(true);

    getDetails({ placeId: place_id })
      .then((details) => {
        if (typeof details !== 'string' && details.address_components) {
          // eslint-disable-next-line no-restricted-syntax
          for (const address of details.address_components) {
            const type = address.types[0];
            const { short_name, long_name } = address;

            if (type) fillAddress({ type, short_name, long_name });
          }
        }
      })
      .catch((err) => console.error(err));
  };

  const renderSugestions = (
    suggestions: Suggestion[],
    formikProps: FormikProps<IAddressValues>,
  ) => {
    return (
      <>
        {suggestions.map((suggestion, index) => {
          const isTheLastCard = index + 1 === suggestions.length;

          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            <ChooseCard
              key={place_id}
              handleClick={() => handleSelect(suggestion, formikProps)}
              styles={{ border: 'none' }}
              classes={`rounded-0 my-0 ${!isTheLastCard && 'border-bottom'}`}
            >
              <>
                <div className="d-flex align-items-center">
                  <div>
                    <FiMapPin color="#333" size="13pt" />
                  </div>

                  <div className="ml-2 w-100" style={{ lineHeight: 1 }}>
                    <h6 className="mb-0">{main_text} </h6>
                    <p className="mb-0 text-secondary" style={{ fontSize: '11pt' }}>
                      {secondary_text}
                    </p>
                  </div>
                </div>
              </>
            </ChooseCard>
          );
        })}
      </>
    );
  };

  const handleInput = (value: string) => {
    setAddressInput(value);
    setValue(`Brazil ${value}`);
  };

  const submit = (address: IAddressValues) => {
    setSubmitButton(true);
    address.zipcode = address.zipcode.replace('-', '');

    if (!address.zipcode) {
      setError('Preencha o CEP corretamente');
      return;
    }

    create(address)
      .then(({ id }) => {
        setShipmentAddress(orderId, id, { type: 'delivery' }).then(() => {
          setError('');
          setSubmitButton(false);
          setModalStep('scheduling');
        });
      })
      .then(() => getAddresses(clientId).then((addresses) => setClientAddresses(addresses)))
      .catch((e) => {
        setSubmitButton(false);
        setError(e.response?.data?.message ?? 'Houve um erro ao adicionar esse endereço');
      });
  };

  const setInputValue = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    const pattern = /(\d{5})(\d{3})/;
    const zipcode = new RegExp(pattern, 'g');

    setFieldValue('zipcode', value.replace(zipcode, '$1-$2'));
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={{
        zipcode: '',
        city: '',
        state: '',
        street: '',
        number: '',
        country: '',
        neighborhood: '',
        complement: '',
        reference: '',
        client_id: clientId,
      }}
      onSubmit={(values: IAddressValues) => {
        submit(values);
      }}
    >
      {(props: FormikProps<IAddressValues>) => {
        const {
          values: { street, neighborhood, city, state, country },
          setFieldValue,
        } = props;

        return (
          <Form className="confirm-address">
            {!addressSelected ? (
              <div className="position-relative">
                <button
                  className="btn btn-sm position-absolute"
                  onClick={() => setModalStep('address')}
                  style={{
                    borderTopLeftRadius: '.2rem',
                    borderBottomLeftRadius: '.2rem',
                    zIndex: 1,
                  }}
                >
                  <IoIosArrowBack />
                </button>

                <FormControl
                  type="text"
                  autoComplete="off"
                  name="search-address"
                  value={addressInput}
                  disabled={!ready}
                  onChange={(e) => handleInput(e.currentTarget.value)}
                  placeholder="Busque seu endereço"
                  className="form-control form-control-sm"
                  style={{ paddingLeft: '2rem' }}
                />

                {status === 'OK' && (
                  <ul className="list-unstyled mb-0">{renderSugestions(data, props)}</ul>
                )}
              </div>
            ) : (
              <Container className="my-2">
                <Row>
                  <Col>
                    <p className="mb-4 text-center">Confirme seu endereço</p>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} className="text-center">
                    <h6 className="mb-1">{street}</h6>
                    <p className="mb-0 text-secondary" style={{ fontSize: '11pt' }}>
                      {neighborhood && `${neighborhood}, `}
                      {city && `${city} - `}
                      {state && `${state}, `}
                      {country}
                    </p>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col xs={5}>
                    <small className="font-weight-bold">Número</small>
                    <Field
                      type="text"
                      name="number"
                      autoComplete="off"
                      placeholder="Número"
                      className="form-control form-control-sm"
                    />
                    <Error name="number" />
                  </Col>

                  <Col xs={7}>
                    <small className="font-weight-bold">CEP</small>
                    <Field
                      type="text"
                      name="zipcode"
                      autoComplete="off"
                      placeholder="_____-___"
                      className="form-control form-control-sm"
                      maxLength="8"
                      onChange={(event: KeyboardEvent<HTMLInputElement>) => {
                        const { value } = event.target as HTMLInputElement;

                        setInputValue(value, setFieldValue);
                      }}
                      onPaste={(event: ClipboardEvent<HTMLInputElement>) => {
                        const value = event.clipboardData?.getData('text/plain');

                        setInputValue(value, setFieldValue);
                      }}
                    />
                    <Error name="zipcode" />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <small className="font-weight-bold">Complemento</small>

                    <Field
                      type="text"
                      name="complement"
                      autoComplete="off"
                      placeholder="Complemento"
                      className="form-control form-control-sm"
                    />

                    <small
                      className="text-primary"
                      style={{ textDecoration: 'underline' }}
                      onClick={() => {
                        setAddressSelected(false);
                        // eslint-disable-next-line guard-for-in, no-restricted-syntax
                        for (const key in props.values) setFieldValue(key, '');
                      }}
                    >
                      Buscar outro endereço
                    </small>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col xs={12}>{error && <small className="text-danger">{error}</small>}</Col>
                  <Col xs={12} className="text-center">
                    <Button
                      type="submit"
                      variant="success"
                      size="sm"
                      className="btn-block"
                      disabled={submitButton}
                    >
                      {submitButton ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Confirmar endereço</span>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Container>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default SearchAddress;
