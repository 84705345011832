import { PageContext } from '../../components/contexts/PageContext';
import { useTemplateByPage } from '../../hooks/useTemplateByPage';
import Default from '../../themes/home/default/Default';

function Home() {
  const template = useTemplateByPage('home');

  const renderHomeTemplate = () => {
    switch (template?.template_name) {
      default:
        return <Default />;
    }
  };

  return <PageContext.Provider value={{ template }}>{renderHomeTemplate()}</PageContext.Provider>;
}

export default Home;
