import { Col, Row } from 'react-bootstrap';

import ButtonLoad from './ButtonLoad';
import TextLoad from './TextLoad';

function ProductDetailLoad() {
  return (
    <>
      <Col md={2}>
        <ul className="mini-images list-unstyled">
          <li
            className="skeleton-load"
            style={{ height: '50px', width: '50px', borderRadius: '4px' }}
          ></li>
        </ul>
      </Col>

      <Col md={4} className="my-auto">
        <div className="skeleton-load" style={{ height: '300px' }}></div>
      </Col>

      <Col md={6} className="p-3">
        <Row>
          <Col>
            <TextLoad width="100%" />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextLoad width="150px" />
            <TextLoad width="100px" />
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center">
              <ButtonLoad width="110px" size="sm" />
              <ButtonLoad width="131px" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <hr />
            <TextLoad width="90%" />
            <TextLoad width="95%" />
            <TextLoad width="80%" />
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default ProductDetailLoad;
