import { IOrder } from '@/store/ducks/order/types';

export const useAddShipping = (type: string, order: IOrder) => {
  if (!window.gtag) return;

  window.gtag('event', 'add_shipping_info', {
    currency: 'BRL',
    value: order.cart.items_total,
    shipping_tier: type,
    items: order.cart.products.map((product) => {
      return {
        item_id: product.product_id,
        item_name: product.title,
        quantity: product.quantity,
      };
    }),
  });
};
