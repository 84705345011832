import { IAddressValues } from '../resources/components/global/address/components/SearchAddress';
import api from '../services/api';

export interface IAddressResponse {
  id: number;
  street: string;
  number: string;
  neighborhood: string;
}

export const getAddresses = async (clientId: number) =>
  api.get<void, IAddressResponse[]>(`/clients/${clientId}/addresses`);

export const create = async (address: IAddressValues) =>
  api.post<IAddressValues, IAddressResponse>('/addresses', { ...address });
