import './listSelectedOptions.scss';

import { IAnswer } from '../../../../store/ducks/order/types';

interface Props {
  options: IAnswer[];
  classes?: string;
}

function ListSelectedOptions({ options, classes }: Props) {
  return (
    <>
      {options.length > 0 && (
        <ul className={`options-list ${classes ?? ''}`}>
          {options.map((option) => {
            return (
              <li key={`option-${option.id}`}>
                <strong className={`mr-1`}>{option.quantity}</strong>
                <span>{option.title}</span>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}

export default ListSelectedOptions;
