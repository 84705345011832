import { useParams } from 'react-router-dom';

import { PageContext } from '../../components/contexts/PageContext';
import { useTemplateByPage } from '../../hooks/useTemplateByPage';
import Default from '../../themes/world/default/Default';

function World() {
  const { id } = useParams<{ id: string; name: string; base64: string }>();
  const template = id ? useTemplateByPage('world', +id) : undefined;

  const render = () => {
    if (id) {
      switch (template?.template_name) {
        default:
          return <Default worldId={parseInt(id)} />;
      }
    }

    return <></>;
  };

  return <PageContext.Provider value={{ template }}>{render()}</PageContext.Provider>;
}

export default World;
