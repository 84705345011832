import { useEffect, useState } from 'react';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { addOrRemoveFilterById } from '@/services/search';
import { IFilterId } from '@/store/ducks/searches/types';

import CollapseItems from '../components/CollapseItems';
import FilterItems from '../components/FilterItems';
import FilterSubTitle from '../components/FIlterSubtitle';

interface Props {
  categories: IFilterId[];
  categoriesFiltered: number[];
  setCategoriesFiltered: Function;
}

function CategoryFilter({ categories, categoriesFiltered, setCategoriesFiltered }: Props) {
  const { configs } = useWebContext();
  const filteredCategories = categories.filter((_, index) => index < 8);

  const [show, setShow] = useState<boolean>(true);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [categoriesToShow, setCategoriesToShow] = useState<IFilterId[]>(filteredCategories);

  const handleFilterItem = (category: IFilterId) => {
    setCategoriesFiltered((old: number[]) => {
      return addOrRemoveFilterById(old, category);
    });
  };

  useEffect(() => {
    if (!isCollapsed && categories.length > 0) setCategoriesToShow(categories);
    else if (filteredCategories.length > 0) setCategoriesToShow(filteredCategories);
  }, [isCollapsed, categories]);

  return (
    <>
      {categories.length > 0 && configs.search_filters.category && (
        <div className="filter-section">
          <FilterSubTitle subtitle="Categoria" show={show} setShow={setShow} />

          {show && (
            <div className="categories pb-3">
              <FilterItems
                itemsToShow={categoriesToShow}
                itemsFiltered={categoriesFiltered}
                setFiltered={handleFilterItem}
              />

              {categories.length > 8 && (
                <CollapseItems isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CategoryFilter;
