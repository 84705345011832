import './image.scss';

import { CSSProperties, useEffect, useState } from 'react';

import { LazyLoadImages } from '@/services/lazyLoadImage';

import withoutImage from '../../../assets/images/without-image.png';

interface Props {
  src: string | null | undefined;
  styles?: CSSProperties;
  className?: string;
  id?: string;
}

function BgImage({ src = withoutImage, styles, className, id }: Props) {
  const [source, setSource] = useState<string>(src ?? withoutImage);

  useEffect(() => {
    LazyLoadImages();
  }, [src]);

  useEffect(() => {
    if (typeof src !== 'undefined' && src !== null && src !== '') setSource(src);
    else setSource(withoutImage);
  }, [src]);

  return (
    <div
      id={id || ''}
      className={`lazy-image lazy-background image ${className || ''}`}
      style={{ backgroundImage: '', backgroundSize: 'contain', ...styles }}
      data-src={source}
    ></div>
  );
}

export default BgImage;
