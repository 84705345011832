import { useCallback, useEffect, useState } from 'react';

interface IDimension {
  width: number;
  height: number;
}

const useImageDimensions = (locate: string): IDimension => {
  const [dimesions, setDimensions] = useState<IDimension>({ width: 0, height: 0 });

  const getDimensions = useCallback(() => {
    try {
      const splited = locate.split('_');
      const dimensions = splited.pop()?.split('x');

      setDimensions({
        width: parseInt(dimensions![0] ?? '0'),
        height: parseInt(dimensions![1] ?? '0'),
      });
    } catch (e) {
      setDimensions({ width: 0, height: 0 });
    }
  }, [locate]);

  useEffect(() => getDimensions(), [getDimensions]);

  return dimesions;
};

export { useImageDimensions };
