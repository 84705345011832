import { Modal } from 'react-bootstrap';
import { FaTags } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import CustomModal from '@/resources/components/global/CustomModal';
import { ApplicationState } from '@/store';
import { IDeliveryDiscountData } from '@/store/ducks/deliveryDiscount/types';

import DeliveryDiscountItem from './DeliveryDiscountItem';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  deliveryDiscount: IDeliveryDiscountData;
}

function AppliedDiscountModal({ show, setShow, deliveryDiscount }: Props) {
  const shippingDiscount = useSelector<ApplicationState, IDeliveryDiscountData[]>(
    (state) => state.deliveryDiscount.data,
  );

  const discount = shippingDiscount.find((discount) => discount.id === deliveryDiscount.id);
  return (
    <CustomModal
      id="shipping-discount-modal"
      show={show}
      onHide={() => setShow(false)}
      centered={true}
      size="lg"
      className="shipping-discount-modal modal-rounded"
    >
      <Modal.Header closeButton className="h5 mb-0">
        <span className="d-flex flex-row align-items-center">
          <FaTags className="mr-2" style={{ color: '#0088FF' }} />
          {discount?.type_discount === 'price' ? 'Desconto aplicado' : 'Frete grátis aplicado'}
        </span>
      </Modal.Header>

      <Modal.Body className="d-flex flex-column gap-2">
        {discount && <DeliveryDiscountItem discount={discount} />}
      </Modal.Body>
    </CustomModal>
  );
}

export default AppliedDiscountModal;
