import { Reducer } from 'redux';

import { IPrivacyPolicyState, PrivacyPolicyTypes } from './types';

const DEFAULT_STATE: IPrivacyPolicyState = {
  consentGiven: true,
  loading: false,
  error: false,
  data: {
    privacy_policy: null,
  },
};

const reducer: Reducer<IPrivacyPolicyState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case PrivacyPolicyTypes.SET_CONSENT_GIVEN:
      return { ...state, consentGiven: action.payload };
    case PrivacyPolicyTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case PrivacyPolicyTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case PrivacyPolicyTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

export default reducer;
