import { useCallback, useEffect, useState } from 'react';

export function useFormatMoney(value: number, fractionDigits: number = 2) {
  const format = useCallback(() => {
    return value
      .toFixed(fractionDigits)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }, [value, fractionDigits]);

  const [money, setMoney] = useState<string>(format);

  useEffect(() => setMoney(format), [value, fractionDigits, format]);

  return money;
}
