import './brandFilter.scss';

import { useState } from 'react';

import { useCategoryContext } from '@/resources/hooks/useCategoryContext';

import AllBrandsCategory from './AllBrandsCategory';
import BrandsFiltered from './BrandsFiltered';
import BrandsSuggestions from './BrandsSuggestions';
import ShowMoreItem from './ShowMoreItem';

function BrandFilter() {
  const { brands } = useCategoryContext();

  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <div className="d-flex">
      {brands?.length > 0 && <span className="text-secondary mr-1">Marcas: </span>}

      <ul className="list-inline brand-filter-list mb-0">
        <BrandsFiltered collapsed={collapsed} />

        {collapsed && <BrandsSuggestions />}

        {!collapsed && <AllBrandsCategory />}

        {brands?.length > 7 && <ShowMoreItem collapsed={collapsed} setCollapsed={setCollapsed} />}
      </ul>
    </div>
  );
}

export default BrandFilter;
