/**
 * Action types
 */
export enum BaseTypes {
  SET_BASE64 = '@base/SET_BASE64',
}

/**
 * State type
 */
export interface IBaseState {
  readonly data: string;
}
