import { Reducer } from 'redux';

import { IOrderState, OrderTypes } from './types';

const DEFAULT_STATE: IOrderState = {
  data: null,
  error: false,
  loading: false,
  validated: false,
  zipcodeAnswered: false,
};

const reducer: Reducer<IOrderState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case OrderTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case OrderTypes.LOAD_SUCCESS:
      if (!action.payload.order) return state;

      return {
        ...state,
        loading: false,
        error: false,
        // eslint-disable-next-line prefer-object-spread
        data: Object.assign({}, action.payload.order),
      };
    case OrderTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true };
    case OrderTypes.SET_VALIDATED:
      return { ...state, validated: action.payload };
    case OrderTypes.SET_ZIPCODE:
      return { ...state, zipcodeAnswered: action.payload };
    default:
      return state;
  }
};

export default reducer;
