import { PageContext } from '../../components/contexts/PageContext';
import Default from '../../themes/disabled/default/Default';

function Disabled() {
  return (
    <PageContext.Provider value={null}>
      <Default />
    </PageContext.Provider>
  );
}

export default Disabled;
