import { KeyboardEvent, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { AiOutlineArrowRight } from 'react-icons/ai';

import { PriceInterval } from '@/resources/components/filters/searchFilter/SearchFilter';
import { useWebContext } from '@/resources/hooks/useWebContext';
import { IPriceFilter } from '@/store/ducks/searches/types';

import FilterSubTitle from '../components/FIlterSubtitle';
import PriceShortcut from '../components/price/PriceShortcut';

interface Props {
  prices: IPriceFilter[];
  priceInterval: PriceInterval;
  setPriceInterval: (priceInterval: PriceInterval) => void;
}

function PriceFilter({ prices, priceInterval, setPriceInterval }: Props) {
  const { configs } = useWebContext();
  const [show, setShow] = useState<boolean>(true);

  const [minPrice, setMinPrice] = useState<number | undefined>();
  const [maxPrice, setMaxPrice] = useState<number | undefined>();

  return (
    <>
      {configs.search_filters.price && (
        <div className="filter-section">
          <FilterSubTitle subtitle="Preço" show={show} setShow={setShow} />

          {show && (
            <>
              <PriceShortcut
                prices={prices}
                priceInterval={priceInterval}
                setPriceInterval={setPriceInterval}
              />
              <div className="d-flex flex-row price-filter my-3">
                <Form.Control
                  type="number"
                  size="sm"
                  placeholder="Minimo"
                  value={priceInterval.minPrice ?? ''}
                  onChange={(e) => {
                    const minPrice = +e.currentTarget.value;

                    setMinPrice(minPrice);
                  }}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') setPriceInterval({ minPrice, maxPrice });
                  }}
                />
                <Form.Control
                  type="number"
                  size="sm"
                  placeholder="Maximo"
                  value={priceInterval.maxPrice ?? ''}
                  onChange={(e) => {
                    const maxPrice = +e.currentTarget.value;

                    setMaxPrice(maxPrice);
                  }}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') setPriceInterval({ minPrice, maxPrice });
                  }}
                />
                <Button
                  className="submit-price btn btn-sm"
                  onClick={() => setPriceInterval({ minPrice, maxPrice })}
                >
                  <AiOutlineArrowRight />
                </Button>
              </div>

              <span
                className="item-filter link"
                onClick={() => {
                  setPriceInterval({ minPrice: undefined, maxPrice: undefined });
                }}
              >
                <u>limpar</u>
              </span>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default PriceFilter;
