import './ordination.scss';

import { ChangeEvent } from 'react';

export type ordinationType = 'lowest price' | 'biggest price';

interface Props {
  setOrdination: (ordination: ordinationType) => void;
}

function Ordination({ setOrdination }: Props) {
  return (
    <div className="ordination-wrapper d-flex">
      <span className="mr-1 w-auto" style={{ color: '#707070' }}>
        Ordenar por:
      </span>

      <select
        className="form-control w-auto"
        name="ordination"
        style={{ color: '#0093ED' }}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          const selected = e.target.value as ordinationType;
          setOrdination(selected);
        }}
      >
        <option value="lowest price">Menor preço</option>
        <option value="biggest price">Maior preço</option>
      </select>
    </div>
  );
}

export default Ordination;
