import { CSSProperties } from 'react';
import { FiBookOpen } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface Props {
  color: string;
  className: string;
  style?: CSSProperties;
}

function BookletsLink({ color, className, style = {} }: Props) {
  return (
    <li className={className} style={style}>
      <Link style={{ color }} className="d-block py-1" to={'/booklets'}>
        <FiBookOpen size={16} className="mr-2" />
        Encartes
      </Link>
    </li>
  );
}

export default BookletsLink;
