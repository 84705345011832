import { PageContext } from '../../components/contexts/PageContext';
import { useTemplateByPage } from '../../hooks/useTemplateByPage';
import Default from '../../themes/notFound/default/Default';

function NotFound() {
  const template = useTemplateByPage('404');

  const renderNotFoundTemplate = () => {
    switch (template?.template_name) {
      default:
        return <Default />;
    }
  };

  return (
    <PageContext.Provider value={{ template }}>{renderNotFoundTemplate()}</PageContext.Provider>
  );
}

export default NotFound;
