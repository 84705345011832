import { useSelector } from 'react-redux';

import { isLightOrDark } from '../../services/lightOrDark';
import { ApplicationState } from '../../store';

const useSmartTextColor = (color = '#212529') => {
  const selectBgColor = useSelector<ApplicationState, string>(
    (state) => state.settings.configs.bg_color,
  );

  const colorBrightness = isLightOrDark(color);
  const bgColorBrightness = isLightOrDark(selectBgColor ?? '#f5f5f5');

  if (colorBrightness !== bgColorBrightness) return color;
  if (bgColorBrightness === 'dark' && colorBrightness === 'dark') return '#fff';
  if (bgColorBrightness === 'light' && colorBrightness === 'light') return '#343a40';
  return '#333';
};

export { useSmartTextColor };
