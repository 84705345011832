/**
 * Action types
 */
export enum CustomerTypes {
  SET_CART_ALERT_AFTER_BUY = '@customer/SET_CART_ALERT_AFTER_BUY',
}

/**
 * Data Types
 */
export interface ICustomerAsks {
  cart_alert_after_buy: boolean;
}

/**
 * State type
 */
export interface ICustomerState {
  readonly asks: ICustomerAsks;
}
