interface Props {
  height: string;
  width?: string;
  styles?: {};
}

function ImageLoad({ width, height, styles }: Props) {
  return <div className="skeleton-load" style={{ width, height, ...styles }}></div>;
}

export default ImageLoad;
