import { CSSProperties, useEffect, useState } from 'react';

import { useWebContext } from '../../hooks/useWebContext';

interface Props {
  style?: CSSProperties;
}

function CartCounter({ style }: Props) {
  const { order } = useWebContext();
  const [cartCounter, setCartCounter] = useState<number>(order?.cart?.products?.length ?? 0);

  useEffect(() => {
    setCartCounter(order?.cart?.products?.length ?? 0);
  }, [order?.cart?.products?.length]);

  return (
    <>
      {cartCounter ? (
        <span className="cart-counter" style={style} aria-label={`${cartCounter} unidades`}>
          {cartCounter}
        </span>
      ) : (
        ''
      )}
    </>
  );
}

export default CartCounter;
