import { useContext } from 'react';

import {
  IShipmentCardContext,
  ShipmentCardContext,
} from '../components/contexts/ShipmentCardContext';

export const useShipmentCardContext = (): IShipmentCardContext => {
  const context = useContext(ShipmentCardContext);

  if (!context)
    throw new Error('Esse compomente deve ser usado dentro de um <ShipmentCard> componente.');

  return context;
};
