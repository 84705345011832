import { ErrorMessage } from 'formik';

function Error({ name }: { name: string }) {
  return (
    <small className="text-danger" style={{ fontSize: '8pt', lineHeight: 1 }}>
      <ErrorMessage name={name} />
    </small>
  );
}

export default Error;
