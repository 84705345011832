import { PriceInterval } from '@/resources/components/filters/searchFilter/SearchFilter';
import { IPriceFilter } from '@/store/ducks/searches/types';

import ShortcutItem from './ShortcutItem';

interface Props {
  prices: IPriceFilter[];
  priceInterval: PriceInterval;
  setPriceInterval: (priceInterval: PriceInterval) => void;
}

function PriceShortcut({ prices, priceInterval, setPriceInterval }: Props) {
  return (
    <>
      {prices.map((price, i) => {
        return (
          <div className="mb-1" key={`shortcut-${i}`}>
            <ShortcutItem
              price={price}
              index={i}
              priceInterval={priceInterval}
              setPriceInterval={setPriceInterval}
            />
          </div>
        );
      })}
    </>
  );
}
export default PriceShortcut;
