import './default.scss';

import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import Breadcrumb from '@/resources/components/global/breadcrumb/Breadcrumb';
import { useWebContext } from '@/resources/hooks/useWebContext';
import { getPrivacyPolicy } from '@/routes/settings';

function Default() {
  const { privacyPolicy } = useWebContext();

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <Container fluid className="breakpoint-page">
      <Row>
        <Col md={12}>
          <Breadcrumb name="Política de Privacidade" />

          <div className="privacy-policy-section py-3">
            <section>
              <ReactMarkdown>{privacyPolicy ?? ''}</ReactMarkdown>
            </section>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Default;
