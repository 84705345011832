import { IUniquePayment } from '@/store/ducks/order/types';
import { IPrivacyPolicy } from '@/store/ducks/privacyPolicy/types';
import { IShopConfig } from '@/store/ducks/shopConfig/types';

import api from '../services/api';
import { store } from '../store';
import * as PrivacyPolicyActions from '../store/ducks/privacyPolicy/actions';
import * as SettingsActions from '../store/ducks/settings/actions';
import { ISettingConfig } from '../store/ducks/settings/types';
import * as ShopActions from '../store/ducks/shopConfig/actions';

const { dispatch } = store;

export const getConfigs = async () => {
  return api
    .get<{ shop_id: number }, ISettingConfig>('/webapp-config')
    .then((config) => {
      dispatch(SettingsActions.setConfigs(config));
      return config;
    })
    .catch((err) => console.error(err));
};

export const getShopConfig = async (shopId: number) => {
  dispatch(ShopActions.loadRequest());

  return api
    .get<{ shop_id: number }, IShopConfig>('/shop-config', { params: { shop_id: shopId } })
    .then((config) => {
      dispatch(ShopActions.loadSuccess(config));

      return config;
    })
    .catch((err) => {
      console.error(err);
      dispatch(ShopActions.loadFailure());
    });
};

export const getPrivacyPolicy = async () => {
  dispatch(PrivacyPolicyActions.loadRequest());

  return api
    .get<void, IPrivacyPolicy>('/privacy-policy')
    .then((privacyPolicy) => {
      dispatch(PrivacyPolicyActions.loadSuccess(privacyPolicy));
    })
    .catch((err) => {
      console.error(err);
      dispatch(PrivacyPolicyActions.loadFailure());
    });
};

export const setPrivacyConsent = async (clientId: number) => {
  return api
    .post<{ client_id: number; origin: 1 }, void>('/agreement', {
      client_id: clientId,
      origin: 1,
      document: `${window.location.origin}/privacy-policy`,
    })
    .then(() => {
      dispatch(PrivacyPolicyActions.setConsentGiven(true));
    })
    .catch((err) => console.error(err));
};

export const getUniquePayment = async (shopId: number, deliveryType: string) => {
  return api
    .get<{ shop_id: number; delivery_type: 'in-store' | 'delivery' }, IUniquePayment>(
      '/deliveries/shop-unique',
      { params: { shop_id: shopId, delivery_type: deliveryType } },
    )
    .then((response) => response)
    .catch((err) => console.error(err));
};
