import { createContext, CSSProperties } from 'react';

export interface IDiscountContext {
  discount: string;
  isAbsolute: boolean;
  style: CSSProperties;
  textStyle: CSSProperties;
}

export const DiscountContext = createContext<IDiscountContext | null>(null);
