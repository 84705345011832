import { FaTimesCircle } from 'react-icons/fa';

interface Props {
  sizes: string[];
  sizeFilter: string[];
  setSizeFilter: Function;
}

function SizeFilter({ sizes, sizeFilter, setSizeFilter }: Props) {
  return (
    <div className="d-flex">
      {sizes?.length > 0 && <span className="text-secondary mr-1">Tamanhos: </span>}

      <ul className="list-inline brand-filter-list mb-0">
        {sizes?.map((size) => {
          const active = sizeFilter.includes(size) ? 'active' : '';

          return (
            <li
              key={size}
              className={`list-inline-item ${active}`}
              onClick={() => {
                setSizeFilter((old: string[]) => {
                  if (!old.includes(size)) return [...old, size];
                  return old;
                });
              }}
            >
              <span>{size}</span>
              {active && (
                <FaTimesCircle
                  className="ml-2"
                  onClick={(e) => {
                    e.stopPropagation();

                    setSizeFilter((old: string[]) => old.filter((s) => s !== size));
                  }}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default SizeFilter;
