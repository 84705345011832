import { Card, Col, Row } from 'react-bootstrap';

import ImageLoad from './ImageLoad';
import TextLoad from './TextLoad';

function CartProductLoad() {
  return (
    <Card className="cart-item-card search-card my-1">
      <Row className="mx-0">
        <Col xs={3} sm={2} className="text-center my-auto px-0">
          <ImageLoad height="50px" />
        </Col>

        <Col xs={9} sm={10} className="px-0">
          <div className="info">
            <TextLoad width="100%" />
            <TextLoad width="74px" styles={{ marginLeft: 'auto' }} />
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default CartProductLoad;
