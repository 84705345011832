import api from '../services/api';
import { store } from '../store';
import {
  setBestSellers,
  setCategory,
  setClientOffers,
  setFreeShipping,
  setGiftClub,
  setGroup,
  setOffers,
  setRandomOffers,
  setSuperOffers,
} from '../store/ducks/productLists/actions';
import * as ProductsActions from '../store/ducks/products/actions';
import { IProduct, IProductsGroupByCategory } from '../store/ducks/products/types';
import * as SearchesActions from '../store/ducks/searches/actions';
import { ISearch } from '../store/ducks/searches/types';

export interface ISecondaryImages {
  id: number;
  path: string;
  product_id: number;
  created_at: string;
  updated_at: string;
}

const { dispatch } = store;

export const getProductsByCategoryId = async (shopId: number, categoryId: number) => {
  dispatch(ProductsActions.loadRequest());

  return api
    .get<{ category_id: number }, IProductsGroupByCategory[]>(`/shops/${shopId}/products`, {
      params: { category_id: categoryId },
    })
    .then((productsGroupByCategory) =>
      dispatch(ProductsActions.loadSuccess(productsGroupByCategory)),
    )
    .catch((err) => {
      console.error(err);
      dispatch(ProductsActions.loadFailure());
    });
};

export const getClientOffers = async (clientId: number, shopId: number, limit: number) => {
  dispatch(setClientOffers({ error: false, loading: true, products: [] }));

  return api
    .get<{ shop_id: number; limit: number }, IProduct[]>(`/clients/${clientId}/client-promo`, {
      params: { limit, shop_id: shopId },
    })
    .then((products) => dispatch(setClientOffers({ error: false, loading: false, products })))
    .catch(() => dispatch(setClientOffers({ error: true, loading: false, products: [] })));
};

export const getProductsInOffer = async (shopId: number, limit: number) => {
  dispatch(setOffers({ error: false, loading: true, products: [] }));

  return api
    .get<{ limit: number }, IProduct[]>(`/shops/${shopId}/promo-products`, { params: { limit } })
    .then((products) => {
      dispatch(setOffers({ error: false, loading: false, products }));
    })
    .catch(() => dispatch(setOffers({ error: false, loading: false, products: [] })));
};

export const getProduct = async (
  shopId: number,
  productId: number,
): Promise<{ fullProducts: IProduct[]; specProducts: IProduct[] }> => {
  dispatch(ProductsActions.setProductLoading());

  return api
    .get<void, IProduct[]>(`shops/${shopId}/products/${productId}`)
    .then((fullProducts) => {
      fullProducts.forEach((fp) => {
        fp.options_group?.options.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
      });

      const specProducts = fullProducts
        .filter((fp) => fp.data && fp.data.spec)
        .sort((a, b) => (a.data?.spec?.color! > b.data?.spec?.color! ? 1 : -1));

      dispatch(ProductsActions.setSpecProducts(specProducts));

      return { fullProducts, specProducts };
    })
    .catch(() => {
      return { fullProducts: [], specProducts: [] };
    });
};

export const getBestSellers = async (shopId: number, limit: number) => {
  dispatch(setBestSellers({ loading: true, error: false, products: [] }));

  return api
    .get<{ limit: number }, IProduct[]>(`shops/${shopId}/top-products`, { params: { limit } })
    .then((products) => dispatch(setBestSellers({ loading: false, error: false, products })))
    .catch(() => dispatch(setBestSellers({ loading: false, error: true, products: [] })));
};

export const smartSearch = async ({
  shopId,
  title,
  brands,
  category_ids,
  group_ids,
  min_price,
  max_price,
  sizes,
}: {
  shopId: number;
  title: string;
  brands?: string[];
  category_ids?: number[];
  group_ids?: number[];
  min_price?: number;
  max_price?: number;
  sizes?: string[];
}) => {
  dispatch(SearchesActions.loadRequest());
  dispatch(SearchesActions.setTitleSearched(title));

  return api
    .get<{ shop_id: number; title: string }, ISearch>(`/products/search`, {
      params: {
        shop_id: shopId,
        title,
        brands,
        category_ids,
        group_ids,
        min_price,
        max_price,
        sizes,
      },
    })
    .then((searched) => dispatch(SearchesActions.loadSuccess(searched)))
    .catch((err) => {
      dispatch(SearchesActions.loadFailure());
      console.error(err);
    });
};

export const getSecondaryImages = async (shopId: number, productId: number) => {
  return api.get<void, ISecondaryImages[]>(
    `/shops/${shopId}/products/${productId}/get-secondary-images`,
  );
};

export const getRandomProducts = async (shopId: number, limit: number) => {
  dispatch(setRandomOffers({ error: false, loading: true, products: [] }));

  return api
    .get<{ shopId: number; limit?: number }, IProduct[]>('/products/get-random-products', {
      params: { shop_id: shopId, limit },
    })
    .then((products) => dispatch(setRandomOffers({ error: false, loading: false, products })))
    .catch(() => dispatch(setRandomOffers({ error: true, loading: false, products: [] })));
};

export const getCategoryProducts = async (shopId: number, limit: number, category_id: number) => {
  dispatch(setCategory({ error: false, loading: true, products: [] }));

  return api
    .get<{ shopId: number; limit?: number }, IProduct[]>('/products/get-random-products', {
      params: { shop_id: shopId, limit, category_id },
    })
    .then((products) => dispatch(setCategory({ error: false, loading: false, products })))
    .catch(() => dispatch(setCategory({ error: true, loading: false, products: [] })));
};

export const getGroupProducts = async (shopId: number, limit: number, group_id: number) => {
  dispatch(setGroup({ error: false, loading: true, products: [] }));

  return api
    .get<{ shopId: number; limit?: number }, IProduct[]>('/products/get-random-products', {
      params: { shop_id: shopId, limit, group_id },
    })
    .then((products) => dispatch(setGroup({ error: false, loading: false, products })))
    .catch(() => dispatch(setGroup({ error: true, loading: false, products: [] })));
};

export const getGiftClubProducts = async (shopId: number, limit: number, worldId?: number) => {
  dispatch(setGiftClub({ error: false, loading: true, products: [] }));

  return api
    .get<{ shopId: number; limit?: number; world_id: number }, IProduct[]>('/products/gift-club', {
      params: { shop_id: shopId, limit, world_id: worldId },
    })
    .then((products) => dispatch(setGiftClub({ error: false, loading: false, products })))
    .catch(() => dispatch(setGiftClub({ error: true, loading: false, products: [] })));
};

export const getSuperOffersProducts = async (shopId: number, limit: number, worldId?: number) => {
  dispatch(setSuperOffers({ error: false, loading: true, products: [] }));

  return api
    .get<{ shopId: number; limit?: number; world_id: number }, IProduct[]>(
      '/products/super-offers',
      {
        params: { shop_id: shopId, limit, world_id: worldId },
      },
    )
    .then((products) => dispatch(setSuperOffers({ error: false, loading: false, products })))
    .catch(() => dispatch(setSuperOffers({ error: true, loading: false, products: [] })));
};

export const getFreeShippingProducts = async (shopId: number, limit = 30) => {
  dispatch(setFreeShipping({ error: false, loading: true, products: [] }));

  return api
    .get<{ shopId: number; limit?: number }, IProduct[]>('/products/free-delivery-products', {
      params: { shop_id: shopId, limit },
    })
    .then((products) => dispatch(setFreeShipping({ error: false, loading: false, products })))
    .catch(() => dispatch(setFreeShipping({ error: true, loading: false, products: [] })));
};
