import { useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { CgPill } from 'react-icons/cg';
import { SlMap } from 'react-icons/sl';

import { saveFile } from '@/services/file';

import { IProduct } from '../../../../store/ducks/products/types';
import Description from '../description/Description';

interface Props {
  product: IProduct | undefined;
}

function ProductInfo({ product }: Props) {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const handleButtonClick = () => {
    setShowCaptcha(true);
  };

  const handleCaptchaSuccess = (token: string | null) => {
    if (!product?.medicine_leaflet_link || !token) return;

    try {
      saveFile(product.medicine_leaflet_link, 'bula.pdf');

      setShowCaptcha(false);

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <>
      {product && (
        <section className="my-3">
          <Card className="shadow" style={{ borderRadius: '20px' }}>
            <Card.Header style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
              <h4 className="mb-0">Informações do produto</h4>
            </Card.Header>
            <Card.Body>
              <Description description={product.description} />

              {product.medicine_leaflet_link && process.env.REACT_APP_RECAPTCHAV2_SITE_KEY && (
                <>
                  <button
                    type="button"
                    className="btn text-primary font-weight-bold p-0 mt-4"
                    onClick={handleButtonClick}
                  >
                    <SlMap /> <CgPill size={14} className="ml-n2 mb-n2" /> Ler a bula
                  </button>

                  {showCaptcha && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="normal"
                      sitekey={process.env.REACT_APP_RECAPTCHAV2_SITE_KEY || ''}
                      onChange={handleCaptchaSuccess}
                    />
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </section>
      )}
    </>
  );
}

export default ProductInfo;
