import api from '../services/api';
import { setHeaderAuthorization, setToken } from '../services/auth';
import { generateRandomKey } from '../services/global';
import { md5 } from '../services/md5';

interface IgetPreToken {
  key: string;
  code: string;
}

interface ILogin {
  token: string;
  token_type: string;
  expires_in: number;
}

const getSecretCode = () => process.env.REACT_APP_SECRET_CODE;

const login = async (key: string, code: string, companyId: number) => {
  return api
    .post<{ key: string; company_id: number; challenge: string }, ILogin>('/secret-login', {
      key,
      company_id: companyId,
      challenge: md5(code + getSecretCode()),
    })
    .then(({ token }) => {
      setToken(token);
      setHeaderAuthorization(token);

      return token;
    });
};

export const getPreToken = async (
  companyId: number,
): Promise<{ token: string | null; error: boolean }> => {
  const preSecret = generateRandomKey();

  return api
    .post<{ company_id: number; pre_secret: string; challenge: string }, IgetPreToken>(
      '/secret-code',
      {
        company_id: companyId,
        pre_secret: preSecret,
        challenge: md5(preSecret + getSecretCode()),
      },
    )
    .then(async ({ key, code }) => {
      const token = await login(key, code, companyId);

      return { token, error: false };
    })
    .catch((e) => {
      console.error(e);
      return { token: null, error: true };
    });
};
