import { useTextColor } from '@/resources/hooks/useTextColor';
import { useWebContext } from '@/resources/hooks/useWebContext';

import { useNavbarContext } from '../../hooks/useNavbarContext';
import ActiveCategoriesList from './ActiveCategoriesList';
import BookletsLink from './BookletsLink';
import CategoryLink from './CategoryLink';

interface Props {
  showActivedList?: boolean;
}

function HighlightedCategoriesList({ showActivedList = false }: Props) {
  const { highlightedCategories, booklets } = useNavbarContext();
  const { configs } = useWebContext();
  const textColor = useTextColor(configs.navbar_footer_color);

  return (
    <div className="d-flex align-items-center justify-content-between">
      {showActivedList && <ActiveCategoriesList iconSize="25" label />}

      <ul className="d-flex list-inline mb-0 navbar-categories-list w-100">
        {booklets.length > 0 && configs.has_booklet && (
          <BookletsLink
            className="navbar-category-link list-inline-item"
            color={textColor}
            key={`highlighted-categories-navbar-booklets`}
          />
        )}

        {highlightedCategories?.map((category) => (
          <CategoryLink
            color={textColor}
            category={category}
            className="navbar-category-link list-inline-item"
            key={`highlighted-categories-navbar-${category.id}-${category.type}`}
            type={category.type}
          />
        ))}
      </ul>
    </div>
  );
}

export default HighlightedCategoriesList;
