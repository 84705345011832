import { useSlug } from '@/resources/hooks/useSlug';
import { ICategory } from '@/store/ducks/categories/types';

export type HighlightRouteType = 'categories' | 'groups';

export function getHighlightLink(
  type: 'category' | 'group' | 'world' | 'cw',
  highlight: ICategory,
) {
  const title = useSlug({ str: highlight.webapp_name ?? highlight.name });

  switch (type) {
    case 'category':
      return `/${type}/${highlight.id}/${title}`;
    case 'group':
      return `/${type}/${highlight.id}/${title}`;
    case 'world':
      return `/cw/${highlight.id}/${title}`;
    case 'cw':
      return `/cw/${highlight.id}/${title}`;
    default:
      return '';
  }
}

export function translateHighlightType(type: 'category' | 'group'): HighlightRouteType {
  return {
    category: 'categories' as HighlightRouteType,
    group: 'groups' as HighlightRouteType,
  }[type];
}
