import { useEffect, useState } from 'react';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { addOrRemoveFilterByName } from '@/services/search';
import { IFilter } from '@/store/ducks/searches/types';

import CollapseItems from '../components/CollapseItems';
import FilterItems from '../components/FilterItems';
import FilterSubTitle from '../components/FIlterSubtitle';

interface Props {
  brands: IFilter[];
  brandsFiltered: string[];
  setBrandsFiltered: Function;
}

function BrandFilter({ brands, brandsFiltered, setBrandsFiltered }: Props) {
  const { configs } = useWebContext();
  const filteredBrands = brands.filter((_, index) => index < 8);

  const [show, setShow] = useState<boolean>(true);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [brandsToShow, setBrandsToShow] = useState<IFilter[]>(filteredBrands);

  const handleFilterItem = (brand: IFilter) => {
    setBrandsFiltered((old: string[]) => {
      return addOrRemoveFilterByName(old, brand);
    });
  };

  useEffect(() => {
    if (!isCollapsed && brands.length > 0) setBrandsToShow(brands);
    else if (filteredBrands.length > 0) setBrandsToShow(filteredBrands);
  }, [isCollapsed, brands]);

  return (
    <>
      {brands.length > 0 && configs.search_filters.brand && (
        <div className="filter-section">
          <FilterSubTitle subtitle="Marca" show={show} setShow={setShow} />

          {show && (
            <div className="brands pb-3">
              <FilterItems
                itemsToShow={brandsToShow}
                itemsFiltered={brandsFiltered}
                setFiltered={handleFilterItem}
              />

              {brands.length > 8 && (
                <CollapseItems isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default BrandFilter;
