import './default.scss';

import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';

import { CategoryContext } from '@/resources/components/contexts/CategoriesContext';

import { getMultipleHighlightGroup } from '../../../../routes/categories';
import { delay } from '../../../../services/global';
import { sortByBiggestPrice, sortByLowestPrice } from '../../../../services/products';
import { ApplicationState } from '../../../../store';
import { changingFilters } from '../../../../store/ducks/categories/actions';
import { ICategoryPaginator } from '../../../../store/ducks/categories/types';
import { IProduct } from '../../../../store/ducks/products/types';
import CategoryGroupImage from '../../../components/category/CategoryGroupImage';
import BrandFilter from '../../../components/filters/brandFilter/BrandFilter';
import SizeFilter from '../../../components/filters/sizeFilter/SizeFilter';
import Breadcrumb from '../../../components/global/breadcrumb/Breadcrumb';
import CategoryImageLoad from '../../../components/loads/CategoryImageLoad';
import ProductsLoad from '../../../components/loads/ProductsLoad';
import Ordination, { ordinationType } from '../../../components/product/ordination/Ordination';
import { useImageGroupsByDevice } from '../../../hooks/useImageGroupByDevice';
import { useImageGroupsByName } from '../../../hooks/useImageGroupsByName';
import { usePageBreakpoints } from '../../../hooks/usePageBreakpoints';
import { usePageContext } from '../../../hooks/usePageContext';

const Products = lazy(() => import('../../../components/products/Products'));

interface Props {
  id?: number;
  shopId: number | null;
  type: 'categories' | 'groups';
}

function Default({ shopId, id, type }: Props) {
  const dispatch = useDispatch();
  const { template } = usePageContext();
  const { breakpoints } = usePageBreakpoints();
  const { imageGroup } = useImageGroupsByDevice({
    imageGroups: useImageGroupsByName({
      locate: 'image_group_1',
      imageGroups: template?.image_groups,
    }),
  });

  const [sizes, setSizes] = useState<string[]>([]);
  const [sizeFilter, setSizeFilter] = useState<string[]>([]);

  const [brandFilter, setBrandFilter] = useState<string[]>([]);

  const [productsToList, setProductsToList] = useState<IProduct[]>([]);
  const [ordination, setOrdination] = useState<ordinationType>('lowest price');
  const [nextPageBtnClicked, setNextPageBtnClicked] = useState<boolean>(false);

  const selectChangingFilters = useSelector<ApplicationState, boolean>(
    (state) => state.categories.categoryGroup.changingFilters,
  );
  const selectCategoryGroup = useSelector<ApplicationState, ICategoryPaginator>(
    (state) => state.categories.categoryGroup,
  );
  const selectIsLoading = useSelector<ApplicationState, boolean>(
    (state) => state.categories.categoryGroup.loading,
  );
  const selectBrandsFiltered = useSelector<ApplicationState, string[]>(
    (state) => state.categories.brandsFiltered,
  );

  const sortProductsBy = useCallback(
    (products: IProduct[]) => {
      switch (ordination) {
        case 'biggest price':
          return products.sort(sortByBiggestPrice);
        default:
          return products.sort(sortByLowestPrice);
      }
    },
    [ordination],
  );

  useEffect(() => {
    const groupedProducts: IProduct[] = [];
    const sortByAlphabeticalAsc = (a: string, b: string) =>
      a.toLowerCase() > b.toLowerCase() ? 1 : -1;

    selectCategoryGroup.data.forEach((group) => {
      if (group.products.length > 0) groupedProducts.push(...group.products);
    });

    setOrdination(ordination);
    setProductsToList(sortProductsBy(groupedProducts));

    setSizes(selectCategoryGroup.sizes.sort(sortByAlphabeticalAsc));
    // eslint-disable-next-line
  }, [selectCategoryGroup]);

  useEffect(() => {
    dispatch(changingFilters(true));

    delay(() => {
      if (id && shopId)
        getMultipleHighlightGroup({ brands: brandFilter, sizes: sizeFilter, shopId, id, type });
    }, 1000);
    // eslint-disable-next-line
  }, [brandFilter, sizeFilter, dispatch]);

  useEffect(() => setProductsToList((old) => [...sortProductsBy(old)]), [sortProductsBy]);

  return (
    <CategoryContext.Provider
      value={{
        brandsFiltered: selectBrandsFiltered,
        brands: selectCategoryGroup.brands,
        setBrandFilter,
      }}
    >
      {selectIsLoading ? (
        <>
          <CategoryImageLoad breakpoints={breakpoints} />
          <ProductsLoad type="grid" breakpoints={{ breakpoints }} />
        </>
      ) : (
        <>
          <Container className="px-0" fluid>
            <Row className="justify-content-center mx-0">
              <Col {...breakpoints} className="breakpoint-page position-relative px-0">
                {selectCategoryGroup.data.length > 0 && (
                  <Breadcrumb
                    page="category"
                    categoryName={selectCategoryGroup.data
                      ?.map((category) => category.webapp_name ?? category.name)
                      .join(', ')}
                  />
                )}

                <div className="category-distinction text-center">
                  <Slider
                    speed={500}
                    arrows={true}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                    autoplay={false}
                    autoplaySpeed={6000}
                    className={`image-groups category-banner`}
                    lazyLoad="ondemand"
                  >
                    {selectCategoryGroup.data.map((categoryGroup) => (
                      <CategoryGroupImage
                        imageGroup={imageGroup}
                        categoryGroup={categoryGroup}
                        key={`category-group-image-${categoryGroup.id}`}
                      />
                    ))}
                  </Slider>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mx-0 mt-1">
              <Col {...breakpoints} className="breakpoint-page px-sm-0">
                <Row className="my-1">
                  <Col md={9}>
                    <BrandFilter />
                    <SizeFilter
                      sizes={sizes}
                      sizeFilter={sizeFilter}
                      setSizeFilter={setSizeFilter}
                    />
                  </Col>
                  <Col md={3}>
                    <div className="d-inline-flex justify-content-end w-100">
                      <Ordination setOrdination={setOrdination} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="justify-content-center mx-0">
              <Col {...breakpoints} className="breakpoint-page px-sm-0">
                {selectChangingFilters ? (
                  <ProductsLoad type="grid" breakpoints={{ breakpoints }} />
                ) : (
                  <Suspense fallback={<div />}>
                    <Products
                      type="grid"
                      keyName="list"
                      products={productsToList}
                      breakpoints={
                        template?.template_name.includes('distributor') ? { xs: 12 } : undefined
                      }
                      productType={
                        template?.template_name.includes('distributor') ? 'one-line' : 'carousel'
                      }
                    />
                  </Suspense>
                )}
              </Col>
            </Row>
          </Container>

          {selectCategoryGroup.last_page !== selectCategoryGroup.current_page && (
            <Container fluid>
              <Row className="py-3 justify-content-center">
                <Col {...breakpoints} className="text-center">
                  <Button
                    size="sm"
                    variant="success"
                    disabled={nextPageBtnClicked}
                    onClick={() => {
                      setNextPageBtnClicked(true);

                      if (id && shopId) {
                        getMultipleHighlightGroup({
                          id,
                          type,
                          shopId,
                          brands: brandFilter,
                          page: selectCategoryGroup.current_page + 1,
                        }).then(() => setNextPageBtnClicked(false));
                      }
                    }}
                  >
                    {nextPageBtnClicked && (
                      <Spinner
                        as="span"
                        animation="border"
                        className="mr-1"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    Buscar mais produtos
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </CategoryContext.Provider>
  );
}

export default Default;
