import { useEffect } from 'react';

import { LazyLoadImages } from '@/services/lazyLoadImage';

import { FooterImageList } from '../../../../store/ducks/settings/types';

interface Props {
  title: string;
  list: FooterImageList[];
}

function ImageList({ title, list }: Props) {
  useEffect(() => {
    LazyLoadImages();
  }, []);

  return (
    <>
      {list.length > 0 && (
        <>
          <span className="font-weight-bold" style={{ fontSize: '14pt' }}>
            {title}
          </span>

          <ul className="list-inline mb-4">
            {list.map((item) => (
              <li key={item.image} className="list-inline-item p-2" style={{ maxWidth: '100px' }}>
                <img
                  alt=""
                  width={50}
                  height={28}
                  src=""
                  data-src={item.image}
                  className="lazy-image img-fluid"
                  style={{ borderRadius: '3px' }}
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}

export default ImageList;
