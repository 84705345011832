import './imageGroups.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import { IImage, IImageGroup } from '../../../store/ducks/settings/types';
import { useDevice } from '../../hooks/useDevice';
import ImageGroupItem from './ImageGroupItem';

const ImageRedirect: FunctionComponent<{ image: IImage; lazy: boolean }> = ({ image, lazy }) => {
  return (
    <>
      {image.redirect_type === 'custom' ? (
        <>
          {image.redirect_link !== null ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={image.redirect_link ?? ''}
              style={{ cursor: image.redirect_link ? 'pointer' : 'default' }}
              onClick={(e) => image.redirect_link === null && e.preventDefault()}
            >
              <ImageGroupItem image={image} lazy={lazy} />
            </a>
          ) : (
            <ImageGroupItem image={image} lazy={lazy} />
          )}
        </>
      ) : (
        <Link to={image.redirect_link ?? ''}>
          <ImageGroupItem image={image} lazy={lazy} />
        </Link>
      )}
    </>
  );
};

interface Props {
  type: 'carousel' | 'grid';
  imageGroups: IImageGroup[];
  slideClassName?: string;
  lazy?: boolean;
}

function ImageGroups({ imageGroups, type = 'carousel', slideClassName = '', lazy = true }: Props) {
  const { device } = useDevice();

  const getImageGroup = useCallback(() => {
    const getLocateDevice = (locate: string) => locate.split('_').shift();

    const imageGroup = imageGroups.find((group) => getLocateDevice(group.name) === device);

    imageGroup?.images?.sort((a: IImage, b: IImage) => (a.id > b.id ? 1 : -1));

    return imageGroup;
  }, [device, imageGroups]);

  const memoizedImageGroup = useMemo(() => getImageGroup(), [getImageGroup]);

  const [imageGroup, setImageGroup] = useState<IImageGroup | undefined>(getImageGroup());

  const handleAfterChange = () => {
    const bannerImage = document.querySelector('.banners .slick-active.slick-current img');

    if (bannerImage) {
      bannerImage.classList.add('hover');

      setTimeout(() => bannerImage.classList.remove('hover'), 500);
    }
  };

  useEffect(() => setImageGroup(memoizedImageGroup), [memoizedImageGroup]);

  return (
    <>
      {imageGroup && imageGroup.images.length > 0 && (
        <section className="image-group-section">
          {type === 'grid' ? (
            <Row className="justify-content-center">
              {imageGroup.images.map((image) => {
                return (
                  <Col
                    key={`grid-${image.id}`}
                    md={12 / imageGroup.images.length}
                    className="py-1 text-center"
                  >
                    <ImageRedirect image={image} lazy={lazy} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Slider
              speed={500}
              arrows={device === 'desktop'}
              infinite={true}
              slidesToShow={1}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={6000}
              dots={device === 'mobile'}
              className={`image-groups ${slideClassName}`}
              lazyLoad="ondemand"
              afterChange={handleAfterChange}
            >
              {imageGroup.images.map((image) => (
                <ImageRedirect key={`carousel-${image.id}`} image={image} lazy={lazy} />
              ))}
            </Slider>
          )}
        </section>
      )}
    </>
  );
}

export default ImageGroups;
