import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getPagesInfo } from '@/services/pages';

export const usePageTrack = () => {
  const location = useLocation();

  if (!window.gtag) return;

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: getPagesInfo(location.pathname, location.search),
      page_location: location.pathname + location.search,
    });
  }, [location.pathname, location.search]);
};
