import { Reducer } from 'redux';

import { IPaymentState, PaymentTypes } from './types';

const DEFAULT_STATE: IPaymentState = {
  asks: {
    has_shown_paid_alert: false,
    show_paid_alert: false,
  },
};

const reducer: Reducer<IPaymentState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case PaymentTypes.SET_PAID_ALERT:
      return { ...state, asks: { ...state.asks, has_shown_paid_alert: action.payload } };
    case PaymentTypes.SET_SHOW_PAID_ALERT:
      return { ...state, asks: { ...state.asks, show_paid_alert: action.payload } };
    default:
      return state;
  }
};

export default reducer;
