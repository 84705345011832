import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaTags } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { useRemoveAllCart } from '@/resources/hooks/useRemoveAllCart';

import { removeCart } from '../../../../routes/order';
import { canUseWebappInStatuses } from '../../../../services/global';
import { useOrder } from '../../../hooks/useOrder';
import ShippingDiscountModal from '../shipment/address/components/delivery/shippingDiscountModal/ShippingDiscountModal';

function DeleteAllCart() {
  const [show, setShow] = useState(false);
  const [showDiscounts, setShowDiscounts] = useState(false);

  const { order } = useOrder();

  const submit = async (orderId: number) => {
    const oldOrder = order ? { ...order } : null;

    await removeCart(orderId).then(() => {
      if (oldOrder) useRemoveAllCart(oldOrder);

      toast('Carrinho removido com sucesso!', { type: 'success' });
      setShow(false);
    });
  };

  return (
    <>
      {order && (
        <>
          <Button
            variant="link"
            className="btn btn-sm text-primary font-weight-bold border-right"
            onClick={() => setShowDiscounts(true)}
          >
            <span className="d-flex flex-row align-items-center">
              <FaTags className="mr-1" style={{ color: '#0088FF' }} /> Descontos disponíveis
            </span>
          </Button>

          <ShippingDiscountModal show={showDiscounts} setShow={setShowDiscounts} />

          {!order.is_paid &&
            !order.integration_code &&
            canUseWebappInStatuses({ status: order.status }) && (
              <Button
                variant="link"
                className="btn btn-sm text-danger"
                onClick={() => setShow(true)}
              >
                Limpar carrinho
              </Button>
            )}
          <Modal show={show} onHide={() => setShow(false)} animation={false} centered>
            <Modal.Header closeButton>
              <Modal.Title>Deseja limpar o carrinho?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Após a confirmação, nenhuma alteração poderá ser desfeita.</Modal.Body>
            <Modal.Footer>
              <Formik
                initialValues={{ order_id: order.id }}
                onSubmit={() => {
                  submit(order.id);
                }}
              >
                <Form>
                  <Field type="hidden" name="order_id" />
                  <Button
                    type="button"
                    variant="secondary"
                    className="mr-1"
                    onClick={() => setShow(false)}
                  >
                    Cancelar
                  </Button>

                  <Button type="submit" variant="danger">
                    Limpar carrinho
                  </Button>
                </Form>
              </Formik>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default DeleteAllCart;
