import { createContext } from 'react';

import { IShopConfig } from '@/store/ducks/shopConfig/types';

import { IOrder } from '../../../store/ducks/order/types';
import { ISettingConfig } from '../../../store/ducks/settings/types';

export interface IWebContext {
  order: IOrder | null;
  selectBase64: string;
  configs: ISettingConfig;
  consentGiven: boolean;
  privacyPolicy: string | null;
  shop: IShopConfig;
  setShowCartAlertModal: (show: boolean) => void;
  setShowCPFModal: (show: boolean) => void;
  setShowIsCartBlockedModal: (show: boolean) => void;
  setShowIdenfificationModal: (show: boolean) => void;
}

export const WebContext = createContext<IWebContext | null>(null);
