import '../product/product.scss';

import { useSmartTextColor } from '@/resources/hooks/useSmartTextColor';

import ProductsLoad from './ProductsLoad';
import TextLoad from './TextLoad';

interface Props {
  quantity?: number;
}

function ListProductsLoad({ quantity = 30 }: Props) {
  const color = useSmartTextColor('#666');

  const lists = quantity.toString().split('')[0];
  const products = Array.from({ length: Number(lists) }, (_, index) => index);

  return (
    <section>
      <h1 className="section-header" style={{ color }}>
        <TextLoad width="215px" styles={{ marginBottom: '0 0 1rem' }} />
      </h1>

      {products.map((i) => (
        <ProductsLoad type="list" key={i} />
      ))}
    </section>
  );
}

export default ListProductsLoad;
