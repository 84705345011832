import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { useNavbarContext } from '../../../hooks/useNavbarContext';
import { useWebContext } from '../../../hooks/useWebContext';

interface Props {
  style?: CSSProperties;
}

function Logo({ style }: Props) {
  const { textColor } = useNavbarContext();
  const { configs } = useWebContext();

  return (
    <Link to={'/w'} className="navbar-brand mr-1 p-0">
      {configs.logo ? (
        <img
          width={185}
          height={55}
          style={style}
          src={configs.logo}
          alt={configs.company_name}
          className="img-fluid"
        />
      ) : (
        <span style={{ color: textColor }}>{configs.company_name}</span>
      )}
    </Link>
  );
}

export default Logo;
