import './navbar.scss';

import { useEffect } from 'react';
import { Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getShopsByCompany } from '@/routes/company';

import { getAllBooklets } from '../../../../routes/booklets';
import { getCategories } from '../../../../routes/categories';
import { ApplicationState } from '../../../../store';
import { IBooklet } from '../../../../store/ducks/booklets/types';
import { ICategory } from '../../../../store/ducks/categories/types';
import { useTextColor } from '../../../hooks/useTextColor';
import { useWebContext } from '../../../hooks/useWebContext';
import { formatDate } from '../../../themes/booklets/default/Default';
import { NavbarContext } from '../../contexts/NavbarContext';
import Desktop from './desktop/Desktop';
import Mobile from './mobile/Mobile';

function Menu() {
  const location = useLocation();

  const { order, configs } = useWebContext();

  const textColor = useTextColor(configs.color);

  const selectCategories = useSelector<ApplicationState, ICategory[]>(
    (state) => state.categories.data,
  );
  const selectHighlightedCategories = useSelector<ApplicationState, ICategory[]>(
    (state) => state.categories.highlited,
  );

  const selectBooklets = useSelector<ApplicationState, IBooklet[]>((state) => {
    const today = formatDate(new Date().toISOString());

    return state.booklets.data.filter((booklet) => {
      const end = formatDate(booklet.end);
      const start = formatDate(booklet.start);

      return today && start && end && today >= start && today <= end;
    });
  });

  useEffect(() => {
    if (order && !selectCategories.length) getCategories(order.dst_shop_id);
    // eslint-disable-next-line
  }, [order?.dst_shop_id]);

  useEffect(() => {
    if (order?.dst_shop_id) getAllBooklets(order.dst_shop_id);
  }, [order?.dst_shop_id]);

  useEffect(() => {
    if (order?.company_id) getShopsByCompany();
  }, [order?.company_id]);

  return (
    <NavbarContext.Provider
      value={{
        textColor,
        booklets: selectBooklets,
        pathname: location.pathname,
        categories: selectCategories,
        highlightedCategories: selectHighlightedCategories,
      }}
    >
      <Navbar expand="lg" className="p-0">
        <Mobile />
        <Desktop />
      </Navbar>
    </NavbarContext.Provider>
  );
}

export default Menu;
