import { Reducer } from 'redux';

import { IShopConfigState, ShopConfigTypes } from './types';

const DEFAULT_STATE: IShopConfigState = {
  data: {
    id: 0,
    name: '',
    cnpj: '',
    schedule: '',
    whatsapp: '',
    is_open: true,
    is_active: false,
    use_stock: false,
    use_stock_kg: false,
    show_full_range: false,
    interval: 0,
    address: {
      id: null,
      city: '',
      state: '',
      number: '',
      street: '',
      zipcode: '',
      neighborhood: '',
      country: null,
      client_id: null,
      reference: null,
      complement: null,
    },
    trade_name: null,
    company_name: null,
    pharmacist_responsible: null,
    pharmacist_crf: null,
    anvisa_registration: null,
    afe_authorization: null,
  },
  error: false,
  loading: false,
};

const reducer: Reducer<IShopConfigState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ShopConfigTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ShopConfigTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload };
    case ShopConfigTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: DEFAULT_STATE.data };
    default:
      return state;
  }
};

export default reducer;
