import { IProduct } from '@/store/ducks/products/types';

export const useViewItem = (product: IProduct) => {
  if (!window.gtag || !product) return;

  window.gtag('event', 'view_item', {
    currency: 'BRL',
    value: product.price,
    items: [
      {
        item_id: product.id,
        item_name: product.title,
      },
    ],
  });
};
