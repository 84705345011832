import api from '../services/api';
import { store } from '../store';
import * as CategoriesActions from '../store/ducks/categories/actions';
import { ICategory, ICategoryPaginator } from '../store/ducks/categories/types';

const { dispatch } = store;

export const getCategories = async (shopId: number) => {
  dispatch(CategoriesActions.loadRequest());

  return api
    .get<{ shop_id: number }, ICategory[]>('/categories', { params: { shop_id: shopId } })
    .then((categories) => dispatch(CategoriesActions.loadSuccess(categories)))
    .catch(() => {
      dispatch(CategoriesActions.loadFailure());
    });
};

export const getMultipleHighlightGroup = async ({
  id,
  type,
  shopId,
  page = 1,
  sizes = [],
  brands = [],
  loading = false,
}: {
  id: number;
  page?: number;
  shopId: number;
  sizes?: string[];
  brands?: string[];
  loading?: boolean;
  type: 'categories' | 'groups';
}) => {
  if (loading) dispatch(CategoriesActions.loadCategoryGroup());

  dispatch(CategoriesActions.setBrandsFiltered([]));

  return api
    .get<{ ids: number[]; brands?: string[] }, ICategoryPaginator>(
      `/shops/${shopId}/get-multiple-${type}`,
      {
        params: {
          ids: [id],
          page,
          sizes,
          brands,
        },
      },
    )
    .then((categoryGroup) => {
      if (page === 1) dispatch(CategoriesActions.successCategoryGroup(categoryGroup));
      else dispatch(CategoriesActions.successCategoryGroupNextPage(categoryGroup));

      dispatch(CategoriesActions.setBrandsFiltered(brands));
      dispatch(CategoriesActions.changingFilters(false));
    })
    .catch((e) => {
      console.error(e);
      dispatch(CategoriesActions.failureCategoryGroup());
    });
};
