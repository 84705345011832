/* eslint-disable no-useless-escape */
interface Props {
  text: string;
}

function Markdown({ text }: Props) {
  const getRegexs = () => {
    const patterns = {
      breakLine: /\r?\n/,
      bold: /(\*)([^*]+?)(\1)/,
      line: /(\~)([^~]+?)(\1)/,
      italic: /(\_)([^_]+?)(\1)/,
      mono: /(\`{3})([^*]+?)(\1)/,
      link: /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/,
    };

    return {
      bold: new RegExp(patterns.bold, 'g'),
      line: new RegExp(patterns.line, 'g'),
      mono: new RegExp(patterns.mono, 'g'),
      link: new RegExp(patterns.link, 'igm'),
      italic: new RegExp(patterns.italic, 'g'),
      breakLine: new RegExp(patterns.breakLine, 'g'),
    };
  };

  const { bold, line, italic, mono, link, breakLine } = getRegexs();

  const formatText = (text: string) => {
    let formated = text;

    formated = formated.replace(bold, '<b>$2</b>');
    formated = formated.replace(line, '<del>$2</del>');
    formated = formated.replace(italic, '<em>$2</em>');
    formated = formated.replace(mono, '<code>$2</code>');
    formated = formated.replace(link, '<a href="$&" target="_blank">$&</a>');
    formated = formated.replace(breakLine, '<br>');

    return formated;
  };

  return <span dangerouslySetInnerHTML={{ __html: formatText(text) }} />;
}

export default Markdown;
