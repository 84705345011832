import { CSSProperties } from 'react';
import { FaArrowDown } from 'react-icons/fa';

import { useDiscountContext } from '../../hooks/useDiscountContext';

function Rounded() {
  const { isAbsolute, style, discount } = useDiscountContext();
  const absoluteStyle: CSSProperties = isAbsolute
    ? { position: 'absolute', top: '3px', right: '3px' }
    : {};

  return (
    <span
      className="discount badge badge-success d-flex align-items-center"
      style={{ borderRadius: '30px', fontSize: '10pt', ...absoluteStyle, ...style }}
    >
      <FaArrowDown className="mr-1" />
      {discount}%
    </span>
  );
}

export default Rounded;
