import { MouseEvent } from 'react';
import { toast } from 'react-toastify';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { deletePayment, setShipmentAddress } from '@/routes/order';
import { getDeliveryInfo } from '@/services/order';

import { IAddressResponse } from '../../../../../routes/addresses';
import IntegrationOption from './IntegrationOption';

interface Props {
  addresses: IAddressResponse[];
  handleShowSchedule: (deliveryType?: string, validAddress?: boolean) => void;
}

function ListAddresses({ addresses, handleShowSchedule }: Props) {
  const { order } = useWebContext();

  const removePayments = () => {
    if (!order?.payments?.length) return;

    const activePayments = order.payments.filter((p) => !p.deleted_at);

    activePayments.map(({ id }) => deletePayment(id));
  };

  const handleClickAddress = (event: MouseEvent<HTMLDivElement>) => {
    const addressId = parseInt(event.currentTarget.dataset.addressId!);

    if (!order) return;

    if (order.shipment.type === 'delivery' && addressId === order.shipment.address?.id) {
      handleShowSchedule();

      return;
    }

    setShipmentAddress(order.id, addressId, { type: 'delivery' }).then((order) => {
      const deliveryIntegrationType = order?.shipment?.delivery?.type;

      const deliveryInfo = getDeliveryInfo(order?.shipment);

      handleShowSchedule(deliveryIntegrationType, !!deliveryInfo);
      removePayments();

      toast('Você alterou o endereço!', { type: 'success' });
    });
  };

  return (
    <>
      <div className="mb-2 px-2 text-secondary small">Escolha o endereço de entrega:</div>
      {addresses.length > 0 ? (
        addresses.map((address) => (
          <IntegrationOption
            key={address.id}
            type="address"
            data={address}
            onClick={handleClickAddress}
          />
        ))
      ) : (
        <h6 className="mb-0 text-center">Não há endereços cadastrados</h6>
      )}
    </>
  );
}

export default ListAddresses;
