import { Reducer } from 'redux';

import { CustomerTypes, ICustomerState } from './types';

const DEFAULT_STATE: ICustomerState = {
  asks: {
    cart_alert_after_buy: false,
  },
};

const reducer: Reducer<ICustomerState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CustomerTypes.SET_CART_ALERT_AFTER_BUY:
      return { ...state, asks: { ...state.asks, cart_alert_after_buy: action.payload } };
    default:
      return state;
  }
};

export default reducer;
