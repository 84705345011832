import './price.scss';

import { CSSProperties, useEffect, useState } from 'react';

import { calcDiscount } from '../../../../services/products';
import { useFormatMoney } from '../../../hooks/useFormatMoney';
import { useTextColor } from '../../../hooks/useTextColor';
import { useWebContext } from '../../../hooks/useWebContext';
import Discount, { discountType } from '../../discount/Discount';

interface Props {
  price: number;
  defaultPrice: number;
  showDiscount?: boolean;
  absoluteDiscount?: boolean;
  rewardPrice?: number | null;
  discountType?: discountType;
  priceStyles?: CSSProperties;
  discountStyles?: CSSProperties;
  showPromotionalPricePronoum?: boolean;
}

const Price = ({
  price,
  defaultPrice,
  discountType,
  rewardPrice = 0,
  priceStyles = {},
  discountStyles = {},
  showDiscount = false,
  absoluteDiscount = false,
  showPromotionalPricePronoum = false,
}: Props) => {
  const {
    configs: { advantage_club },
  } = useWebContext();
  const formatedPrice = useFormatMoney(price);
  const formatedRewardPrice = useFormatMoney(rewardPrice ?? 0);
  const formatedDefaultPrice = useFormatMoney(defaultPrice);

  const backgroundColor = advantage_club?.bg_color ?? '#fff';
  const color = useTextColor(backgroundColor);

  const [hasDiscount, setHasDiscount] = useState<boolean>(
    () => calcDiscount(defaultPrice, price) > 0,
  );

  useEffect(() => setHasDiscount(calcDiscount(defaultPrice, price) > 0), [defaultPrice, price]);

  return (
    <div className="prices-wrapper">
      <div style={priceStyles} className={`price ${rewardPrice ? 'has-reward' : ''}`}>
        {hasDiscount && (
          <div className="price-and-discount d-flex align-items-center justify-content-between">
            <div className="old-price">
              <span className="tag">{showPromotionalPricePronoum ? 'De:' : ''} R$ </span>
              <span className="value">{formatedDefaultPrice}</span>
            </div>

            {showDiscount && (
              <Discount
                price={price}
                type={discountType}
                style={discountStyles}
                defaultPrice={defaultPrice}
                isAbsolute={absoluteDiscount}
              />
            )}
          </div>
        )}
        <div className="updated-price" style={{ width: hasDiscount ? '' : '100%' }}>
          <span className="tag">R$ </span>
          <span className="value">{formatedPrice}</span>
        </div>
      </div>

      {!!rewardPrice && (
        <div
          style={{
            lineHeight: 1,
            backgroundColor,
            borderRadius: '4px',
          }}
          className="reward-price w-100 w-lg-50 ml-lg-1 mt-2 mt-lg-0 py-1 px-2 px-lg-1 d-flex flex-column"
        >
          <h6
            className="mb-0 title"
            style={{
              color,
              lineHeight: 1,
              fontWeight: 700,
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            {advantage_club?.text ?? ''}
          </h6>

          <div className="mt-1" style={{ textAlign: 'right', color }}>
            <span className="tag">R$ </span>
            <span className="value">{formatedRewardPrice}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Price;
