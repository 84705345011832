import { useEffect, useState } from 'react';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { addOrRemoveFilterById } from '@/services/search';
import { IFilterId } from '@/store/ducks/searches/types';

import CollapseItems from '../components/CollapseItems';
import FilterItems from '../components/FilterItems';
import FilterSubTitle from '../components/FIlterSubtitle';

interface Props {
  groups: IFilterId[];
  groupsFiltered: number[];
  setGroupsFiltered: Function;
}

function GroupsFilter({ groups, groupsFiltered, setGroupsFiltered }: Props) {
  const { configs } = useWebContext();
  const filteredGroups = groups.filter((_, index) => index < 8);

  const [show, setShow] = useState<boolean>(true);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [groupsToShow, setGroupsToShow] = useState<IFilterId[]>(filteredGroups);

  const handleFilterItem = (group: IFilterId) => {
    setGroupsFiltered((old: number[]) => {
      return addOrRemoveFilterById(old, group);
    });
  };

  useEffect(() => {
    if (!isCollapsed && groups.length > 0) setGroupsToShow(groups);
    else if (filteredGroups.length > 0) setGroupsToShow(filteredGroups);
  }, [isCollapsed, groups]);

  return (
    <>
      {groups.length > 0 && configs.search_filters.group && (
        <div className="filter-section">
          <FilterSubTitle subtitle="Grupo" show={show} setShow={setShow} />

          {show && (
            <div className="groups pb-3">
              <FilterItems
                itemsToShow={groupsToShow}
                itemsFiltered={groupsFiltered}
                setFiltered={handleFilterItem}
              />

              {groups.length > 8 && (
                <CollapseItems isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default GroupsFilter;
