import { CSSProperties, useEffect, useState } from 'react';

import { calcDiscount } from '../../../services/products';
import { useFormatMoney } from '../../hooks/useFormatMoney';
import { DiscountContext } from '../contexts/DiscountContext';
import Rounded from './Rounded';
import Tag from './Tag';

export type discountType = 'rounded' | 'tag';

interface Props {
  price: number;
  type?: discountType;
  defaultPrice: number;
  isAbsolute?: boolean;
  style?: CSSProperties;
  textStyle?: CSSProperties;
}

function Discount({
  price,
  defaultPrice,
  isAbsolute = false,
  style = {},
  textStyle = {},
  type = 'rounded',
}: Props) {
  const [discount, setDiscount] = useState(calcDiscount(defaultPrice, price));
  const [hasDiscount, setHasDiscount] = useState<boolean>(() => discount !== 0);

  const formatedDiscount = useFormatMoney(discount, 0);

  useEffect(() => {
    if (price !== 0) setDiscount(calcDiscount(defaultPrice, price));
  }, [defaultPrice, price]);

  useEffect(() => setHasDiscount(discount !== 0), [discount]);

  return (
    <DiscountContext.Provider value={{ isAbsolute, style, textStyle, discount: formatedDiscount }}>
      {hasDiscount && <>{type === 'rounded' ? <Rounded /> : <Tag />}</>}
    </DiscountContext.Provider>
  );
}

export default Discount;
