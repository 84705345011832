import Disabled from '../../../assets/images/OBJECTS.svg';

function Default() {
  return (
    <div
      className="Disabled text-center"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
      }}
    >
      <div style={{ maxWidth: '80%' }}>
        <img className="img-fluid" src={Disabled} alt="Página não existe ou foi apagada" />
        <h6 className="mt-5">
          Ops! Parece que essa página não existe ou foi recentemente apagada.
        </h6>
      </div>
    </div>
  );
}

export default Default;
