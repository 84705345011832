import './searchFilter.scss';

import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { BsFilterRight } from 'react-icons/bs';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { useWindowSize } from '@/resources/hooks/useWindowSize';
import { smartSearch } from '@/routes/products';
import { isLightOrDark } from '@/services/lightOrDark';
import { ISearch } from '@/store/ducks/searches/types';

import { delay } from '../../../../services/global';
import { scrollToTop } from '../../navbar/SearchInput';
import BrandFilter from './filters/BrandFilter';
import CategoryFilter from './filters/CategoryFilter';
import GroupsFilter from './filters/GroupsFilter';
import PriceFilter from './filters/PriceFilter';
import SizeFilter from './filters/SizeFilter';

interface Props {
  search: ISearch | null;
  title: string;
}

export type PriceInterval = { minPrice?: number; maxPrice?: number };

function SearchFilter({ search, title }: Props) {
  const { width } = useWindowSize();
  const { configs } = useWebContext();

  const bgType = isLightOrDark(configs.bg_color);

  const [show, setShow] = useState<boolean>(width > 768);
  const [brandsFiltered, setBrandsFiltered] = useState<string[]>([]);
  const [sizesFiltered, setSizesFiltered] = useState<string[]>([]);
  const [categoriesFiltered, setCategoriesFiltered] = useState<number[]>([]);
  const [groupsFiltered, setGroupsFiltered] = useState<number[]>([]);

  const [priceInterval, setPriceInterval] = useState<PriceInterval>({});

  const { order } = useWebContext();

  const [apliedFilters, setApliedFilters] = useState<(string | undefined)[]>([]);

  const getCategoryOrGroupName = (ids: number[], type: 'category' | 'group') => {
    const attribute = ids.map((id) => {
      return type === 'category'
        ? search?.categories.find((category) => category.id === id)
        : search?.groups.find((group) => group.id === id);
    });

    return attribute?.map((attr) => attr?.name) ?? [];
  };

  const getPricesFiltered = () => {
    if (priceInterval.minPrice && priceInterval.maxPrice)
      return [`Mínimo: $ ${priceInterval.minPrice}`, `Máximo: $ ${priceInterval.maxPrice}`];
    return [];
  };

  useEffect(() => {
    if (order && search) {
      delay(() => {
        smartSearch({
          shopId: order.dst_shop_id,
          title,
          category_ids: categoriesFiltered,
          group_ids: groupsFiltered,
          brands: brandsFiltered,
          min_price: priceInterval.minPrice,
          max_price: priceInterval.maxPrice,
          sizes: sizesFiltered,
        });

        scrollToTop();

        if (width < 768) setShow(false);
      }, 1000);
    }
  }, [categoriesFiltered, brandsFiltered, groupsFiltered, sizesFiltered, priceInterval]);

  useEffect(() => {
    setShow(width > 768);
  }, [width]);

  useEffect(() => {
    if (search) {
      setApliedFilters([
        ...getCategoryOrGroupName(categoriesFiltered, 'category'),
        ...brandsFiltered,
        ...getCategoryOrGroupName(groupsFiltered, 'group'),
        ...sizesFiltered,
        ...getPricesFiltered(),
      ]);
    }
  }, [categoriesFiltered, brandsFiltered, priceInterval, groupsFiltered, sizesFiltered]);

  return (
    <>
      {search && (
        <Col md={2} className="d-flex flex-column">
          <>
            {width < 768 && (
              <div className={`text-right filter-subtitle bg-color-${bgType} p-0`}>
                <span onClick={() => setShow(!show)}>
                  <BsFilterRight />
                  filtrar
                </span>
              </div>
            )}

            {show && (
              <>
                <div>
                  <p className={`filter-title bg-color-${bgType} mb-0`}>Filtrar por</p>
                </div>

                <CategoryFilter
                  categories={search.categories}
                  categoriesFiltered={categoriesFiltered}
                  setCategoriesFiltered={setCategoriesFiltered}
                />
                <BrandFilter
                  brands={search.brands}
                  brandsFiltered={brandsFiltered}
                  setBrandsFiltered={setBrandsFiltered}
                />
                <PriceFilter
                  prices={search.prices}
                  setPriceInterval={setPriceInterval}
                  priceInterval={priceInterval}
                />
                <GroupsFilter
                  groups={search.groups}
                  groupsFiltered={groupsFiltered}
                  setGroupsFiltered={setGroupsFiltered}
                />
                <SizeFilter
                  sizes={search.sizes}
                  sizesFiltered={sizesFiltered}
                  setSizesFiltered={setSizesFiltered}
                />
              </>
            )}
          </>

          {apliedFilters.length > 0 && width < 768 && (
            <div className="aplied-filters">
              {apliedFilters.map((filter) => (
                <span className={`filtered bg-color-${bgType}`} key={filter}>
                  {filter}
                </span>
              ))}
            </div>
          )}
        </Col>
      )}
    </>
  );
}

export default SearchFilter;
