import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { LazyLoadImages } from '@/services/lazyLoadImage';

import { IImage } from '../../../store/ducks/settings/types';
import { useImageDimensions } from '../../hooks/useImageDimensions';

interface Props {
  image: IImage;
  lazy: boolean;
}

function ImageGroupItem({ image }: Props) {
  const { width, height } = useImageDimensions(image.locate);

  useEffect(() => {
    LazyLoadImages();
  }, []);

  return (
    <Row className="mx-0">
      <Col className="px-0">
        <link rel="preload" as="image" href={image.link ?? ''} crossOrigin="anonymous" />

        <img
          src={image.link}
          className="img-fluid mx-auto"
          style={{ width, aspectRatio: `${width / height}/1` }}
          alt={image.description ?? 'Clique aqui e aproveite!'}
        />

        {image.description && (
          <div className="description-wrapper">
            <div className="background mx-auto" style={{ maxWidth: width }}>
              <h6 className="description">{image.description}</h6>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default ImageGroupItem;
