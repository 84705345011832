import { PageContext } from '../../components/contexts/PageContext';
import { useTemplateByPage } from '../../hooks/useTemplateByPage';
import Default from '../../themes/product/default/Default';

function ProductDetail() {
  const template = useTemplateByPage('product');

  function renderProductTemplate() {
    switch (template?.template_name) {
      default:
        return <Default />;
    }
  }

  return (
    <PageContext.Provider value={{ template }}>{renderProductTemplate()}</PageContext.Provider>
  );
}

export default ProductDetail;
