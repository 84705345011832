interface Props {
  width: string;
  size?: 'xs' | 'sm' | 'lg';
  styles?: {};
}

function ButtonLoad({ size, width, styles }: Props) {
  enum Size {
    xs = '20px',
    sm = '31px',
    lg = '48px',
    default = '38px',
  }

  return (
    <div
      className="skeleton-load"
      style={{ width, height: Size[size ?? 'default'], borderRadius: '.25rem', ...styles }}
    ></div>
  );
}

export default ButtonLoad;
