import { IProduct } from '../products/types';

/**
 * Action types
 */
export enum ProductListsTypes {
  SET_OFFERS = '@productLists/SET_OFFERS',
  SET_BEST_SELLERS = '@productLists/SET_BEST_SELLERS',
  SET_RANDOM_OFFERS = '@productLists/SET_RANDOM_OFFERS',
  SET_CLIENT_OFFERS = '@productLists/SET_CLIENT_OFFERS',
  SET_GIFT_CLUB = '@productLists/SET_GIFT_CLUB',
  SET_SUPER_OFFERS = '@productLists/SET_SUPER_OFFERS',
  SET_FREE_SHIPPING = '@productLists/SET_FREE_SHIPPING',
  SET_CATEGORY = '@productLists/SET_CATEGORY',
  SET_GROUP = '@productLists/SET_GROUP',
}

/**
 * Data Types
 */
export interface IProductList {
  error: boolean;
  loading: boolean;
  products: IProduct[];
}

export type ProductListNames =
  | 'bestSellers'
  | 'offers'
  | 'clientOffers'
  | 'randomOffers'
  | 'superOffers'
  | 'giftClub'
  | 'freeShipping'
  | 'category'
  | 'group';

/**
 * State type
 */
export interface IProductListsState {
  readonly offers: IProductList;
  readonly bestSellers: IProductList;
  readonly clientOffers: IProductList;
  readonly randomOffers: IProductList;
  readonly superOffers: IProductList;
  readonly giftClub: IProductList;
  readonly freeShipping: IProductList;
  readonly category: IProductList;
  readonly group: IProductList;
}
