import { IOrder } from '@/store/ducks/order/types';

export const useRemoveAllCart = (order: IOrder) => {
  if (!window.gtag) return;

  window.gtag('event', 'remove_from_cart', {
    currency: 'BRL',
    value: order.cart.items_total,
    items: order.cart.products.map((item) => ({
      item_id: item.product_id,
      item_name: item.title,
      quantity: item.quantity,
    })),
  });
};
