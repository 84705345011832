const getUrlInfo = (pathname: string) => {
  return pathname.split('/').pop()?.replaceAll('-', ' ') || '';
};

export const getPagesInfo = (pathname: string, search?: string) => {
  const pagesMap: Record<string, string> = {
    search: `Pesquisa: ${search ? search.split('=').pop() : '--'}`,
    cw: `Mundo: ${getUrlInfo(pathname)}`,
    category: `Categoria: ${getUrlInfo(pathname)}`,
    cart: 'Carrinho',
    group: `Grupo: ${getUrlInfo(pathname)}`,
    booklets: 'Encartes',
    policy: 'Politica de Privacidade',
    product: `Produto: ${getUrlInfo(pathname)}`,
    error: 'Token',
  };

  const foundPage = Object.entries(pagesMap).find(([key]) => pathname.includes(key));

  return foundPage ? foundPage[1] : 'Página inicial';
};
