export interface IBreakpoints {
  breakpoints: IBreakpoint;
}

export interface IBreakpoint {
  xs?: number | undefined;
  sm?: number | undefined;
  md?: number | undefined;
  lg?: number | undefined;
  xl?: number | undefined;
}

export const usePageBreakpoints = (): IBreakpoints => {
  return { breakpoints: { md: 11 } };
};
