import { useContext } from 'react';

import { CategoryContext, ICategoryContext } from '../components/contexts/CategoriesContext';

export const useCategoryContext = (): ICategoryContext => {
  const context = useContext(CategoryContext);

  if (!context)
    throw new Error('Esse compomente deve ser usado dentro de um <Category> componente.');

  return context;
};
