import { Reducer } from 'redux';

import { BookletsTypes, IBookletsState } from './types';

const DEFAULT_STATE: IBookletsState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IBookletsState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case BookletsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case BookletsTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload };
    case BookletsTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    default:
      return state;
  }
};

export default reducer;
