import { IDeliveryDiscountData } from '../deliveryDiscount/types';
import { PriceType } from '../order/types';

/**
 * Action types
 */
export enum ProductsTypes {
  LOAD_REQUEST = '@products/LOAD_REQUEST',
  LOAD_SUCCESS = '@products/LOAD_SUCCESS',
  LOAD_FAILURE = '@products/LOAD_FAILURE',
  CLEAN_DATA = '@products/CLEAN_DATA',
  SET_SPEC_PRODUCTS = '@products/SET_SPEC_PRODUCTS',
  SET_CHOSEN_PRODUCT = '@products/SET_CHOSEN_PRODUCT',
  SET_PRODUCT_LOADING = '@products/SET_PRODUCT_LOADING',
  REMOVE_CHOSEN_PRODUCT = '@products/REMOVE_CHOSEN_PRODUCT',
}

/**
 * Data Types
 */
export interface IProductsGroupByCategory {
  id: number;
  name: string;
  image: string;
  webapp_name: string;
  products: IProduct[];
}

export interface IProduct {
  id: number;
  ean: string;
  brand: string;
  title: string;
  stock: number;
  price: number;
  status: Status;
  shop_id: number;
  alerts: IAlert[];
  images: string[];
  measure: Measure;
  quantity: number;
  company_id: number;
  created_at: string;
  updated_at: string;
  product_id: number;
  default_price: number;
  price_type: PriceType;
  see_as_unit: boolean;
  is_searchable: boolean;
  average_weight: number;
  group_id: number | null;
  data: IProductData | null;
  category_id: number | null;
  description: string | null;
  full_products: IFullProduct[];
  options_group: IOptionGroup | null;
  high_quality_image: string | null;
  delivery_discount_data: IDeliveryDiscountData | null;
  medicine_strip: 1 | 2 | null;
  medicine_leaflet_link: string | null;
}

type Status = 'enabled' | 'disabled' | 'paused';

export type Measure = 'kg' | 'unity';

export interface IAlert {
  type: AlertType;
  price: number | null;
  min_quantity?: number;
}

type AlertType = 'wholesales' | 'promotion' | 'reward';

export interface IProductData {
  spec: ISpec;
}

export interface ISpec {
  size: string;
  color: string;
}

export interface IOptionGroup {
  id: number;
  title: string;
  options: IQuestion[];
}

export interface IQuestion {
  id: number;
  title: string;
  sequence: number;
  minimum_of_answers: number;
  maximum_of_answers: number;
  select_options: IAnswer[];
  add_order_quantity: boolean;
}

export interface IAnswer {
  id: number;
  title: string;
  value: number;
  maximum_quantity: number;
  marked_as_default: boolean;
  chosen?: boolean;
  quantity?: number;
  question_id: number;
}

export interface IFullProduct {
  id: number;
  ean: string;
  price: number;
  status: Status;
  price2: string;
  price3: string;
  promotions: [];
  shop_id: number;
  images: string[];
  quantity: string;
  product_id: number;
  created_at: string;
  data: IProductData;
  updated_at: string;
  type: string | null;
  image: string | null;
  see_as_unit: boolean;
  service_schedule: [];
  is_searchable: boolean;
  active_ecommerce: true;
  wholesales_price: string;
  daily_promo_price: string;
  denounced: boolean | null;
  justification: string | null;
  wholesales_min_quantity: string;
  measure: Measure;
  high_quality_image: string | null;
  delivery_discount_data: IDeliveryDiscountData | null;
}

/**
 * State type
 */
export interface IProductsGroupByCategoryState {
  readonly data: IProductsGroupByCategory[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly productLoading: boolean;
  readonly specProducts: IProduct[];
  readonly chosenProduct: IProduct | undefined;
}
