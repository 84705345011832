import { Badge } from 'react-bootstrap';

import { IShipmentType } from '../../../../../../store/ducks/order/types';

interface Props {
  type: IShipmentType | null;
}

function RenderOrderType({ type }: Props) {
  return (
    <div className="shipment-type">
      <Badge variant="secondary" className="my-auto">
        {type === 'shop' ? 'Retirada na Loja' : 'Entrega / Delivery'}
      </Badge>
    </div>
  );
}

export default RenderOrderType;
