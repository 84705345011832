import './default.scss';

import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { getAllBooklets } from '../../../../routes/booklets';
import { ApplicationState } from '../../../../store';
import { IBooklet } from '../../../../store/ducks/booklets/types';
import BookletCard from '../../../components/booklets/BookletCard';
import Breadcrumb from '../../../components/global/breadcrumb/Breadcrumb';
import { usePageBreakpoints } from '../../../hooks/usePageBreakpoints';
import { useSmartTextColor } from '../../../hooks/useSmartTextColor';
import { useWebContext } from '../../../hooks/useWebContext';

export const formatDate = (date: string) => {
  const dateTZ = new Date(date).toISOString();
  const splitedDate = dateTZ.split('T');

  return splitedDate[0];
};

function Default() {
  const { order } = useWebContext();
  const { breakpoints } = usePageBreakpoints();

  useEffect(() => {
    if (order?.dst_shop_id) {
      getAllBooklets(order.dst_shop_id);
    }
  }, [order?.dst_shop_id]);

  const formatDate = (date: string) => {
    const dateTZ = new Date(date).toISOString();
    const splitedDate = dateTZ.split('T');

    return splitedDate[0] ?? null;
  };

  const selectBooklets = useSelector<ApplicationState, IBooklet[] | null>((state) => {
    const today = formatDate(new Date().toISOString());

    return state.booklets.data.filter((booklet) => {
      const end = formatDate(booklet.end);
      const start = formatDate(booklet.start);

      return today && start && end && today >= start && today <= end;
    });
  });

  const color = useSmartTextColor('#666');

  return (
    <>
      <Container fluid>
        <Row className="justify-content-center">
          <Col {...breakpoints} className="breakpoint-page">
            <Breadcrumb page="booklets" />
            <div className="booklets-section py-3">
              <section>
                {selectBooklets && selectBooklets.length > 0 && (
                  <h1 className="section-title" style={{ color }}>
                    Veja os nossos encartes!
                  </h1>
                )}

                <Row className="justify-content-center px-1 mt-5">
                  {selectBooklets?.map((booklet) => (
                    <BookletCard booklet={booklet} key={`booklet-item-${booklet.id}`}></BookletCard>
                  ))}
                </Row>
              </section>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Default;
