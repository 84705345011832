import { isLightOrDark } from '../../../services/lightOrDark';
import { useWebContext } from '../../hooks/useWebContext';

function ShopBadge() {
  const { configs, shop } = useWebContext();
  const brightness = isLightOrDark(configs.color);

  return (
    <>
      {!shop.is_open && !configs.client_finish_order_outside_time && (
        <span className={`badge badge-${brightness === 'light' ? 'dark' : 'light'}`}>
          Loja Fechada
        </span>
      )}
    </>
  );
}

export default ShopBadge;
