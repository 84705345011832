import { IProduct } from '../products/types';

/**
 * Action types
 */
export enum WorldsTypes {
  LOAD_REQUEST = '@worlds/LOAD_REQUEST',
  LOAD_SUCCESS = '@worlds/LOAD_SUCCESS',
  LOAD_FAILURE = '@worlds/LOAD_FAILURE',
}

/**
 * Data Types
 */
export interface IWorld {
  id: number;
  name: string;
  company_id: number;
  created_at: string;
  updated_at: string;
  active_ecommerce: boolean;
  groups: IGroup[];
  offers: IProduct[];
  best_sellers: IProduct[];
  client_offers: IProduct[];
  world_products: IProduct[];
  gift_club: IProduct[];
  category: IProduct[];
  group: IProduct[];
  super_offers: IProduct[];
  categories: ICategory[];
}

interface ICategory {
  id: number;
  name: string;
}

interface IGroup {
  id: number;
  name: string;
}

export type ProductListNames = 'offers' | 'best_sellers' | 'client_offers' | 'world_products';

/**
 * State type
 */
export interface IWorldsState {
  readonly data: IWorld[];
  readonly loading: boolean;
  readonly error: boolean;
}
