import { action } from 'typesafe-actions';

import { IProduct, IProductsGroupByCategory, ProductsTypes } from './types';

export const loadRequest = () => action(ProductsTypes.LOAD_REQUEST);
export const loadSuccess = (data: IProductsGroupByCategory[]) =>
  action(ProductsTypes.LOAD_SUCCESS, { data });
export const cleanData = () => action(ProductsTypes.CLEAN_DATA);
export const loadFailure = () => action(ProductsTypes.LOAD_FAILURE);
export const setChosenProduct = (product: IProduct | undefined) =>
  action(ProductsTypes.SET_CHOSEN_PRODUCT, product);
export const setSpecProducts = (products: IProduct[]) =>
  action(ProductsTypes.SET_SPEC_PRODUCTS, products);
export const setProductLoading = () => action(ProductsTypes.SET_PRODUCT_LOADING);
