import { Reducer } from 'redux';

import {
  CategoriesTypes,
  ICategoriesState,
  ICategory,
  ICategoryGroupData,
  ICategoryPaginator,
} from './types';

const initialCategoryGroup: ICategoryPaginator = {
  error: false,
  loading: false,
  to: 0,
  from: 0,
  sizes: [],
  brands: [],
  last_page: 0,
  current_page: 0,
  next_page_url: '',
  data: [],
  changingFilters: false,
};

const DEFAULT_STATE: ICategoriesState = {
  data: [],
  actived: [],
  highlited: [],
  error: false,
  loading: true,
  categoryGroup: initialCategoryGroup,
  brandsFiltered: [],
};

const reducer: Reducer<ICategoriesState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CategoriesTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case CategoriesTypes.LOAD_SUCCESS:
      const activated = action.payload?.filter((category: ICategory) => category.active_ecommerce);
      const highlited = action.payload?.filter(
        (category: ICategory) => category.highlighted_ecommerce,
      );

      return { ...state, loading: false, error: false, data: action.payload, activated, highlited };
    case CategoriesTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    case CategoriesTypes.CATEGORY_GROUP_LOAD_REQUEST:
      return { ...state, categoryGroup: { ...state.categoryGroup, loading: true } };
    case CategoriesTypes.CATEGORY_GROUP_LOAD_SUCCESS:
      return { ...state, categoryGroup: { ...action.payload, loading: false, error: false } };
    case CategoriesTypes.CATEGORY_GROUP_LOAD_FAILURE:
      return { ...state, categoryGroup: { ...state.categoryGroup, loading: false, error: true } };
    case CategoriesTypes.CATEGORY_GROUP_NEXT_PAGE:
      const getNextPageProducts = (categoryGroupData: ICategoryGroupData) => {
        const nextPage = (action.payload.data as ICategoryGroupData[]).find(
          (group) => group.id === categoryGroupData.id,
        );

        if (!nextPage) return [];
        return nextPage.products;
      };

      const getCategoryGroupData = (categoryGroupData: ICategoryGroupData) => {
        return {
          ...categoryGroupData,
          products: [...categoryGroupData.products, ...getNextPageProducts(categoryGroupData)],
        };
      };

      return {
        ...state,
        categoryGroup: {
          ...state.categoryGroup,
          to: action.payload.to,
          from: action.payload.from,
          sizes: action.payload.sizes,
          brands: action.payload.brands,
          last_page: action.payload.last_page,
          current_page: action.payload.current_page,
          next_page_url: action.payload.next_page_url,
          error: false,
          loading: false,
          changingFilters: false,
          data: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...state.categoryGroup?.data?.map((categoryGroupData) => {
              return getCategoryGroupData(categoryGroupData);
            }),
          ],
        },
      };
    case CategoriesTypes.CATEGORY_GROUP_CHANGING_FILTERS:
      return {
        ...state,
        categoryGroup: { ...state.categoryGroup, changingFilters: action.payload },
      };
    case CategoriesTypes.CATEGORY_GROUP_SET_BRANDS_FILTERED:
      return {
        ...state,
        brandsFiltered: action.payload,
      };
    case CategoriesTypes.CATEGORY_GROUP_CLEAN_DATA:
      return { ...state, categoryGroup: initialCategoryGroup };
    default:
      return state;
  }
};

export default reducer;
