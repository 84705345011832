interface Props {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}
function ShowMoreItem({ collapsed, setCollapsed }: Props) {
  const setTextByBrandsFilter = () => {
    if (collapsed) return 'Ver mais';
    return 'Ver menos';
  };

  return (
    <li className="show-more-item" onClick={() => setCollapsed(!collapsed)}>
      {setTextByBrandsFilter()}
    </li>
  );
}

export default ShowMoreItem;
