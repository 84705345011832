import { useState } from 'react';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { addOrRemoveFilterByName } from '@/services/search';
import { IFilter } from '@/store/ducks/searches/types';

import FilterItems from '../components/FilterItems';
import FilterSubTitle from '../components/FIlterSubtitle';

interface Props {
  sizes: IFilter[];
  sizesFiltered: string[];
  setSizesFiltered: Function;
}

function SizeFilter({ sizes, sizesFiltered, setSizesFiltered }: Props) {
  const { configs } = useWebContext();
  const [show, setShow] = useState<boolean>(true);

  const handleFilterItem = (brand: IFilter) => {
    setSizesFiltered((old: string[]) => {
      return addOrRemoveFilterByName(old, brand);
    });
  };

  return (
    <>
      {sizes.length > 0 && configs.search_filters.size && (
        <div className="filter-section">
          <FilterSubTitle subtitle="Tamanho" show={show} setShow={setShow} />

          {show && (
            <div className="sizes pb-3">
              <FilterItems
                itemsToShow={sizes}
                itemsFiltered={sizesFiltered}
                setFiltered={handleFilterItem}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SizeFilter;
