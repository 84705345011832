import { lazy, Suspense, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { setShipmentPayment } from '@/routes/order';
import { getUniquePayment } from '@/routes/settings';
import { getOrderMinValue } from '@/services/global';
import { IOrder, IUniquePayment } from '@/store/ducks/order/types';

import FooterDefaultButton from '../footerDefaultButton/FooterDefaultButton';

const MissingDataModal = lazy(() => import('./components/MissingDataModal'));
const MinOrderAlertModal = lazy(
  () => import('@/resources/components/deliveryMinOrderAlert/MinOrderAlertModal'),
);

interface Props {
  setShow: (value: boolean) => void;
  setValidUniquePayment: (value: boolean) => void;
}

function PaymentButton({ setShow, setValidUniquePayment }: Props) {
  const { configs, order } = useWebContext();

  const [uniquePayment, setUniquePayment] = useState<IUniquePayment | null>(null);
  const [showMissingData, setShowMissingData] = useState<boolean>(false);
  const [showMinOrderWarning, setShowMinOrderWarning] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<string[]>([]);

  const validPayments = order?.payments?.filter((p) => !p.deleted_at);
  const canFinishUnpaid = validPayments?.every((p) => p.integration?.data?.can_finish_unpaid);
  const showPaymentButton =
    !validPayments?.length || (!canFinishUnpaid && !order?.is_paid && !order?.integration_code);

  const redirectToPayment = (isRedirect: boolean, payment?: number) => {
    if (!isRedirect) return;

    const paymentUrl = `${process.env.REACT_APP_BV_URL}/orders/${order?.id}/payment/${
      payment ?? ''
    }`;

    const paymentWindow = window.open(paymentUrl, '_blank');

    if (paymentWindow) {
      paymentWindow.opener = null;
    }
  };

  const redirectToPaymentMethod = (order: IOrder, paymentId: number) => {
    const payment = order?.payments?.find((p) => !p.deleted_at && p.integration_id === paymentId);

    const isRedirect =
      !payment?.integration.data.can_finish_unpaid && payment?.type !== 'custom-payment';

    redirectToPayment(isRedirect, payment?.id);
  };

  const handleMissingData = () => {
    toast(
      'Para prosseguir com esse método de pagamento selecionado, é preciso preencher os dados necessários.',
      {
        type: 'warning',
        autoClose: 10000,
      },
    );

    setShowMissingData(true);
  };

  const setPaymentMethod = () => {
    if (!uniquePayment || !order || !order.shipment.address?.id) return;

    if (order.cart.items_total < uniquePayment.plan.min_value) {
      setShowMinOrderWarning(true);

      return;
    }

    setShipmentPayment(order.id, uniquePayment.method.id, uniquePayment.plan.id)
      .then((order: IOrder) => {
        redirectToPaymentMethod(order, uniquePayment.method.id);
      })
      .catch((e) => {
        if (e?.response?.data?.type === 'missing_data') {
          handleMissingData();
          const errors = e?.response?.data?.error_fields ?? [];

          setErrorFields(errors);

          return;
        }

        console.error(e);
        toast('Ocorreu um erro ao adicionar o método de pagamento', { type: 'error' });
      });
  };

  const handleShowModal = () => {
    if (!order) return;

    if (order.cart.items_total > getOrderMinValue(order)) {
      if (configs.unique_payment && uniquePayment) {
        setPaymentMethod();

        return;
      }

      setShow(true);
    } else {
      setShowMinOrderWarning(true);
    }
  };

  useEffect(() => {
    if (!order || !configs.unique_payment || !order.shipment.type) return;

    const type = order.shipment.type === 'delivery' ? 'delivery' : 'in_store';

    getUniquePayment(order.dst_shop_id, type).then((payment) => {
      setUniquePayment(payment || null);

      setValidUniquePayment(!!payment);
    });
  }, [order?.dst_shop_id, order?.shipment.type, order?.shipment.address?.id]);

  return (
    <>
      {showPaymentButton && (
        <FooterDefaultButton
          title={!configs.unique_payment ? 'Escolher pagamento' : 'Pagar'}
          onClick={handleShowModal}
          showModal={setShow}
        />
      )}

      {uniquePayment && (
        <Suspense fallback={<div />}>
          <MissingDataModal
            show={showMissingData}
            setShow={setShowMissingData}
            selectedPayment={{
              paymentId: uniquePayment.method.id,
              planId: uniquePayment.plan.id,
            }}
            errorFields={errorFields}
          />
        </Suspense>
      )}

      <Suspense fallback={<div />}>
        <MinOrderAlertModal
          show={showMinOrderWarning}
          setShow={setShowMinOrderWarning}
          value={uniquePayment?.plan.min_value ?? 0}
        />
      </Suspense>
    </>
  );
}

export default PaymentButton;
