import { IAttendanceType, ICartProduct, IShipment } from '@/store/ducks/order/types';
import { IProduct } from '@/store/ducks/products/types';

import { removeMask } from './global';

interface ISettingByBase64 {
  orderId: number | null;
  companyId: number | null;
  shopId: number | null;
}

export const baseIsValid = (settings: ISettingByBase64 | null) => {
  if (!settings) return false;
  let isValid = true;

  (Object.keys(settings) as (keyof ISettingByBase64)[]).forEach((key) => {
    if (Number.isNaN(settings[key])) {
      isValid = false;
    }
  });

  return isValid;
};

export const getSettingsByBase64 = (base64: string): ISettingByBase64 => {
  try {
    const decodedBase64 = atob(base64).split(';');

    return {
      orderId:
        decodedBase64[0] === 'null' || decodedBase64[0] === '' || !decodedBase64[0]
          ? null
          : +decodedBase64[0],
      companyId: decodedBase64[1] ? +decodedBase64[1] : null,
      shopId: decodedBase64[2] ? +decodedBase64[2] : null,
    };
  } catch (e) {
    return { orderId: NaN, companyId: NaN, shopId: NaN };
  }
};

const getFloatQuantity = (unity: string) => parseFloat(removeMask(unity).trim());

export const convertUnityToKg = (unity: string, product: IProduct | ICartProduct): string => {
  const quantity = getFloatQuantity(unity === '' ? '0' : unity);

  return (quantity * product.average_weight).toString();
};

export const returnQuantityComma = (quantity: number, current_measure: string) =>
  current_measure === 'kg' ? `${quantity.toFixed(3).replace('.', ',')} KG` : quantity;

export const getDeliveryInfoByType = (type: string, shipment: IShipment) => {
  const types = shipment?.attendance_types || {};

  if (!shipment?.attendance_types || !(type in types)) return null;

  return shipment?.attendance_types[type as keyof IAttendanceType];
};

export const getDeliveryInfo = (shipment?: IShipment) => {
  if (!shipment) return null;

  const shipmentType = shipment?.delivery?.type || '';
  const shipmentInfo = getDeliveryInfoByType(shipmentType, shipment);

  return shipmentInfo;
};
