import './privacyPolicyPopup.scss';

import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { useTextColor } from '@/resources/hooks/useTextColor';
import { useWebContext } from '@/resources/hooks/useWebContext';
import { setPrivacyConsent } from '@/routes/settings';
import { ClientTypes } from '@/services/clients';
import { setConsentGiven } from '@/store/ducks/privacyPolicy/actions';

function PrivacyPolicyPopup() {
  const dispatch = useDispatch();
  const { configs, order, consentGiven } = useWebContext();
  const textColor = useTextColor(configs.default_color);
  const policyConsentStorage = Boolean(localStorage.getItem('policyConsent'));
  const clientConsentStorage = localStorage.getItem('clientConsent');

  const clientConsent = clientConsentStorage ? JSON.parse(clientConsentStorage) : [];
  const clientAccepted = clientConsent.includes(order?.client?.id);

  const [hidden, setHidden] = useState(true);

  const handleUpdateClientConsent = () => {
    if (order?.client.type !== ClientTypes.ANONYMOUS && order) {
      clientConsent.push(order.client?.id);

      localStorage.setItem('clientConsent', JSON.stringify(clientConsent));
    }
  };

  const setConsentGivenAnswer = () => {
    dispatch(setConsentGiven(true));

    localStorage.setItem('policyConsent', 'true');

    handleUpdateClientConsent();
  };

  const handleConsentGiven = () => {
    setHidden(true);
    setConsentGivenAnswer();

    if (order && order?.client.type !== ClientTypes.ANONYMOUS) {
      setPrivacyConsent(order.client?.id);
    }
  };

  useEffect(() => {
    const hasToSetConsent = !!(
      policyConsentStorage &&
      order &&
      order.client.type !== ClientTypes.ANONYMOUS
    );

    if (!policyConsentStorage || !consentGiven) setHidden(false);

    if (hasToSetConsent && !clientAccepted) {
      setPrivacyConsent(order.client?.id);

      handleUpdateClientConsent();
    }
  }, [order?.client?.id]);

  return (
    <div className={`privacy-policy-popup ${hidden ? 'hidden' : ''}`}>
      <div className="w-100">
        <Row className="popup-content m-0">
          <Col md={8}>
            <div className="d-flex flex-column">
              <span>{configs.cookies_popup_message ?? ''}</span>
              <span>
                Ao clicar em <b>estou ciente</b>, você concorda com nossa{' '}
                <Link to={`/privacy-policy`}>Política de Privacidade</Link>.
              </span>
            </div>
          </Col>
          <Col className="d-flex justify-content-end pt-2 pt-md-0" md={4}>
            <Button
              type="submit"
              style={{
                backgroundColor: configs.default_color,
                borderColor: configs.default_color,
                color: textColor,
              }}
              onClick={handleConsentGiven}
            >
              Estou ciente
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PrivacyPolicyPopup;
