import { ChangeEvent } from 'react';
import { MdSpeakerNotes } from 'react-icons/md';

import { setNote } from '../../../../routes/order';
import { canUseWebappInStatuses } from '../../../../services/global';
import { useSmartTextColor } from '../../../hooks/useSmartTextColor';

interface Props {
  orderId: number;
  status: number;
  note: string | null;
}

function Note({ note, orderId, status }: Props) {
  const handleBlur = (note: string) => setNote(orderId, note);
  const color = useSmartTextColor('#464646');

  return (
    <div className="my-2">
      <h6 style={{ color }}>
        <MdSpeakerNotes />
        <span> Alguma observação?</span>
      </h6>

      <textarea
        name="note"
        cols={12}
        rows={4}
        defaultValue={note ?? ''}
        className="form-control form-control-sm resize-none"
        disabled={!canUseWebappInStatuses({ status })}
        onBlur={(event: ChangeEvent<HTMLTextAreaElement>) => handleBlur(event.target.value)}
      ></textarea>
    </div>
  );
}

export default Note;
