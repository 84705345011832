import { useWebContext } from '@/resources/hooks/useWebContext';

import { humanizeDate, sumHoursToMinute } from '../../../../../../services/dates';

interface Props {
  pickupAt: string | null;
  arrow?: boolean;
  showTime?: boolean;
}
function RenderPickupAt({ pickupAt, arrow = false, showTime = true }: Props) {
  const { day, time } = humanizeDate(pickupAt);
  const { shop } = useWebContext();

  return (
    <>
      {pickupAt && (
        <>
          {arrow ? '>' : ''}
          <span> {day}</span>
          {showTime && <span>, {time}</span>}

          {shop.show_full_range && (
            <>
              <span> às {sumHoursToMinute(pickupAt, shop.interval)}</span>
            </>
          )}
        </>
      )}

      {!pickupAt && '---'}
    </>
  );
}

export default RenderPickupAt;
