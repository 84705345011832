export const saveFile = (urlOrBlob: string | Blob, filename: string) => {
  const isBlob = urlOrBlob instanceof Blob;
  const link = isBlob ? URL.createObjectURL(urlOrBlob) : urlOrBlob;
  const anchor = document.createElement('a');

  anchor.href = link;
  anchor.setAttribute('download', filename);
  anchor.click();

  if (isBlob) URL.revokeObjectURL(link);
};
