import { useCategoryContext } from '@/resources/hooks/useCategoryContext';

import BrandItem from './BrandItem';

interface Props {
  collapsed: boolean;
}

function BrandsFiltered({ collapsed }: Props) {
  const { brandsFiltered } = useCategoryContext();

  const getBrandsFiltered = () => {
    const brands = [...brandsFiltered];

    if (collapsed) return brands.slice(0, 8);

    return brandsFiltered;
  };

  return (
    <>
      {getBrandsFiltered().map((brand) => {
        return <BrandItem active={true} brand={brand} key={brand} />;
      })}
    </>
  );
}

export default BrandsFiltered;
