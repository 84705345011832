import { Link } from 'react-router-dom';

import notFoundImage from '../../../assets/images/404_ERROR.svg';

function Default() {
  return (
    <div
      className="NotFound text-center"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
      }}
    >
      <img
        className="img-fluid"
        src={notFoundImage}
        alt="Página não encontrada"
        style={{ maxWidth: '40rem' }}
      />

      <div>
        <h6 className="mb-4">Ops! Parece que a página que você está procurando não existe.</h6>

        <Link
          to={'/w'}
          className="btn btn-sm"
          style={{
            backgroundColor: '#facc34',
            borderRadius: '20px',
            padding: '.5rem 1.5rem',
            lineHeight: 1,
          }}
        >
          Voltar a página inicial
        </Link>
      </div>
    </div>
  );
}

export default Default;
