import { memo } from 'react';
import { FaTimesCircle } from 'react-icons/fa';

import { useCategoryContext } from '@/resources/hooks/useCategoryContext';

interface Props {
  brand: string;
  active?: boolean;
}

function BrandItem({ brand, active = false }: Props) {
  const { brandsFiltered, setBrandFilter } = useCategoryContext();

  const updateUrlParams = (updatedBrands: string[]) => {
    const encodedBrands = updatedBrands.map((brand) => encodeURIComponent(brand));
    const queryString = encodedBrands.join(',');
    const currentUrl = window.location.origin + window.location.pathname;
    const newUrl = `${currentUrl}?brands=${queryString}`;

    window.history.pushState({}, '', newUrl);
  };

  const getBrandsUpdated = (action: 'add' | 'remove') => {
    if (action === 'add') {
      return [...brandsFiltered, brand];
    }

    return brandsFiltered.filter((brandFiltered) => {
      return brandFiltered !== brand;
    });
  };

  const handleBrandFilter = (action: 'add' | 'remove') => {
    const updatedBrands = getBrandsUpdated(action);

    setBrandFilter(updatedBrands);

    updateUrlParams(updatedBrands);
  };

  return (
    <li
      key={brand}
      className={`list-inline-item ${active ? 'active' : ''}`}
      onClick={(e) => {
        e.stopPropagation();

        if (active) return;

        handleBrandFilter('add');
      }}
    >
      <span>{brand}</span>

      {active && (
        <FaTimesCircle
          className="ml-2"
          onClick={(e) => {
            e.stopPropagation();

            handleBrandFilter('remove');
          }}
        />
      )}
    </li>
  );
}

export default memo(BrandItem);
