import { memo } from 'react';
import { Button } from 'react-bootstrap';
import { FaMotorcycle, FaStore } from 'react-icons/fa';

interface Props {
  type: 'shop' | 'delivery';
  setShipmentType: (integrationType: 'delivery' | 'shop') => void;
}

function DeliveryMethod({ type, setShipmentType }: Props) {
  return (
    <Button
      variant="light"
      color="#f0f0f0"
      className="w-100 mb-2 shadow-sm btn-method"
      data-integration-type={type}
      onClick={() => setShipmentType(type)}
    >
      {type === 'shop' ? (
        <FaStore size={24} color="#343434" />
      ) : (
        <FaMotorcycle size={26} color="#343434" />
      )}

      <span className="ml-2">{type === 'shop' ? 'Retirar na loja' : 'Delivery'}</span>
    </Button>
  );
}

export default memo(DeliveryMethod);
