import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ApplicationState } from '../../../store';

function DefaultSettingsRedirect() {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const selectBase64 = useSelector<ApplicationState, string | null>((state) => state.base.data);
  const selectPath = pathname === '/' ? '/w' : pathname;

  useEffect(() => {
    if (!selectBase64) {
      const { REACT_APP_COMPANY_DEFAULT, REACT_APP_SHOP_DEFAULT } = process.env;
      const base64 = ['null', REACT_APP_COMPANY_DEFAULT, REACT_APP_SHOP_DEFAULT].join(';');

      history.push({
        pathname: `${selectPath}/${btoa(base64)}`,
        search,
      });
    } else {
      history.push({
        pathname: `${selectPath}/${selectBase64}`,
        search,
      });
    }
  }, [history, selectBase64]);

  return null;
}

export default DefaultSettingsRedirect;
