import { createContext } from 'react';

import { IBrand } from '@/store/ducks/categories/types';

export interface ICategoryContext {
  brandsFiltered: string[];
  brands: IBrand[];
  setBrandFilter: (brands: string[]) => void;
}

export const CategoryContext = createContext<ICategoryContext | null>(null);
