import { Col, Container, Row } from 'react-bootstrap';

import PrivacyPolicyPopup from '@/resources/components/privacyPolicyPopup/PrivacyPolicyPopup';
import { usePageBreakpoints } from '@/resources/hooks/usePageBreakpoints';
import { useWebContext } from '@/resources/hooks/useWebContext';

import { useNavbarContext } from '../../../../hooks/useNavbarContext';
import CartLink from '../../../navbar/CartLink';
import HighlightedCategoriesList from '../../../navbar/HighlightedCategoriesList';
import Logo from '../../../navbar/logo/Logo';
import SearchInput from '../../../navbar/SearchInput';
import SearchLink from '../../../navbar/SearchLink';
import ShopBadge from '../../../navbar/ShopBadge';
import DefaultStore from '../../../navbar/stores/DefaultStore';

function Desktop() {
  const { order, configs } = useWebContext();
  const { breakpoints } = usePageBreakpoints();

  const { pathname } = useNavbarContext();

  return (
    <div className="d-none d-md-flex flex-column w-100 align-self-stretch">
      <div
        className="d-flex align-items-center w-100"
        style={{ backgroundColor: configs.color, minWidth: '80px' }}
      >
        <Container fluid className="d-block p-0">
          <Row className="navbar-information">
            <Col {...breakpoints} className="px-0 d-flex flex-row breakpoint-page">
              <div className="d-flex  flex-column align-items-start justify-content-end">
                <Logo style={{ maxWidth: '185px' }} />
                <ShopBadge />
              </div>

              <div className="d-flex align-items-center mr-4 w-100">
                {pathname.includes('/search') ? <SearchInput /> : <SearchLink />}
                <CartLink size="28" />
              </div>

              <div className="d-flex align-items-center" style={{ width: '30%' }}>
                <DefaultStore />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="h-100" style={{ backgroundColor: configs.navbar_footer_color }}>
        <Container fluid>
          <Row className="justify-content-center w-100 py-1">
            <Col {...breakpoints} className="breakpoint-page d-flex flex-row">
              <HighlightedCategoriesList showActivedList />
            </Col>
          </Row>
        </Container>
      </div>

      {order && <PrivacyPolicyPopup />}
    </div>
  );
}

export default Desktop;
