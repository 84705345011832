import { FaTimesCircle } from 'react-icons/fa';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { isLightOrDark } from '@/services/lightOrDark';
import { IFilter, IFilterId } from '@/store/ducks/searches/types';

interface Props {
  itemsToShow: IFilterId[] | IFilter[];
  itemsFiltered: number[] | string[];
  setFiltered: Function;
}

function FilterItems({ itemsToShow, itemsFiltered, setFiltered }: Props) {
  const { configs } = useWebContext();

  const bgType = isLightOrDark(configs.bg_color);

  return (
    <>
      {itemsToShow.map((item: IFilterId | IFilter) => {
        const activeId = (itemsFiltered as number[]).includes((item as IFilterId).id)
          ? 'active'
          : '';

        const activeName = (itemsFiltered as string[]).includes((item as IFilter).name)
          ? 'active'
          : '';

        return (
          <div className="mb-1" key={`item-${item.name}`}>
            <span
              className={`item-filter bg-color-${bgType} px-1 ${activeName} ${activeId}`}
              onClick={() => {
                if (!(activeName || activeId)) setFiltered(item);
              }}
            >
              {item.name?.toLowerCase()} ({item.count})
              {(activeName || activeId) && (
                <FaTimesCircle
                  className="ml-2"
                  onClick={() => {
                    setFiltered(item);
                  }}
                />
              )}
            </span>
          </div>
        );
      })}
    </>
  );
}

export default FilterItems;
