import { action } from 'typesafe-actions';

import { CategoriesTypes, ICategory, ICategoryPaginator } from './types';

export const loadRequest = () => action(CategoriesTypes.LOAD_REQUEST);
export const loadSuccess = (categories: ICategory[]) =>
  action(CategoriesTypes.LOAD_SUCCESS, categories);
export const loadFailure = () => action(CategoriesTypes.LOAD_FAILURE);
export const loadCategoryGroup = () => action(CategoriesTypes.CATEGORY_GROUP_LOAD_REQUEST);
export const successCategoryGroup = (categoryGroup: ICategoryPaginator) =>
  action(CategoriesTypes.CATEGORY_GROUP_LOAD_SUCCESS, categoryGroup);
export const failureCategoryGroup = () => action(CategoriesTypes.CATEGORY_GROUP_LOAD_FAILURE);
export const cleanCategoryGroup = () => action(CategoriesTypes.CATEGORY_GROUP_CLEAN_DATA);
export const successCategoryGroupNextPage = (categoryGroup: ICategoryPaginator) =>
  action(CategoriesTypes.CATEGORY_GROUP_NEXT_PAGE, categoryGroup);
export const changingFilters = (changingFilters: boolean) =>
  action(CategoriesTypes.CATEGORY_GROUP_CHANGING_FILTERS, changingFilters);
export const setBrandsFiltered = (brandsFiltered: string[]) => {
  return action(CategoriesTypes.CATEGORY_GROUP_SET_BRANDS_FILTERED, brandsFiltered);
};
