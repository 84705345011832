import { IBooklet } from '../../../store/ducks/booklets/types';
import { useWindowSize } from '../../hooks/useWindowSize';
import BgImage from '../global/Image/BgImage';

interface Props {
  booklet: IBooklet;
}

function PagePreview({ booklet }: Props) {
  const { width } = useWindowSize();

  const scrollIntoView = (id: string) => {
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({ block: 'end', behavior: 'smooth' });
  };
  const getHeight = () => {
    if (width <= 880) return '100px';
    return '150px';
  };
  return (
    <>
      <div className="page-preview list-unstyled d-flex flex-column align-items-center w-100">
        <div className="page-preview-item my-2">
          <div
            onClick={() => {
              scrollIntoView('zoom-parent');
            }}
          >
            <BgImage src={booklet.link} styles={{ height: getHeight() }} />
          </div>
        </div>

        {booklet.sheets.length > 0 &&
          booklet.sheets.map((sheet) => {
            return (
              <div className="page-preview-item my-2" key={`preview-${sheet.id}`}>
                <div onClick={() => scrollIntoView(`zoom-${sheet.id}`)}>
                  <BgImage src={sheet.link} styles={{ height: getHeight() }} />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default PagePreview;
