import './shipment.scss';

import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../../../../store';
import { IOrder } from '../../../../store/ducks/order/types';
import Note from '../note/Note';
import Address from './address/Address';
import Identification from './client/Identification';
import CloseOrder from './payment/CloseOrder';
import Payment from './payment/Payment';

interface IShipmentContext {
  step: number;
  setStep: (step: 1 | 2 | 3 | 4) => void;
}

const ShipmentContext = createContext<IShipmentContext | null>(null);

export const useShipmentContext = (): IShipmentContext => {
  const context = useContext(ShipmentContext);

  if (!context)
    throw new Error('Esse compomente deve ser usado dentro de um <Shipment> componente.');

  return context;
};

interface Props {
  order: IOrder;
  handleFinishOrder: () => boolean;
  forwardedOrderAlert: (alert: boolean) => void;
  setShipmentStep: (step: 1 | 2 | 3 | 4) => void;
}

function Shipment({ setShipmentStep, handleFinishOrder, forwardedOrderAlert }: Props) {
  const selectOrder = useSelector<ApplicationState, IOrder | null>((state) => state.order.data);

  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);

  useEffect(() => setShipmentStep(step), [step, setShipmentStep]);

  return (
    <>
      {selectOrder && (
        <ShipmentContext.Provider value={{ step, setStep }}>
          <div className="shipment mb-2 mt-3 mt-sm-0">
            <Identification client={selectOrder.client} orderId={selectOrder.id} />
            <Address order={selectOrder} />
            <Payment order={selectOrder} />
          </div>
          <Note note={selectOrder.note} orderId={selectOrder.id} status={selectOrder.status} />
          <CloseOrder
            order={selectOrder}
            handleFinishOrder={handleFinishOrder}
            forwardedOrderAlert={forwardedOrderAlert}
          />
        </ShipmentContext.Provider>
      )}
    </>
  );
}

export default Shipment;
