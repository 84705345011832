import { memo } from 'react';
import { useSelector } from 'react-redux';

import { getFullAddress } from '@/services/global';
import { ApplicationState } from '@/store';
import { IIntegration } from '@/store/ducks/integrations/types';
import { IShopsAddress } from '@/store/ducks/shops/types';

interface Props {
  integration: IIntegration;
}

function StoreAddress({ integration }: Props) {
  const selectShops = useSelector<ApplicationState, IShopsAddress[]>((state) => state.shops.data);

  const getShopName = (shopId: number) => {
    const shop = selectShops.find((shop) => shop.id === shopId);

    return shop?.name || '';
  };

  const getShopAddress = (shopId: number) => {
    const shop = selectShops.find((shop) => shop.id === shopId);

    return shop?.address;
  };

  const address = getShopAddress(integration.shop_id);

  return (
    <>
      <p className="m-0 w-100" style={{ fontSize: '16px' }}>
        <strong>{getShopName(integration.shop_id)}</strong>
      </p>
      <p className="m-0 full-address" style={{ fontSize: '14px' }}>
        {address && <>{getFullAddress(address)}</>}
      </p>
    </>
  );
}

export default memo(StoreAddress);
