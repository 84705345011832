import { useSelector } from 'react-redux';

import { ApplicationState } from '../../store';
import { ITemplate, PageName } from '../../store/ducks/settings/types';

export const useTemplateByPage = (PageName: PageName, worldId?: number) => {
  const findByPage = (template: ITemplate) =>
    template.status === true && template.page_name === PageName;
  const findByWorldId = (template: ITemplate) =>
    template.status === true && template.world_id === worldId;

  return useSelector<ApplicationState, ITemplate | undefined>((state) =>
    state.settings.configs.chosen_templates.find(worldId ? findByWorldId : findByPage),
  );
};
