import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useAddShipping } from '@/resources/hooks/useAddShipping';
import { useWebContext } from '@/resources/hooks/useWebContext';
import { deletePayment, setShipmentAddress } from '@/routes/order';
import { ApplicationState } from '@/store';
import { IIntegration } from '@/store/ducks/integrations/types';
import { IShopsAddress } from '@/store/ducks/shops/types';

import IntegrationOption from './IntegrationOption';

interface Props {
  integrations: IIntegration[];
  handleShowSchedule: (deliveryType?: string, validAddress?: boolean) => void;
}

function ListStores({ integrations, handleShowSchedule }: Props) {
  const { order } = useWebContext();
  const selectShops = useSelector<ApplicationState, IShopsAddress[]>((state) => state.shops.data);

  const removePayments = () => {
    if (!order?.payments?.length) return;

    const activePayments = order.payments.filter((p) => !p.deleted_at);

    activePayments.map(({ id }) => deletePayment(id));
  };

  const addGTagEvents = (storeId: number) => {
    if (!order) return;

    const shop = selectShops.find((shop) => shop.id === storeId);
    const type = `Retirar na Loja (${shop?.name})`;

    useAddShipping(type, order);
  };

  const handleClickStore = async (event: MouseEvent<HTMLDivElement>) => {
    const storeId = parseInt(event.currentTarget.dataset.storeId!);

    if (!order) return;

    setShipmentAddress(order.id, storeId, { type: 'shop' }).then(() => {
      handleShowSchedule();
      removePayments();

      addGTagEvents(storeId);
      toast('Você alterou para buscar na loja!', { type: 'success' });
    });
  };

  return (
    <div>
      <div className="mb-2 px-2 text-secondary small">
        Escolha a loja de retirada de seus produtos:
      </div>

      {integrations.length ? (
        integrations.map((store) => (
          <IntegrationOption
            key={store.id}
            type="in-store"
            data={store}
            onClick={handleClickStore}
          />
        ))
      ) : (
        <h6 className="mb-0 text-center">Não há lojas disponíveis</h6>
      )}
    </div>
  );
}

export default ListStores;
