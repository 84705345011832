import { useLocation } from 'react-router-dom';

import { useTemplateByPage } from '../../hooks/useTemplateByPage';
import { useTextColor } from '../../hooks/useTextColor';

function TopAlertMessage() {
  const location = useLocation();
  const template = useTemplateByPage('home');
  const color = useTextColor(template?.top_alert_message?.bg_color ?? '#333');

  return (
    <>
      {template &&
        template.top_alert_message &&
        template.top_alert_message?.text &&
        template.top_alert_message.text.length > 0 &&
        location.pathname.includes('/w') && (
          <div
            className="text-center py-1"
            style={{ color, backgroundColor: template.top_alert_message.bg_color }}
          >
            {template.top_alert_message.text}
          </div>
        )}
    </>
  );
}

export default TopAlertMessage;
