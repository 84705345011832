import { useDevice } from '@/resources/hooks/useDevice';

import { generateRandomKey } from '../../../services/global';

function CategoryLoad() {
  const { device } = useDevice();
  const fill = device === 'desktop' ? 5 : 4;
  const emptyArray = new Array(fill).fill('');

  return (
    <div className="list-inline categories-list d-flex justify-content-around flex-wrap my-3">
      {emptyArray.map(() => (
        <div className="list-inline-item py-2 m-0" key={`category-load-${generateRandomKey()}`}>
          <div className="category-card skeleton-load"></div>

          <div className="category-name skeleton-load rounded mt-2"></div>
        </div>
      ))}
    </div>
  );
}

export default CategoryLoad;
