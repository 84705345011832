import { IProductsGroupByCategory } from '../products/types';

/**
 * Action types
 */
export enum CategoriesTypes {
  LOAD_REQUEST = '@categories/LOAD_REQUEST',
  LOAD_SUCCESS = '@categories/LOAD_SUCCESS',
  LOAD_FAILURE = '@categories/LOAD_FAILURE',
  CATEGORY_GROUP_LOAD_REQUEST = '@categories/CATEGORY_GROUP_LOAD_REQUEST',
  CATEGORY_GROUP_LOAD_SUCCESS = '@categories/CATEGORY_GROUP_LOAD_SUCCESS',
  CATEGORY_GROUP_LOAD_FAILURE = '@categories/CATEGORY_GROUP_LOAD_FAILURE',
  CATEGORY_GROUP_CLEAN_DATA = '@categories/CATEGORY_GROUP_CLEAN_DATA',
  CATEGORY_GROUP_NEXT_PAGE = '@categories/CATEGORY_GROUP_NEXT_PAGE',
  CATEGORY_GROUP_CHANGING_FILTERS = '@categories/CATEGORY_GROUP_CHANGING_FILTERS',
  CATEGORY_GROUP_SET_BRANDS_FILTERED = '@categories/CATEGORY_GROUP_SET_BRANDS_FILTERED',
}

/**
 * Data Types
 */
export interface ICategory {
  id: number;
  name: string;
  webapp_name: string;
  image: string | null;
  company_id: number;
  created_at: string;
  updated_at: string;
  external_code: string;
  has_promotion: boolean;
  active_ecommerce: boolean;
  highlighted_ecommerce: boolean;
  type: 'category' | 'group';
}

export interface ICategoryPaginator {
  error: boolean;
  loading: boolean;
  changingFilters: boolean;
  sizes: string[];
  brands: IBrand[];
  to: number;
  from: number;
  last_page: number;
  current_page: number;
  next_page_url: string;
  data: ICategoryGroupData[];
}

export interface IBrand {
  brand: string;
  count: number;
}

export interface ICategoryGroupData extends IProductsGroupByCategory {}

/**
 * State type
 */
export interface ICategoriesState {
  readonly data: ICategory[];
  readonly actived: ICategory[];
  readonly highlited: ICategory[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly categoryGroup: ICategoryPaginator;
  readonly brandsFiltered: string[];
}
