import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

function ScrollToTop({ children }: Props) {
  const location = useLocation();

  useEffect(() => window.scrollTo({ top: 0 }), [location]);

  return <>{children}</>;
}

export default ScrollToTop;
