import { memo, useEffect } from 'react';

interface Props {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
}

function CollapseItems({ isCollapsed, setIsCollapsed }: Props) {
  useEffect(() => setIsCollapsed(isCollapsed), [isCollapsed]);

  return (
    <span className="item-filter link" onClick={() => setIsCollapsed(!isCollapsed)}>
      <u>{isCollapsed ? 'Ver mais' : 'Ver menos'}</u>
    </span>
  );
}

export default memo(CollapseItems);
