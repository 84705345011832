import { Col, Row } from 'react-bootstrap';

import CategoryLoad from './CategoryLoad';

function CategoriesLoad() {
  return (
    <section className="categories-section">
      <Row className="mx-0">
        <Col xs={12} className="px-0">
          <CategoryLoad />
        </Col>
      </Row>
    </section>
  );
}

export default CategoriesLoad;
