export function isLightOrDark(color: string): 'light' | 'dark' {
  let r: any;
  let g: any;
  let b: any;
  let hsp: any;

  if (color.match(/^rgb/)) {
    const breakColor = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    if (breakColor) {
      r = breakColor[1];
      g = breakColor[2];
      b = breakColor[3];
    }
  } else {
    // @ts-ignore
    const breakColor = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    r = breakColor >> 16;
    g = (breakColor >> 8) & 255;
    b = breakColor & 255;
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  return hsp > 127.5 ? 'light' : 'dark';
}
