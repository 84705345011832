import './skeletonScreen.scss';

import Web from '../../layouts/Web';

function SkeletonScreen() {
  const render = () => {
    return <Web />;
  };

  return <>{render()}</>;
}

export default SkeletonScreen;
