import './askZipcode.scss';

import {
  ChangeEvent,
  ClipboardEvent,
  CSSProperties,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useTextColor } from '@/resources/hooks/useTextColor';
import { useWebContext } from '@/resources/hooks/useWebContext';
import { setShopByZipcode } from '@/routes/order';
import { delay } from '@/services/global';
import { ApplicationState } from '@/store';
import { zipcodeAnswered } from '@/store/ducks/order/actions';

interface Props {
  show: boolean;
}

function AskZipcode({ show }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const ref = useRef<HTMLDivElement>(null);

  const { order, configs } = useWebContext();
  const textColor = useTextColor(configs.default_color);

  const [visible, setVisible] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [zipcode, setZipcode] = useState<string>('');

  const selectIsZipcodeAnswered = useSelector<ApplicationState, boolean>(
    (state) => state.order.zipcodeAnswered,
  );

  const handleSetZipcode = (zipcode: string) => {
    const zipcodeFormatted = zipcode.replace('-', '');

    if (order && zipcodeFormatted.length === 8) {
      setIsLoading(true);

      setShopByZipcode(zipcodeFormatted, order.company_id, order.id)
        .then(() => {
          dispatch(zipcodeAnswered(true));
          setVisible(false);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleSetZipcode(zipcode);
  };

  const getStyles = (): CSSProperties => {
    if (visible) return { visibility: 'visible', opacity: 1 };

    return { visibility: 'hidden', opacity: 0 };
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setVisible(false);
    }
  };

  const setInputValue = (value: string) => {
    const pattern = /(\d{5})(\d{3})/;
    const regex = new RegExp(pattern, 'g');

    setZipcode(value.replace(/\D+/g, '').slice(0, 8).replace(regex, '$1-$2'));
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const hasToShowPopup = () =>
    show &&
    configs.ask_zipcode &&
    !selectIsZipcodeAnswered &&
    !order?.client?.favorite_purchase_shop_id;

  useEffect(() => {
    const hasShow = hasToShowPopup();

    setVisible(hasShow);
  }, [show]);

  useEffect(() => {
    delay(() => {
      if (!selectIsZipcodeAnswered && !order?.client?.favorite_purchase_shop_id) {
        setVisible(true);
      }
    }, 300000);
  }, [location.pathname, order?.client?.favorite_purchase_shop_id]);

  return (
    <>
      <div className="ask-zipcode" style={getStyles()} ref={ref}>
        <div className="zipcode-content">
          <p className="title-zipcode mb-1">Coloque seu CEP aqui</p>
          <p className="subtitle-zipcode">Para localizar a loja mais próxima de você</p>

          <form className="mt-4" onSubmit={handleSubmit}>
            <Container fluid>
              <Row className="d-flex align-items-center text-right">
                <Col xs={9} className="p-0">
                  <input
                    type="text"
                    name="zipcode"
                    autoComplete="off"
                    placeholder="_____-___"
                    className="form-control "
                    disabled={isLoading}
                    value={zipcode}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;

                      setInputValue(value);
                    }}
                    onPaste={(event: ClipboardEvent<HTMLInputElement>) => {
                      const value = event.clipboardData?.getData('text/plain');

                      setInputValue(value);
                    }}
                  />
                </Col>
                <Col xs={3} className="p-0">
                  <Button
                    type="submit"
                    className="zipcode-btn"
                    style={{
                      backgroundColor: configs.default_color,
                      borderColor: configs.default_color,
                      color: textColor,
                    }}
                    disabled={isLoading || !zipcode || zipcode.length !== 9}
                  >
                    OK
                  </Button>
                </Col>
              </Row>
            </Container>
          </form>
        </div>
      </div>
      <div className={`background-overlay ${visible ? 'active' : ''}`}></div>
    </>
  );
}

export default AskZipcode;
