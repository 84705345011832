import { Col, Container, Row } from 'react-bootstrap';

import { useWindowSize } from '@/resources/hooks/useWindowSize';

import { generateRandomKey } from '../../../services/global';
import { IBreakpoints } from '../../hooks/usePageBreakpoints';
import ProductLoad from './ProductCardLoad';

interface Props {
  type: 'list' | 'grid';
  quantity?: number;
  breakpoints?: IBreakpoints;
}

function ProductsLoad({ type, breakpoints }: Props) {
  const { width } = useWindowSize();

  const getQuantityByWindowSize = () => {
    if (width < 600) return 2;
    if (width < 850) return 3;
    if (width < 1200) return 4;
    if (width < 1550) return 5;

    return 6;
  };

  const quantity = type === 'list' ? getQuantityByWindowSize() : 30;

  const products = new Array(quantity).fill('');

  const list = () => (
    <ul className="products-list list-inline" style={{ overflowX: 'hidden' }}>
      {products.map(() => (
        <li
          key={`product-load-${generateRandomKey()}`}
          className="product-card list-inline-item w-100 m-2 m-sm-2 m-md-2"
        >
          <ProductLoad />
        </li>
      ))}
    </ul>
  );

  const grid = () => (
    <Row>
      {products.map(() => (
        <Col
          xs={6}
          sm={4}
          md={3}
          lg={2}
          className="py-2 px-1"
          key={`product-load-${generateRandomKey()}`}
        >
          <div className="product-card">
            <ProductLoad />
          </div>
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      {type === 'list' ? (
        list()
      ) : (
        <Container>
          <Row className="justify-content-center mx-0">
            <Col {...breakpoints} className="px-0">
              {grid()}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default ProductsLoad;
