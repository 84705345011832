import { useContext } from 'react';

import { IPageContext, PageContext } from '../components/contexts/PageContext';

export const usePageContext = (): IPageContext => {
  const context = useContext(PageContext);

  if (!context)
    throw new Error('Esse compomente deve ser usado dentro de um <PageContext> componente.');

  return context;
};
