import { Reducer } from 'redux';

import { ISearchesState, SearchesTypes } from './types';

const DEFAULT_STATE: ISearchesState = {
  data: null,
  error: false,
  loading: false,
  touched: false,
  titleSearched: '',
};

const reducer: Reducer<ISearchesState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SearchesTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case SearchesTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        touched: true,
        // eslint-disable-next-line prefer-object-spread
        data: Object.assign({}, action.payload),
      };
    case SearchesTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, touched: false, data: null };
    case SearchesTypes.CLEAN_DATA:
      return { ...state, touched: false, data: null, titleSearched: '' };
    case SearchesTypes.SET_TITLE_SEARCHED:
      return { ...state, titleSearched: action.payload };
    default:
      return state;
  }
};

export default reducer;
