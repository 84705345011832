/**
 * Action types
 */
export enum BookletsTypes {
  LOAD_REQUEST = '@booklets/LOAD_REQUEST',
  LOAD_SUCCESS = '@booklets/LOAD_SUCCESS',
  LOAD_FAILURE = '@booklets/LOAD_FAILURE',
}

/**
 * Data Types
 */

export interface IBooklet {
  id: number;
  name: string;
  caption?: string;
  start: string;
  end: string;
  link: string;
  company_id: number;
  shops: number[];
  pivot: IPivot;
  created_at: string;
  updated_at: string;
  links: string[];
  sheets: IBookletSheet[];
}

export interface IPivot {
  shop_id: number;
  booklet_id: number;
}

interface IBookletSheet {
  booklet_id: number;
  created_at: string;
  link: string;
  updated_at: string;
  id: number;
}

export interface IBookletsState {
  readonly data: IBooklet[];
  readonly loading: boolean;
  readonly error: boolean;
}
