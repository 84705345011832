import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';

import { getPreToken } from '@/routes/login';
import { setTokenValidated } from '@/store/ducks/token/actions';

import { store } from '../store';
import * as OrderActions from '../store/ducks/order/actions';
import { IOrder } from '../store/ducks/order/types';
import { destroyToken, getToken, setHeaderAuthorization } from './auth';

let previousRequest = '';

const { dispatch } = store;

const fulfillHeaders = () => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  return typeof token !== 'undefined' ? { authorization: `Bearer ${token}` } : {};
};

const api = axios.create({
  withCredentials: true,
  headers: fulfillHeaders(),
  baseURL: `${process.env.REACT_APP_BV_URL}/api/v1`,
});

const requestHandler = async (config: AxiosRequestConfig) => {
  const token = getToken();
  const currentRequest = config.url;
  const isSetUpdatedAtRequest = config.url?.includes('/updated-at');

  if (previousRequest === currentRequest && isSetUpdatedAtRequest) {
    previousRequest = currentRequest ?? '';

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(false);
  }

  if (token) setHeaderAuthorization(token);

  previousRequest = currentRequest ?? '';

  return config;
};

const errorHandler = (error: AxiosError) => Promise.reject(error);

const getOrderId = (order: IOrder | null = store.getState().order.data) =>
  order ? order.id : null;
const setUpdatedAt = async (orderId: number | null = getOrderId()) => {
  return api
    .put<IOrder, IOrder>(`/orders/${orderId}/updated-at`)
    .then((order) => store.dispatch(OrderActions.loadSuccess(order)))
    .catch(() => store.dispatch(OrderActions.loadFailure()));
};

const responseHandler = (response: AxiosResponse) => {
  const orderId = getOrderId();
  const isLoginRequest = response.request?.responseURL.includes('/secret');
  const isSetUpdatedAtRequest = response.request?.responseURL.includes('/updated-at');

  if (orderId && !isSetUpdatedAtRequest && !isLoginRequest) setUpdatedAt(orderId);

  return response.data;
};

const errorResponseHandler = async (error: AxiosError) => {
  const currentResponse = error.response;
  const originalRequest = error.config;

  if (currentResponse?.status === 403 || currentResponse?.status === 401) {
    destroyToken();
    dispatch(setTokenValidated(false));

    getPreToken(Number(process.env.REACT_APP_COMPANY_DEFAULT)).then(({ token }) => {
      if (!token) {
        destroyToken();

        return;
      }

      dispatch(setTokenValidated(true));

      if (originalRequest.url?.includes('/validate-order')) return;

      window.location.reload();
    });

    return;
  }

  // eslint-disable-next-line consistent-return
  return Promise.reject(error);
};

api.interceptors.request.use(
  async (config: AxiosRequestConfig) => requestHandler(config),
  (error: AxiosError) => errorHandler(error),
);

api.interceptors.response.use(
  (response: AxiosResponse) => responseHandler(response),
  (error: AxiosError) => errorResponseHandler(error),
);

export default api;
