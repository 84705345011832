import { action } from 'typesafe-actions';

import { ISettingBase, ISettingConfig, SettingsTypes } from './types';

export const setConfigs = (configs: ISettingConfig) =>
  action(SettingsTypes.SET_CONFIGS, { ...configs, fulfilled: true });
export const setSettings = (settings: ISettingBase) =>
  action(SettingsTypes.SET_BASE_SETTINGS, settings);
export const setWebappEnable = (is_enabled: boolean) =>
  action(SettingsTypes.SET_WEBAPP_ENABLED, is_enabled);
