import { AxiosError, AxiosResponse } from 'axios';

export const createErrorMessage = (error: AxiosError, defaultMessage: string) => {
  const { errors } = (error.response as AxiosResponse).data;

  const message: string[] = [];

  (Object.keys(errors || { error: undefined }) as (keyof string)[]).forEach((key) => {
    if (errors && errors[key]) message.push(errors[key]);
  });

  return message.length > 0 ? message.join(', ') : defaultMessage;
};
