import { useDevice } from '@/resources/hooks/useDevice';

import TextLoad from './TextLoad';

function ProductLoad() {
  const { device } = useDevice();

  const getHeight = () => {
    if (device === 'mobile') return '200px';
    return '250px';
  };

  return (
    <div>
      <div
        className="image-wrapper"
        style={{
          height: getHeight(),
        }}
      >
        <div className="image product-load-image"></div>
      </div>

      <div className="info">
        <TextLoad width="100%" />
        <TextLoad width="60%" />
      </div>
    </div>
  );
}

export default ProductLoad;
