interface Props {
  style?: React.CSSProperties;
  className?: string;
}

function Skeleton({ className = '', style }: Props) {
  return <div className={`skeleton-load ${className}`} style={style}></div>;
}

export default Skeleton;
