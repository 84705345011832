import './closedOrderAlertModal.scss';

import { Button, Modal } from 'react-bootstrap';
import { FaRegComments } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { useTextColor } from '@/resources/hooks/useTextColor';
import { useWebContext } from '@/resources/hooks/useWebContext';

import CustomModal from '../../global/CustomModal';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}

function ClosedOrderAlertModal({ show, setShow }: Props) {
  const history = useHistory();
  const { configs } = useWebContext();
  const textColor = useTextColor(configs.default_color);

  return (
    <CustomModal
      id="waiting-seller-modal"
      show={show}
      onHide={() => setShow(false)}
      centered={true}
    >
      <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-column align-items-center alert-body">
          <FaRegComments
            size={100}
            style={{
              color: configs.default_color,
            }}
          />

          <span className="title">Aguardando atendimento</span>

          <span className="subtitle">
            Seu pedido já foi encaminhado para a equipe de vendas, por favor, aguarde.
          </span>
        </div>

        <div className="alert-footer">
          <Button variant="ligth" onClick={() => setShow(false)}>
            Cancelar
          </Button>

          <Button
            style={{
              backgroundColor: configs.default_color,
              borderColor: configs.default_color,
              color: textColor,
            }}
            onClick={() => {
              setShow(false);
              history.push(`/`);
            }}
          >
            Continuar comprando
          </Button>
        </div>
      </Modal.Body>
    </CustomModal>
  );
}

export default ClosedOrderAlertModal;
