/**
 * Action types
 */
export enum PaymentTypes {
  SET_PAID_ALERT = '@payment/SET_PAID_ALERT',
  SET_SHOW_PAID_ALERT = '@payment/SET_SHOW_PAID_ALERT',
}

/**
 * Data Types
 */
export interface IPaymentAlertAsks {
  has_shown_paid_alert: boolean;
  show_paid_alert: boolean;
}

/**
 * State type
 */
export interface IPaymentState {
  readonly asks: IPaymentAlertAsks;
}
