import './search.scss';

import { lazy, Suspense, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import SearchFilter from '@/resources/components/filters/searchFilter/SearchFilter';

import { ApplicationState } from '../../../store';
import { cleanData } from '../../../store/ducks/searches/actions';
import { ISearch } from '../../../store/ducks/searches/types';
import { PageContext } from '../../components/contexts/PageContext';
import SearchLoad from '../../components/loads/SearchLoad';
import { useTemplateByPage } from '../../hooks/useTemplateByPage';
import { useWebContext } from '../../hooks/useWebContext';

const Products = lazy(() => import('../../components/products/Products'));

function Search() {
  const dispatch = useDispatch();

  const { configs } = useWebContext();
  const template = useTemplateByPage('home');

  const selectTouched = useSelector<ApplicationState, boolean>((state) => state.searches.touched);
  const selectIsSearching = useSelector<ApplicationState, boolean>(
    (state) => state.searches.loading,
  );
  const selectSearches = useSelector<ApplicationState, ISearch | null>(
    (state) => state.searches.data,
  );
  const selectTitleSearched = useSelector<ApplicationState, string>(
    (state) => state.searches.titleSearched,
  );

  const searchFilterActive = Object.values(configs.search_filters).some((filter) => filter);

  useEffect(() => {
    return () => {
      dispatch(cleanData());
    };
  }, [dispatch]);

  return (
    <PageContext.Provider value={{ template }}>
      <section className="Search" style={{ padding: '2rem 0' }}>
        <Container fluid className="px-md-0 breakpoint-page">
          <Row className="justify-content-center px-md-5">
            {searchFilterActive && (
              <SearchFilter search={selectSearches} title={selectTitleSearched} />
            )}
            <Col>
              {!selectTouched && !selectIsSearching && !selectSearches && (
                <div className="search-icon">
                  <BiSearch size={300} />
                </div>
              )}

              {selectIsSearching ? (
                <SearchLoad />
              ) : (
                <ul className="list-unstyled mb-0">
                  <Suspense fallback={<div />}>
                    <Products
                      type="grid"
                      productType="one-line"
                      breakpoints={{ xs: 12 }}
                      keyName="searched-products"
                      products={selectSearches?.products}
                    />
                  </Suspense>

                  {selectTouched && selectSearches?.products.length === 0 && (
                    <div className="text-center">
                      <h5 className="mt-2">Nenhum resultado foi encontrado.</h5>
                      <Link to={'/w'} className="btn btn-info">
                        Voltar para a página inicial
                      </Link>
                    </div>
                  )}
                </ul>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </PageContext.Provider>
  );
}

export default Search;
