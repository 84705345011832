import { PageContext } from '@/resources/components/contexts/PageContext';
import { useTemplateByPage } from '@/resources/hooks/useTemplateByPage';
import Default from '@/resources/themes/privacyPolicy/default/Default';

function PrivacyPolicy() {
  const template = useTemplateByPage('privacy-policy');

  const render = () => {
    switch (template?.template_name) {
      default:
        return <Default />;
    }
  };

  return <PageContext.Provider value={{ template }}>{render()}</PageContext.Provider>;
}

export default PrivacyPolicy;
