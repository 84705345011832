import { Fragment, memo, ReactNode } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { createPortal } from 'react-dom';

interface Props {
  id?: string;
  show: boolean;
  children: ReactNode;
  centered: boolean;
  onHide: () => void;
  className?: string;
  [key: string]: any;
}

function CustomModal({ id, show, children, centered, onHide, ...props }: Props) {
  return show
    ? createPortal(
        <Fragment>
          <BootstrapModal
            id={id ?? ''}
            show={show}
            onHide={onHide}
            animation={false}
            centered={centered}
            {...props}
          >
            {children}
          </BootstrapModal>
        </Fragment>,
        document.body,
      )
    : null;
}

export default memo(CustomModal);
