import { useEffect } from 'react';

import { LazyLoadImages } from '@/services/lazyLoadImage';

import { INetwork } from '../../../../store/ducks/settings/types';

interface Props {
  socials: INetwork[];
}

function Socials({ socials }: Props) {
  useEffect(() => {
    LazyLoadImages();
  }, []);

  return (
    <ul className="list-inline mb-0">
      {socials
        .filter((n) => n.type !== 'whatsapp')
        .map((network, index) => (
          <li key={index} className="list-inline-item mx-2 py-1">
            <a href={network.link} target="_blank" rel="noreferrer">
              <img
                className="social-images lazy-image"
                width={23}
                height={23}
                src=""
                data-src={network.image}
                alt={network.type}
              />
            </a>
          </li>
        ))}
    </ul>
  );
}

export default Socials;
