interface Props {
  width: string;
  styles?: {};
}

function BadgeLoad({ width, styles }: Props) {
  return (
    <div
      className="skeleton-load"
      style={{ width, ...styles, height: '13px', borderRadius: '.25rem' }}
    ></div>
  );
}

export default BadgeLoad;
