import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useSearch } from '@/resources/hooks/useSearch';

import { smartSearch } from '../../../routes/products';
import { delay } from '../../../services/global';
import { ApplicationState } from '../../../store';
import * as SearchesActions from '../../../store/ducks/searches/actions';
import { useQueryParam } from '../../hooks/useQueryParam';
import { useWebContext } from '../../hooks/useWebContext';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

function SearchInput() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { order } = useWebContext();
  const searchParam = useQueryParam('search');
  const { pathname } = useLocation();

  const ref = useRef<HTMLInputElement>(null);

  const selectLoading = useSelector<ApplicationState, boolean>((state) => state.searches.loading);
  const selectTitleSearched = useSelector<ApplicationState, string>(
    (state) => state.searches.titleSearched,
  );

  const [timeouts, setTimeouts] = useState<NodeJS.Timeout[]>([]);

  const handleBack = () => history.goBack();

  const handleClear = () => {
    if (ref.current) ref.current.value = '';
  };

  const setHistorySearch = (title: string | null) => {
    history.replace({
      pathname,
      search: title ? `?search=${title.replace(/ /g, '-')}` : '',
    });
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const title = e.currentTarget.value;

    if (title === '') {
      setHistorySearch(null);
      dispatch(SearchesActions.cleanData());
    }

    if (e.key === 'Enter' && title.length >= 3) {
      e.preventDefault();
      timeouts.forEach((timeout) => clearTimeout(timeout));

      if (order) {
        setHistorySearch(title);
        smartSearch({ shopId: order.dst_shop_id, title }).then(() => {
          useSearch(title);
          scrollToTop();
        });
      }
    } else {
      const timeout = delay(() => {
        if (title.length >= 3 && order) {
          setHistorySearch(title);
          smartSearch({ shopId: order.dst_shop_id, title }).then(() => {
            useSearch(title);
            scrollToTop();
          });
        }
      }, 2500);

      setTimeouts((old) => [...old, timeout]);
    }
  };

  useEffect(() => {
    if (searchParam && order && ref.current) {
      const title = decodeURIComponent(searchParam).replace(/-/g, ' ');

      smartSearch({ shopId: order.dst_shop_id, title }).then(() => {
        useSearch(title);
        scrollToTop();
      });
    }
  }, [dispatch, order?.dst_shop_id]);

  return (
    <div className="w-100 d-flex position-relative">
      <button
        onClick={handleBack}
        className="btn btn-sm align-items-center position-absolute"
        style={{
          borderTopLeftRadius: '.2rem',
          borderBottomLeftRadius: '.2rem',
          zIndex: 1,
          top: 0,
          bottom: 0,
        }}
      >
        <FaChevronLeft />
      </button>

      <input
        type="text"
        name="search"
        autoFocus={true}
        autoComplete="off"
        className="form-control"
        placeholder="Pesquisar: Nome, marca"
        onKeyUp={handleKeyUp}
        disabled={selectLoading}
        defaultValue={selectTitleSearched}
        style={{ paddingLeft: '2rem', height: '40px' }}
        ref={ref}
      />

      <button
        onClick={handleClear}
        className="btn btn-sm align-items-center position-absolute"
        style={{
          borderTopLeftRadius: '.2rem',
          borderBottomLeftRadius: '.2rem',
          zIndex: 1,
          top: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <GrFormClose size={20} />
      </button>
    </div>
  );
}

export default SearchInput;
