import { IProduct } from '../products/types';

/**
 * Action types
 */
export enum SearchesTypes {
  LOAD_REQUEST = '@searches/LOAD_REQUEST',
  LOAD_SUCCESS = '@searches/LOAD_SUCCESS',
  LOAD_FAILURE = '@searches/LOAD_FAILURE',
  CLEAN_DATA = '@searches/CLEAN_DATA',
  SET_TITLE_SEARCHED = '@searches/SET_TITLE_SEARCHED',
}

/**
 * Data Types
 */
export interface ISearch {
  products: IProduct[];
  brands: IBrandFilter[];
  categories: ICategoryFilter[];
  groups: IGroupFilter[];
  sizes: ISizeFilter[];
  prices: IPriceFilter[];
}

export interface IFilter {
  name: string;
  id?: number;
  count: number;
}

export interface IFilterId extends IFilter {
  id: number;
}

interface IBrandFilter extends IFilter {}

interface ICategoryFilter extends IFilterId {}

interface IGroupFilter extends IFilterId {}

interface ISizeFilter extends IFilter {}

export interface IPriceFilter {
  min_price: number;
  max_price: number;
  count: number;
}

/**
 * State type
 */
export interface ISearchesState {
  readonly data: ISearch | null;
  readonly loading: boolean;
  readonly touched: boolean;
  readonly titleSearched: string;
  readonly error: boolean;
}
