import './freeShippingInLine.scss';

import { useState } from 'react';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { IDeliveryDiscountData } from '@/store/ducks/deliveryDiscount/types';

import ProductShippingRulesModal from './rulesModal/ProductShippingRulesModal';

interface Props {
  classes?: string;
  text?: string;
  deliveryDiscountData?: IDeliveryDiscountData;
}

function FreeShippingInLine({ classes, text, deliveryDiscountData }: Props) {
  const { configs } = useWebContext();
  const [show, setShow] = useState<boolean>(false);

  return (
    <div
      className={`free-shipping-in-line ${classes ?? ''}`}
      style={{
        border: `1px solid ${configs.free_shipping_tag_color}`,
        color: configs.free_shipping_tag_color,
      }}
    >
      {text && <span>{text}</span>}

      {!text && (
        <span className="d-flex flex-column align-items-center">
          <span>COMPRE E GANHE FRETE GRÁTIS</span>
          <small
            className="d-flex flex-row align-items-center"
            style={{ color: '#000', fontSize: '0.7rem' }}
          >
            *Consulte as regras{' '}
            <button
              type="button"
              className="btn btn-sm p-0 text-primary font-weight-bold"
              style={{ fontSize: '0.7rem' }}
              onClick={() => {
                setShow(true);
              }}
            >
              <u>aqui</u>
            </button>
          </small>

          {deliveryDiscountData && (
            <ProductShippingRulesModal
              show={show}
              setShow={setShow}
              deliveryDiscountData={deliveryDiscountData}
            />
          )}
        </span>
      )}
    </div>
  );
}

export default FreeShippingInLine;
