const setImageObserved = (observer: IntersectionObserver) => {
  document.querySelectorAll('.lazy-image').forEach((imageElement) => {
    const isImageObserved =
      imageElement.getAttribute('is-observed') === 'true' &&
      imageElement.getAttribute('data-src') === null;

    if (!isImageObserved) {
      imageElement.setAttribute('is-observed', 'true');
      observer.observe(imageElement);
    }
  });
};

const setBackgroundImage = (entry: IntersectionObserverEntry, observer: IntersectionObserver) => {
  if (entry.target.hasAttribute('data-src') && entry.target.localName !== 'img') {
    const targetElement = entry.target as HTMLImageElement;

    targetElement.style.backgroundImage = `url(${entry.target.getAttribute('data-src')})`;

    observer.unobserve(entry.target);
  }
};

const setImageSource = (entry: IntersectionObserverEntry, observer: IntersectionObserver) => {
  if (entry.target.hasAttribute('data-src') && entry.target.localName === 'img') {
    entry.target.setAttribute('src', entry.target.getAttribute('data-src') as string);

    observer.unobserve(entry.target);
  }
};

const setImagePropeties = (entry: IntersectionObserverEntry, observer: IntersectionObserver) => {
  setBackgroundImage(entry, observer);

  setImageSource(entry, observer);
};

const setAllImagesPropeties = () => {
  const imgList = document.querySelectorAll('.lazy-image');
  const imgBackgroundList = document.querySelectorAll('.lazy-image .lazy-background');

  Array.prototype.forEach.call(imgList, function setImage(image) {
    image.setAttribute('src', image.getAttribute('data-src'));
  });

  Array.prototype.forEach.call(imgBackgroundList, function setImage(image) {
    image.style.backgroundImage = `url(${image.getAttribute('data-src')})`;
  });
};

export const LazyLoadImages = () => {
  if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          setImagePropeties(entry, observer);
        }
      });
    });
    setImageObserved(observer);
  } else {
    setAllImagesPropeties();
  }
};
