import { compose, createStore, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { IBaseState } from './ducks/base/types';
import { IBookletsState } from './ducks/booklets/types';
import { ICategoriesState } from './ducks/categories/types';
import { IClientState } from './ducks/client/types';
import { ICustomerState } from './ducks/customer/types';
import { IDeliveryDiscountState } from './ducks/deliveryDiscount/types';
import { IDeliveryMethodsState } from './ducks/deliveryMethods/types';
import { IIntegrationState } from './ducks/integrations/types';
import { IOrderState } from './ducks/order/types';
import { IPaymentState } from './ducks/payment/types';
import { IPrivacyPolicyState } from './ducks/privacyPolicy/types';
import { IProductListsState } from './ducks/productLists/types';
import { IProductsGroupByCategoryState } from './ducks/products/types';
import rootReducer from './ducks/rootReducer';
import { ISearchesState } from './ducks/searches/types';
import { ISettingsState } from './ducks/settings/types';
import { IShopConfigState } from './ducks/shopConfig/types';
import { IShopsState } from './ducks/shops/types';
import { ITokenState } from './ducks/token/types';
import { IWorldsState } from './ducks/worlds/types';

export interface ApplicationState {
  base: IBaseState;
  order: IOrderState;
  worlds: IWorldsState;
  customer: ICustomerState;
  settings: ISettingsState;
  searches: ISearchesState;
  categories: ICategoriesState;
  integrations: IIntegrationState;
  productLists: IProductListsState;
  productsGroupByCategory: IProductsGroupByCategoryState;
  booklets: IBookletsState;
  client: IClientState;
  privacyPolicy: IPrivacyPolicyState;
  deliveryMethods: IDeliveryMethodsState;
  shops: IShopsState;
  shopConfig: IShopConfigState;
  token: ITokenState;
  deliveryDiscount: IDeliveryDiscountState;
  payment: IPaymentState;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig = {
  storage,
  key: 'base',
  whitelist: ['base', 'customer', 'payment'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store: Store<any> = createStore(persistedReducer, {}, composeEnhancers());
export const persistor = persistStore(store);
