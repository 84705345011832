import { Col, Container, Row } from 'react-bootstrap';

import { IBreakpoints } from '../../hooks/usePageBreakpoints';

function CategoryImageLoad({ breakpoints }: IBreakpoints) {
  return (
    <Container className="px-0" fluid>
      <Row className="justify-content-center mx-0">
        <Col {...breakpoints} className=" position-relative px-0">
          <div className="category-distinction">
            <div className="skeleton-load" style={{ height: '313px' }}></div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CategoryImageLoad;
