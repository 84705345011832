import { ICartProduct } from '../../store/ducks/order/types';
import { IProduct } from '../../store/ducks/products/types';
import { useSlug } from './useSlug';

interface Props {
  product: IProduct | ICartProduct;
}

export const useProductLink = ({ product }: Props): string => {
  const title = useSlug({ str: product.title });

  return `/product/${product.product_id}/${encodeURIComponent(title)}`;
};
