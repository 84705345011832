import './booklets.scss';

import { MouseEvent, useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';

import { useDeviceWindow } from '@/resources/hooks/useDeviceWindow';
import { removeMarkdown } from '@/services/global';

import { IBooklet } from '../../../store/ducks/booklets/types';
import PagePreview from './PagePreview';

interface Props {
  show: boolean;
  booklet: IBooklet;
  handleClose: () => void;
}

function ExpandBookletModal({ show, booklet, handleClose }: Props) {
  const { device } = useDeviceWindow();

  const [showModal, setShowModal] = useState<boolean>(show);
  const [transformOrigin, setTransformOrigin] = useState<string>('');
  const [transform, setTransform] = useState<string>('scale(1)');

  const [imageOnZoom, setIimageOnZoom] = useState<string>('');

  useEffect(() => setShowModal(show), [show]);

  const handleMouseEvent = (event: MouseEvent<HTMLDivElement>) => {
    if (device === 'mobile') return;

    const { left, top } = event.currentTarget.getBoundingClientRect();
    const x = event.pageX - left;
    const y = event.pageY - top;

    setTransformOrigin(`${x}px ${y}px`);
    setTransform('scale(2)');
  };

  const getStyle = () => {
    return { transform, transformOrigin };
  };

  const handleMouseEnter = (image: string) => {
    if (device === 'mobile') return;

    setIimageOnZoom(image);
  };

  const handleMouseLeave = () => {
    if (device === 'mobile') return;

    setTransform('scale(1)');
  };

  return (
    <Modal
      id="expand-booklet-modal"
      show={showModal}
      centered={true}
      animation={false}
      onHide={handleClose}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-truncate">
          {removeMarkdown(booklet.caption?.toUpperCase() || '')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center p-0 modal-zoom">
        <Container className="p-0 m-0 booklet-container">
          <Row className="w-100 m-0 h-100">
            <Col sm={3} className="col-preview sticky-top p-3">
              <PagePreview booklet={booklet} />
            </Col>
            <Col sm={9} className="col-zoom d-flex justify-content-center">
              <div className="d-flex flex-column align-items-center w-100">
                {booklet.caption && (
                  <div className="caption text-secondary px-3">
                    {removeMarkdown(booklet.caption || '')}
                  </div>
                )}

                <div className="img-wrapper d-flex flex-column align-items-center w-100">
                  <div
                    className="booklet-image"
                    onMouseEnter={() => handleMouseEnter('zoom-parent')}
                    onMouseMove={handleMouseEvent}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img
                      src={booklet.link}
                      className="img-fluid booklet-image mt-2"
                      style={imageOnZoom === 'zoom-parent' ? getStyle() : {}}
                      id="zoom-parent"
                      key="zoom-0"
                    />
                  </div>

                  {booklet.sheets.length > 0 &&
                    booklet.sheets.map((sheet) => (
                      <div
                        key={sheet.id}
                        className="booklet-image"
                        onMouseEnter={() => handleMouseEnter(`zoom-${sheet.id}`)}
                        onMouseMove={handleMouseEvent}
                        onMouseLeave={handleMouseLeave}
                      >
                        <img
                          src={sheet.link}
                          style={imageOnZoom === `zoom-${sheet.id}` ? getStyle() : {}}
                          className="img-fluid "
                          key={`zoom-${sheet.id}`}
                          id={`zoom-${sheet.id}`}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ExpandBookletModal;
