import { BsCartCheckFill } from 'react-icons/bs';
import { FaBox, FaBoxOpen, FaMotorcycle, FaParachuteBox, FaUsers } from 'react-icons/fa';

import { OrderStatus } from '../../../../services/global';
import { IShipmentType } from '../../../../store/ducks/order/types';

interface Props {
  status: number;
  shipmentType: IShipmentType | null;
}

export type IStatusStep =
  | 'processing-order'
  | 'in-attendance'
  | 'in-separation'
  | 'separated'
  | 'delivery'
  | 'closed';

interface IOrderSteps {
  name: string;
  icon: JSX.Element;
  extraClass?: string;
  stepType: IStatusStep;
}

function OrderSteps({ status, shipmentType }: Props) {
  const statusesSteps: IOrderSteps[] = [
    { name: 'Em Separação', stepType: 'in-separation', icon: <FaBoxOpen size="20" /> },
    { name: 'Separado', stepType: 'separated', icon: <FaBox size="20" /> },
    {
      name: 'Em Delivery',
      stepType: 'delivery',
      icon: <FaMotorcycle size="20" />,
      extraClass: shipmentType !== 'delivery' ? 'd-none' : '',
    },
    {
      name: shipmentType === 'delivery' ? 'Entregue' : 'Finalizado',
      stepType: 'closed',
      icon: <BsCartCheckFill size="20" />,
    },
  ];

  if ([OrderStatus.WAITING_SELLER, OrderStatus.WAITING_CLIENT].includes(status)) {
    statusesSteps.unshift({
      name: 'Aguardando atendimento',
      stepType: 'processing-order',
      icon: <FaParachuteBox size="20" />,
    });
  } else if (OrderStatus.IN_ATTENDANCE === status) {
    statusesSteps.unshift({
      name: 'Em atendimento',
      stepType: 'in-attendance',
      icon: <FaUsers size="20" />,
    });
  }

  return statusesSteps;
}

export default OrderSteps;
