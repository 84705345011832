import api from '../services/api';

export interface ITimeBlocks {
  success: boolean;
  day: string;
  times: ITime[];
}

export interface ITime {
  time: string;
  available: boolean;
}

export const getTimeBlocks = async (shopId: number, date: string) => {
  return api.get<{ date: string }, ITimeBlocks[]>(`/deliverySchedule/${shopId}/times`, {
    params: { date },
  });
};
