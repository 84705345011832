import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { SocialNetworkType } from '../../../../store/ducks/order/types';

interface Props {
  show: boolean;
  socialNetworkType: SocialNetworkType;
  handleClose: () => void;
}

function SendOrderAlert({ show, socialNetworkType, handleClose }: Props) {
  const [showModal, setShowModal] = useState<boolean>(show);
  const getSocialTranslated = () => {
    switch (socialNetworkType) {
      case 'whatsapp':
        return 'a seu WhatsApp';
      case 'instagram-api':
        return 'ao seu Instagram';
      case 'facebook-api':
        return 'ao seu Facebook';
      default:
        return '';
    }
  };

  useEffect(() => setShowModal(show), [show]);

  return (
    <Modal
      id="finish-order-modal"
      show={showModal}
      onHide={handleClose}
      centered={true}
      animation={false}
    >
      <Modal.Body className="text-center p-2">
        <h6 className="mb-0" style={{ fontSize: '13pt' }}>
          Seu pedido foi enviado para um vendedor 😄
        </h6>
        <h6 style={{ fontSize: '13pt' }}>
          Fique atento {getSocialTranslated()} pois logo falaremos com você.
        </h6>

        <Button variant="success" size="sm" onClick={handleClose}>
          Ok! Entendi
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default SendOrderAlert;
