import { Reducer } from 'redux';

import { IWorldsState, WorldsTypes } from './types';

const DEFAULT_STATE: IWorldsState = {
  data: [],
  error: false,
  loading: false,
};

const reducer: Reducer<IWorldsState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case WorldsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case WorldsTypes.LOAD_SUCCESS:
      const { world } = action.payload;
      const alreadyInArray = state.data.find((w) => w.id === world.id);

      return {
        ...state,
        loading: false,
        error: false,
        data: alreadyInArray ? state.data : [...state.data, world],
      };
    case WorldsTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    default:
      return state;
  }
};

export default reducer;
