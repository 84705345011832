import { RegisterValues } from '@/resources/components/cart/shipment/client/identificationTypes/email/Register';
import api from '@/services/api';
import { IClientProfile } from '@/store/ducks/client/types';

interface ISearchClient {
  client_id: number;
  social_network_id: number;
}

export const search = async (identification: string) => {
  return api.get<{ identification: string }, ISearchClient>(`/clients/search`, {
    params: { identification },
  });
};

export const create = async (name: string, identification: string) => {
  return api.post<
    { name: string; social_networks: { social_id: string; social_network: 'whatsapp' }[] },
    ISearchClient
  >('/clients', {
    name,
    social_networks: [
      {
        social_id: identification,
        social_network: 'whatsapp',
      },
    ],
  });
};

export const identifyByEmail = async (email: string, password: string) => {
  return api.post<{ email: string; password: string }, ISearchClient>('/clients/login', {
    email,
    password,
  });
};

export const signUp = async (values: RegisterValues) => {
  return api.post<RegisterValues, ISearchClient & { error?: 'EMAIL_NOT_SENT' }>('/clients/signup', {
    ...values,
  });
};

export const addClientCPF = async (clientId: number, cpf: string) => {
  return api.put<{ cpf: string }, { message: string }>(`/clients/${clientId}/set-cpf`, {
    cpf,
  });
};

export const getClientInfo = async (clientId: number) => {
  return api
    .get<void, IClientProfile>(`/clients/${clientId}/profile`)
    .then((client) => {
      return client;
    })
    .catch((err) => {
      console.error(err);

      throw err;
    });
};

export const updateClientInfo = async (clientId: number, data: Partial<IClientProfile>) => {
  return api
    .put<Partial<IClientProfile>, IClientProfile>(`/clients/${clientId}/profile`, data)
    .catch((err) => {
      console.error(err);

      throw err;
    });
};
