import { useWindowSize } from './useWindowSize';

interface IUseDeviceReturn {
  device: 'mobile' | 'desktop';
}

export const useDevice = (): IUseDeviceReturn => {
  const { width } = useWindowSize();

  return width <= 480 ? { device: 'mobile' } : { device: 'desktop' };
};
