import { ICartProduct } from '../../../../store/ducks/order/types';
import { IProduct } from '../../../../store/ducks/products/types';
import { useWebContext } from '../../../hooks/useWebContext';

interface Props {
  product: IProduct | ICartProduct;
  isAvailable: boolean;
}

function QuantityAlert({ isAvailable, product }: Props) {
  const {
    configs: { min_quantity_alert, has_min_quantity_alert },
    shop: { use_stock, use_stock_kg },
  } = useWebContext();

  const convertKgToUnity = (quantity: number) =>
    parseInt((quantity / product.average_weight).toFixed(0));

  const productQuantity =
    product.measure === 'kg' && product.see_as_unit
      ? convertKgToUnity(product.quantity)
      : product.quantity;

  const verifyStock =
    (use_stock && product.measure === 'unity') ||
    (use_stock_kg && product.measure === 'kg' && product.see_as_unit);

  const showQuantityAlert =
    isAvailable &&
    verifyStock &&
    has_min_quantity_alert &&
    min_quantity_alert &&
    productQuantity >= 1 &&
    productQuantity <= min_quantity_alert;

  return (
    <>
      {!!showQuantityAlert && (
        <div>
          <small className="float-right badge badge-warning my-2">
            {productQuantity === 1 ? 'Resta ' : 'Restam '}
            apenas {productQuantity} {productQuantity === 1 ? 'unidade' : 'unidades'}!
          </small>
          <div className="clearfix"></div>
        </div>
      )}
    </>
  );
}

export default QuantityAlert;
