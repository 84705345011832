import { useShipmentCardContext } from '../../../../hooks/useShipmentCardContext';

interface Props {
  title: string;
  showModal?: (show: boolean) => void;
  onClick?: () => void;
  isDisabled?: boolean;
}

function FooterDefaultButton({ title, showModal = () => {}, onClick, isDisabled }: Props) {
  const { disabled } = useShipmentCardContext();

  return (
    <button
      disabled={disabled || isDisabled}
      onClick={() => (onClick ? onClick() : showModal(true))}
      className="btn btn-block btn-success mt-2 font-weight-bold"
    >
      {title}
    </button>
  );
}

export default FooterDefaultButton;
