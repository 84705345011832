import { maskMoney } from '@/services/global';

import { ICart } from '../../../../store/ducks/order/types';
import { useFormatMoney } from '../../../hooks/useFormatMoney';

interface Props {
  cart: ICart;
  subtotal?: boolean;
}

function Subtotal({ cart, subtotal = false }: Props) {
  const total = useFormatMoney(cart.total);

  return (
    <div
      className={
        subtotal ? 'text-right' : 'd-flex align-items-center justify-content-between w-100'
      }
    >
      <span>{subtotal ? 'Subtotal' : 'Total:'} </span>

      {subtotal && (
        <span>
          ({cart.products.length} {cart.products.length === 1 ? 'item' : 'itens'}):
        </span>
      )}

      {subtotal ? (
        <>
          <small> R$ </small>
          <strong style={{ fontSize: '18pt' }}>{maskMoney(cart.items_total, false)}</strong>
        </>
      ) : (
        <div>
          <small>R$</small>
          <strong style={{ fontSize: '22pt' }}> {total}</strong>
        </div>
      )}
    </div>
  );
}

export default Subtotal;
