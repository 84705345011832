import { ICart } from '@/store/ducks/order/types';

export const useViewCart = (cart: ICart) => {
  if (!window.gtag || cart.products.length) return;

  window.gtag('event', 'view_cart', {
    currency: 'BRL',
    value: cart.items_total,
    items: cart.products.map((product) => {
      return {
        item_id: product.product_id,
        item_name: product.title,
        quantity: product.quantity,
      };
    }),
  });
};
