import { Reducer } from 'redux';

import { BaseTypes, IBaseState } from './types';

const DEFAULT_STATE: IBaseState = {
  data: '',
};

const reducer: Reducer<IBaseState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case BaseTypes.SET_BASE64:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default reducer;
