export enum ClientTypes {
  SET_CPF_ANSWERED = '@client/SET_CPF_ANSWERED',
}

export interface IClientState {
  readonly cpfAnswered: boolean;
}

export interface IClientProfile {
  cpf?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  address?: {
    zipcode?: string | null;
    country?: string | null;
    street?: string | null;
    number?: string | null;
    neighborhood?: string | null;
    city?: string | null;
    state?: string | null;
    reference?: string | null;
    complement?: string | null;
  };
}
