import { Reducer } from 'redux';

import { ITokenState, TokenTypes } from './types';

const DEFAULT_STATE: ITokenState = {
  tokenValidated: false,
};

const reducer: Reducer<ITokenState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TokenTypes.SET_TOKEN_VALIDATED:
      return { ...state, tokenValidated: action.payload };
    default:
      return state;
  }
};

export default reducer;
