import { CSSProperties } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useNavbarContext } from '../../hooks/useNavbarContext';
import { useWebContext } from '../../hooks/useWebContext';
import CartCounter from './CartCounter';

interface Props {
  size: string;
  cartCounterStyle?: CSSProperties;
}

function CartLink({ cartCounterStyle, size }: Props) {
  const { textColor } = useNavbarContext();
  const { configs } = useWebContext();

  return (
    <Link
      to={'/cart'}
      className="position-relative"
      style={{ backgroundColor: configs.color, paddingLeft: '15px' }}
      aria-label="Ir para o Carrinho"
      role="link"
    >
      <FaShoppingCart data-testid="cart-icon" color={textColor} size={size} />
      <CartCounter style={cartCounterStyle} />
    </Link>
  );
}

export default CartLink;
