export enum PrivacyPolicyTypes {
  SET_CONSENT_GIVEN = '@privacyPolicy/SET_CONSENT_GIVEN',
  LOAD_REQUEST = '@privacyPolicy/LOAD_REQUEST',
  LOAD_SUCCESS = '@privacyPolicy/LOAD_SUCCESS',
  LOAD_FAILURE = '@privacyPolicy/LOAD_FAILURE',
}

export interface IPrivacyPolicy {
  privacy_policy: string | null;
}

export interface IPrivacyPolicyState {
  readonly consentGiven: boolean;
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: IPrivacyPolicy;
}
