import './paidAlertModal.scss';

import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '@/store';
import * as PaymentActions from '@/store/ducks/payment/actions';
import { IPaymentAlertAsks } from '@/store/ducks/payment/types';

import CheckAnimation from '../../checkAnimation/CheckAnimation';
import CustomModal from '../../global/CustomModal';

function PaidAlertModal() {
  const dispatch = useDispatch();
  const [show, setShow] = useState<boolean>(false);

  const paidAlertAsks = useSelector<ApplicationState, IPaymentAlertAsks>(
    (state) => state.payment.asks,
  );

  useEffect(() => {
    if (!paidAlertAsks.has_shown_paid_alert && paidAlertAsks.show_paid_alert) {
      setShow(true);

      dispatch(PaymentActions.setHasShownPaidAlert(true));
      dispatch(PaymentActions.setShowPaidAlert(false));
    }
  }, [paidAlertAsks.show_paid_alert]);

  return (
    <CustomModal
      id="paid-alert-modal"
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered={true}
      size="md"
      className="shipping-discount-modal modal-rounded"
    >
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center mb-4">
        <CheckAnimation />

        <div className="d-flex flex-column justify-content-center align-items-center mt-4">
          <span className="h3 font-weight-bold">Pagamento confirmado!</span>

          <span className="paid-alert-description">
            Estamos preparando seu pedido!
            <br /> Para <b>acompanhar seu pedido</b>, confira a as etapas de entrega na{' '}
            <b>loja virtual</b>!
          </span>
        </div>
      </Modal.Body>
    </CustomModal>
  );
}

export default PaidAlertModal;
