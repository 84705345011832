import './address.scss';
import 'react-calendar/dist/Calendar.css';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import AskCpfModal from '@/resources/components/askCpfModal/AskCpfModal';
import { useShipmentContext } from '@/resources/components/cart/shipment/Shipment';
import { useWebContext } from '@/resources/hooks/useWebContext';
import { setShipmentAddress } from '@/routes/order';
import { ApplicationState } from '@/store';

import { IOrder } from '../../../../../store/ducks/order/types';
import AddressModal, { IAddressModalStep } from '../../../global/address/AddressModal';
import FooterDefaultButton from '../footerDefaultButton/FooterDefaultButton';
import { ShipmentCard } from '../shipmentCard/ShipmentCard';
import DeliveryDiscountAlert from './components/delivery/DeliveryDiscountAlert';
import DeliveryInformation from './components/delivery/DeliveryInformation';
import RenderOrderAddress from './components/RenderOrderAddress';
import RenderOrderType from './components/RenderOrderType';

interface Props {
  order: IOrder;
}

function Address({ order }: Props) {
  const { shipment, shop_name_dst, id } = order;
  const { step, setStep } = useShipmentContext();
  const { configs } = useWebContext();

  const [show, setShowAddress] = useState<boolean>(false);
  const [modalStep, setModalStep] = useState<IAddressModalStep>('type');
  const [showCpf, setShowCpf] = useState<boolean>(false);

  const [calcButtonDisabled, setCalcButtonDisabled] = useState<boolean>(false);

  const selectCPFAnswered = useSelector<ApplicationState, boolean>(
    (state) => state.client.cpfAnswered,
  );

  const handleChooseSchedule = () => {
    setModalStep('scheduling');
    setShowAddress(true);
  };

  const setShow = (show: boolean) => {
    if (!show) {
      setShowCpf(false);
      setShowAddress(show);

      return;
    }

    if (configs.ask_cpf && !selectCPFAnswered && !order?.client.cpf) {
      setShowCpf(true);
      return;
    }

    setShowAddress(show);
  };

  const handleRefreshShipment = () => {
    setCalcButtonDisabled(true);

    setShipmentAddress(id, shipment.address.id, { type: 'delivery' })
      .catch((err) => {
        console.error(err);
        toast('Erro ao calcular frete, tente novamente mais tarde', { type: 'error' });
      })
      .finally(() => setCalcButtonDisabled(false));
  };

  const showPickupAt = shipment.type === 'shop' || shipment.delivery?.type === 'google-maps';

  useEffect(() => {
    if (step === 2 && shipment.pickup_at !== null) setStep(3);
  }, [shipment.pickup_at, step, setStep]);

  return (
    <>
      <ShipmentCard
        showModal={show}
        disabled={step <= 1}
        setShowModal={setShow}
        warningType="forma de entrega"
        handleCloseModal={() => setModalStep('type')}
      >
        <ShipmentCard.Content
          title="2 - Entrega"
          actions={!!shipment.address}
          footer={
            <>
              {!shipment.address && (
                <FooterDefaultButton title="Definir entrega" showModal={setShow} />
              )}

              {shipment.type === 'delivery' && shipment.address && <DeliveryInformation />}

              {!shipment.pickup_at && showPickupAt && (
                <FooterDefaultButton
                  title="Definir horário"
                  showModal={setShow}
                  onClick={() => handleChooseSchedule()}
                />
              )}

              {shipment.refresh_carrier && (
                <FooterDefaultButton
                  title="Calcular entrega"
                  onClick={handleRefreshShipment}
                  isDisabled={calcButtonDisabled}
                />
              )}
            </>
          }
        >
          <div className="d-flex flex-column">
            {shipment.type && <RenderOrderType type={shipment.type} />}

            {shipment.address && (
              <RenderOrderAddress
                type={shipment.type}
                address={shipment.address}
                shopNameDst={shop_name_dst}
                pickupAt={shipment.pickup_at}
              />
            )}
          </div>
        </ShipmentCard.Content>

        <AddressModal
          showModal={show}
          step={modalStep}
          showSchedule={true}
          setShowModal={setShow}
          handleClose={() => setModalStep('type')}
        />

        <AskCpfModal show={showCpf} setShow={setShowCpf} setAddress={setShowAddress} />
      </ShipmentCard>

      <DeliveryDiscountAlert />
    </>
  );
}

export default Address;
