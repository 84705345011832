import './breadcrumbs.scss';

import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { slug } from '../../../../services/global';
import { useTextColor } from '../../../hooks/useTextColor';
import { useWebContext } from '../../../hooks/useWebContext';

interface Props {
  page?: string;
  product?: string;
  categoryName?: string;
  categoryId?: number | null;
  name?: string;
}

function Breadcrumb({ page, name, categoryName, product, categoryId }: Props) {
  const {
    configs: { bg_color },
  } = useWebContext();
  const color = useTextColor(bg_color);

  const customStyle = { color };

  const renderBreadCrumbItem = (page?: string) => {
    switch (page) {
      case 'cart':
        return (
          <span className="breadcrumb-item" style={customStyle}>
            Carrinho
          </span>
        );
      case 'booklets':
        return (
          <span className="breadcrumb-item" style={customStyle}>
            Encartes
          </span>
        );
      case 'category':
        return (
          <span className="breadcrumb-item" style={customStyle}>
            {categoryName}
          </span>
        );
      case 'product':
        const categoryTitle = slug(categoryName ?? '');

        return (
          <>
            {categoryId && categoryName && categoryName.length > 0 && (
              <Link
                style={customStyle}
                to={`/category/${btoa(`${categoryId}`)}/${encodeURIComponent(categoryTitle)}`}
              >
                <span className="breadcrumb-item category">{categoryName}</span>
              </Link>
            )}

            <span className="breadcrumb-item" style={customStyle}>
              {product}
            </span>
          </>
        );

      default:
        return (
          <span className="breadcrumb-item" style={customStyle}>
            {name ?? ''}
          </span>
        );
    }
  };

  return (
    <Navbar className="breadcrumb pl-2">
      <Nav className="me-auto">
        <Link style={customStyle} to={'/w'}>
          Pagina Inicial
        </Link>

        {renderBreadCrumbItem(page)}
      </Nav>
    </Navbar>
  );
}

export default Breadcrumb;
