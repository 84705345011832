import { generateRandomKey } from '../../../services/global';
import { useRandomNumber } from '../../hooks/useRandomNumber';
import CartProductLoad from './CartProductLoad';

function SearchLoad() {
  const quantity = useRandomNumber(10);
  const emptyArray = new Array(quantity).fill('');

  return (
    <>
      {emptyArray.map(() => (
        <CartProductLoad key={`product-load-${generateRandomKey()}`} />
      ))}
    </>
  );
}

export default SearchLoad;
