import './defaultStore.scss';

import { memo, useCallback, useEffect, useState } from 'react';
import { RiMapPinLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import { useDevice } from '@/resources/hooks/useDevice';
import { useTextColor } from '@/resources/hooks/useTextColor';
import { ClientTypes } from '@/services/clients';
import { IFooterAddress } from '@/store/ducks/shopConfig/types';
import { IShopsAddress } from '@/store/ducks/shops/types';

import {
  canUseWebappInStatuses,
  formatAddressNumber,
  getFullAddress,
} from '../../../../services/global';
import { ApplicationState } from '../../../../store';
import {
  IAddress,
  IShipment,
  IShipmentType,
  IShopAddress,
} from '../../../../store/ducks/order/types';
import { useWebContext } from '../../../hooks/useWebContext';
import AddressModal from '../../global/address/AddressModal';
import AskZipcode from '../../global/address/zipcode/AskZipcode';

function DefaultStore() {
  const { device } = useDevice();
  const { order, configs, shop } = useWebContext();
  const textColor = useTextColor(
    device === 'desktop' ? configs.color : configs.navbar_footer_color,
  );

  const [showModal, setShowModal] = useState(false);
  const [showZipcode, setShowZipcode] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  const [showFullAddress, setShowFullAddress] = useState(false);
  const [active, setActive] = useState(false);
  const [shipmentAddress, setShipmentAddress] = useState('');

  const canShowModal =
    order &&
    !order.is_paid &&
    !order.integration_code &&
    canUseWebappInStatuses({ status: order.status });

  const selectShops = useSelector<ApplicationState, IShopsAddress[]>((state) => state.shops.data);
  const selectIsZipcodeAnswered = useSelector<ApplicationState, boolean>(
    (state) => state.order.zipcodeAnswered,
  );

  const getSelectedShop = (shopIdDst: number) => selectShops.find((shop) => shop.id === shopIdDst);

  const getAddressBySelected = (address: IAddress | IFooterAddress) => {
    const addressFormated = {
      number: address.number,
      street: address.street,
      neighborhood: address.neighborhood,
    };

    if (!addressFormated.street) return '';

    return getFullAddress(addressFormated);
  };

  const formatClientAddress = (address: IAddress) => {
    if (!address) return '';

    return `${address.street}, ${formatAddressNumber(address.number)} - ${address.neighborhood}`;
  };

  const renderAddressInfos = (shipmentInfo?: IShipment) => {
    switch (shipmentInfo?.type) {
      case 'shop':
        const selectedShop = getSelectedShop(shipmentInfo.address.id);

        if (!selectedShop) return null;

        return getAddressBySelected(
          selectedShop ? (selectedShop.address as IAddress) : (shop.address as IFooterAddress),
        );
      case 'delivery':
        if (!shipmentInfo.address) return '';

        return formatClientAddress(shipmentInfo.address as IAddress);
      default:
        if (!shop.address) return '';

        return getAddressBySelected(shop.address);
    }
  };

  const getNameByType = (type?: IShipmentType | null) => {
    switch (type) {
      case 'shop':
        if (!order) return '';

        return order.shipment?.address
          ? ` - ${(order.shipment?.address as IShopAddress).name}`
          : '';
      case 'delivery':
        if (!order) return '';

        const shopName = order?.shop_name_dst ? order?.shop_name_dst : shop.name;

        return order.client?.type !== ClientTypes.ANONYMOUS
          ? `- ${order.client.name}`
          : `- ${shopName}`;
      default:
        return configs ? `${shop.name ? `- ${shop.name}` : ''}` : '';
    }
  };

  const handleMouseAddress = useCallback(
    (show) => {
      setShowZipcode(show);
      setShowFullAddress(show);
    },
    [setShowZipcode],
  );

  useEffect(() => {
    setActive(showFullAddress && selectIsZipcodeAnswered);
  }, [showFullAddress, selectIsZipcodeAnswered]);

  useEffect(() => {
    if (order) {
      const renderAddress = renderAddressInfos(order.shipment);

      if (renderAddress) setShipmentAddress(renderAddress);
    }
  }, [order?.dst_shop_id, order?.shipment?.address, shop.address]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowZipcode(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div
        className="position-relative w-100 mt-1"
        onMouseEnter={() => {
          if (!selectIsZipcodeAnswered) handleMouseAddress(true);
        }}
        onMouseLeave={() => handleMouseAddress(false)}
      >
        <div
          className="p-0 m-0 d-flex justify-content-start"
          onClick={() => {
            if (canShowModal) setShowModal(true);
          }}
          style={{ cursor: canShowModal ? 'pointer' : '' }}
        >
          <div className="d-flex py-2 pr-2 pl-md-2 align-items-center">
            <RiMapPinLine color={textColor} size={24} />
          </div>
          <div className="d-flex flex-column justify-content-center w-100">
            <span className="m-0 font-weight-bold" style={{ color: textColor, fontSize: '0.8rem' }}>
              {order?.shipment?.type === 'delivery' ? 'Delivery' : 'Retirar na loja'}{' '}
              {getNameByType(order?.shipment?.type)}
            </span>

            <div className="align-items-baseline">
              <p
                className={`small m-0 address ${active ? 'active' : ''}`}
                style={{ color: textColor }}
              >
                {shipmentAddress || '---'}
              </p>
            </div>
          </div>
        </div>

        {order && <AskZipcode show={showZipcode} />}
      </div>

      {order && (
        <AddressModal
          showModal={showModal}
          handleClose={handleCloseModal}
          showSchedule={false}
          step={'type'}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
}

export default memo(DefaultStore);
