import { useCategoryContext } from '@/resources/hooks/useCategoryContext';

import BrandItem from './BrandItem';

function BrandsSuggestions() {
  const { brands, brandsFiltered } = useCategoryContext();

  const sortBrandsByFilters = () => {
    const allBrands = brands.slice();

    const brandsInters = allBrands.filter((brandInfo) => {
      return !brandsFiltered.includes(brandInfo.brand);
    });

    const brandsSorted = brandsInters.sort((a, b) => {
      if (a.count > b.count) return -1;
      if (a.brand < b.brand) return 1;
      return 0;
    });

    return brandsSorted.splice(0, 8 - brandsFiltered.length);
  };

  return (
    <>
      {sortBrandsByFilters()?.map((brandInfo) => {
        return <BrandItem brand={brandInfo.brand} key={brandInfo.brand} />;
      })}
    </>
  );
}

export default BrandsSuggestions;
