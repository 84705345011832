/**
 * Action types
 */
export enum ShopConfigTypes {
  LOAD_REQUEST = '@shopConfig/LOAD_REQUEST',
  LOAD_SUCCESS = '@shopConfig/LOAD_SUCCESS',
  LOAD_FAILURE = '@shopConfig/LOAD_FAILURE',
}

/**
 * Data Types
 */
export interface IShopConfig {
  id: number;
  name: string;
  cnpj: string;
  is_open: boolean;
  schedule: string;
  whatsapp: string;
  use_stock: boolean;
  use_stock_kg: boolean;
  address: IFooterAddress;
  is_active: boolean;
  show_full_range: boolean;
  interval: number;
  trade_name: string | null;
  company_name: string | null;
  pharmacist_responsible: string | null;
  pharmacist_crf: string | null;
  anvisa_registration: string | null;
  afe_authorization: string | null;
}

export interface IFooterAddress {
  id: number | null;
  city: string;
  state: string;
  number: string;
  street: string;
  zipcode: string;
  neighborhood: string;
  country: string | null;
  client_id: number | null;
  reference: string | null;
  complement: string | null;
}

export interface IShopConfigState {
  readonly error: boolean;
  readonly loading: boolean;
  readonly data: IShopConfig;
}
