import { ICategoryGroupData } from '../../../store/ducks/categories/types';
import { IImageGroup } from '../../../store/ducks/settings/types';
import withoutImage from '../../assets/images/without-image.png';

interface Props {
  categoryGroup: ICategoryGroupData;
  imageGroup: IImageGroup | undefined;
}

function CategoryGroupImage({ categoryGroup, imageGroup }: Props) {
  return (
    <div className="position-relative">
      {categoryGroup?.image ||
      (imageGroup && imageGroup.images?.length > 0 && imageGroup.images[0]?.link) ? (
        <img
          className="img-fluid category-image mx-auto"
          src={categoryGroup?.image ?? imageGroup?.images[0]?.link}
          alt={categoryGroup?.image ?? ''}
        />
      ) : (
        <img
          className="img-fluid category-image mx-auto"
          style={{ maxHeight: '400px' }}
          src={withoutImage}
          alt=""
        />
      )}
    </div>
  );
}

export default CategoryGroupImage;
