import './statusSteps.scss';

import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoChatboxEllipsesOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { OrderStatus } from '../../../../services/global';
import { ApplicationState } from '../../../../store';
import { IOrder } from '../../../../store/ducks/order/types';
import { useTextColor } from '../../../hooks/useTextColor';
import { useWebContext } from '../../../hooks/useWebContext';
import OrderSteps, { IStatusStep } from './OrderSteps';

interface Props {
  order: IOrder;
}

function StatusSteps({ order }: Props) {
  const { shop } = useWebContext();
  const orderSteps = OrderSteps({ status: order.status, shipmentType: order.shipment.type });

  const [helpText, setHelpText] = useState<string>('');

  const checkStatus = useCallback((): IStatusStep => {
    if (order.status === OrderStatus.IN_ATTENDANCE) return 'in-attendance';
    if ([OrderStatus.WAITING_SELLER, OrderStatus.WAITING_CLIENT].includes(order.status))
      return 'processing-order';
    if ([OrderStatus.ROBOT_POST_SALE, OrderStatus.SELLER_POST_SALE].includes(order.status))
      return 'in-separation';
    if (order.status === OrderStatus.SEPARATED) return 'separated';
    if (order.status === OrderStatus.DELIVERY) return 'delivery';
    return 'closed';
  }, [order.status]);

  const [step, setStep] = useState<IStatusStep>(checkStatus());

  const selectColor = useSelector<ApplicationState, string>(
    (state) => state.settings.configs.color,
  );
  const textColor = useTextColor(selectColor);

  const customStyle = { backgroundColor: selectColor, borderColor: selectColor, color: textColor };

  useEffect(() => setStep(checkStatus()), [checkStatus]);

  useEffect(() => {
    switch (step) {
      case 'processing-order':
        setHelpText('Seu pedido foi enviado para a loja.');
        break;
      case 'in-attendance':
        setHelpText(`Você está sendo atendido por ${order.seller?.name}`);
        break;
      case 'in-separation':
        setHelpText('Seu pedido está sendo preparado pela nossa equipe.');
        break;
      case 'separated':
        setHelpText(
          `Seu pedido foi separado ${
            order.shipment?.type === 'delivery'
              ? 'e logo sairá para entrega'
              : ', aguardando retirada'
          }.`,
        );
        break;
      case 'delivery':
        setHelpText('Seu pedido está a caminho.');
        break;
      case 'closed':
        setHelpText(
          `Seu pedido foi ${order.shipment?.type === 'delivery' ? 'entregue' : 'finalizado'}.`,
        );
        break;
      default:
        setHelpText('Seu pedido está sendo preparado pela nossa equipe.');
        break;
    }
    // eslint-disable-next-line
  }, [step]);

  return (
    <Card className="shipment-card">
      <Card.Header style={customStyle}>Status do Pedido</Card.Header>
      <Card.Body className="px-2 py-4 d-block">
        <Container fluid>
          <Row>
            <Col>
              <h6 className="mb-2" style={{ color: '#17a2b8' }}>
                {helpText}
              </h6>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col>
              <ul className="list-unstyled statuses-progress">
                {orderSteps.map(({ name, stepType, icon, extraClass }, index) => (
                  <li
                    key={index}
                    className={`status ${step === stepType ? 'active' : ''} ${extraClass ?? ''}`}
                  >
                    <div className="tag">{icon}</div>

                    <span className="name">{name}</span>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          <Row className="justify-content-center mt-4">
            <Col xs={6} md={3}>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ borderRadius: '6px', fontSize: '12px' }}
                className="btn btn-danger py-1 d-flex align-items-center justify-content-center"
                href={`https://api.whatsapp.com/send?phone=${shop.whatsapp}&text=Ol%C3%A1%2C%20gostaria%20de%20cancelar%20o%20meu%20pedido%20${order.id}%20da%20loja%20virtual!`}
              >
                <AiOutlineCloseCircle size="13" className="mr-1" />
                Cancelar
              </a>
            </Col>

            <Col className="d-none d-md-block" md={6}></Col>

            <Col xs={6} md={3}>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ borderRadius: '6px', fontSize: '12px' }}
                className="btn btn-outline-success py-1 d-flex align-items-center justify-content-center"
                href={`https://api.whatsapp.com/send?phone=${shop.whatsapp}&text=Ol%C3%A1%2C%20gostaria%20de%20falar%20o%20sobre%20o%20pedido%20${order.id}%20da%20loja%20virtual!`}
              >
                <IoChatboxEllipsesOutline size="15" className="mr-1" />
                Ajuda
              </a>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default StatusSteps;
