import { IFilter, IFilterId } from '@/store/ducks/searches/types';

export const addOrRemoveFilterById = (old: number[], item: IFilterId) => {
  if (!old.includes(item.id)) return [...old, item.id];
  return [...old.filter((id: number) => id !== item.id)];
};

export const addOrRemoveFilterByName = (old: string[], item: IFilter) => {
  if (!old.includes(item.name)) return [...old, item.name];
  return [...old.filter((b) => b !== item.name)];
};
