import { IImageGroup } from '../../store/ducks/settings/types';

interface Props {
  locate: string;
  imageGroups: IImageGroup[] | undefined;
}

export const useImageGroupsByName = ({ imageGroups, locate }: Props): IImageGroup[] => {
  return imageGroups ? imageGroups.filter((group) => group.name.includes(locate)) : [];
};
