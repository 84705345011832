import api from '@/services/api';
import { IShopsAddress } from '@/store/ducks/shops/types';

import { store } from '../store';
import * as ShopsActions from '../store/ducks/shops/actions';

const { dispatch } = store;

export const getShopsByCompany = async () => {
  dispatch(ShopsActions.loadRequest());

  return api
    .get<void, IShopsAddress[]>('/shops/addresses')
    .then((shops) => dispatch(ShopsActions.loadSuccess(shops)))
    .catch((err) => {
      console.error(err);
      dispatch(ShopsActions.loadFailure());
    });
};
