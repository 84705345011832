import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { maskMoney } from '@/services/global';

import { ApplicationState } from '../../../../store';
import { IOrder } from '../../../../store/ducks/order/types';
import { useFormatMoney } from '../../../hooks/useFormatMoney';

interface Props {
  order: IOrder;
}

function OrderResume({ order }: Props) {
  const selectOrderIsLoading = useSelector<ApplicationState, boolean>(
    (state) => state.order.loading,
  );

  const { discount } = order.cart;
  const subtotal = order.cart.items_total;

  const deliveryRate = useFormatMoney(order?.cart.delivery_tax ?? 0);

  return (
    <>
      {!selectOrderIsLoading && (
        <Row>
          {subtotal > 0 && (
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center">
                <span>
                  Subtotal ({order.cart.products.length}{' '}
                  {order.cart.products.length === 1 ? 'item' : 'itens'}):
                </span>

                <span>
                  <small>R$</small> {maskMoney(subtotal, false)}
                </span>
              </div>
            </Col>
          )}

          {discount > 0 && (
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center text-success">
                <span>Descontos:</span>

                <span>
                  - <small>R$</small> {discount}
                </span>
              </div>
            </Col>
          )}

          {!!(order?.cart.delivery_tax && order?.cart.delivery_tax !== 0) && (
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center delivery-rate">
                <span>Taxa de Entrega:</span>

                <span>
                  + <small>R$</small> {deliveryRate}
                </span>
              </div>
            </Col>
          )}
        </Row>
      )}
    </>
  );
}

export default OrderResume;
