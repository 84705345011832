import { Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { getDeliveryStoreIntegrations, getDeliveryTypeIntegrations } from '@/services/integrations';
import { ApplicationState } from '@/store';
import { IIntegration } from '@/store/ducks/integrations/types';

import CircleLoad from '../../CircleLoad';
import DeliveryMethod from './DeliveryMethod';

interface Props {
  integrations: IIntegration[];
  setShipmentType: (integrationType: 'delivery' | 'shop') => void;
}

function ListDeliveryMethods({ integrations, setShipmentType }: Props) {
  const deliveryIntegrations = getDeliveryTypeIntegrations(integrations);
  const storesIntegrations = getDeliveryStoreIntegrations(integrations);

  const selectIntegrationIsLoading = useSelector<ApplicationState, boolean>(
    (state) => state.integrations.loading,
  );

  const withoutIntegrations =
    !deliveryIntegrations.length && !storesIntegrations.length && !selectIntegrationIsLoading;

  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center">
          <div className="w-100">
            {selectIntegrationIsLoading && <CircleLoad classes="load-gray" />}

            {!!deliveryIntegrations.length && !selectIntegrationIsLoading && (
              <DeliveryMethod type="delivery" setShipmentType={setShipmentType} />
            )}

            {!!storesIntegrations.length && !selectIntegrationIsLoading && (
              <DeliveryMethod type="shop" setShipmentType={setShipmentType} />
            )}

            {withoutIntegrations && (
              <h6 className="mb-0 text-center">Nenhuma forma de entrega disponível</h6>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ListDeliveryMethods;
