import { action } from 'typesafe-actions';

import { IOrder, OrderTypes } from './types';

export const loadRequest = () => action(OrderTypes.LOAD_REQUEST);
export const loadFailure = () => action(OrderTypes.LOAD_FAILURE);
export const loadSuccess = (order: IOrder | null) => action(OrderTypes.LOAD_SUCCESS, { order });
export const setValidated = (validated: boolean) => action(OrderTypes.SET_VALIDATED, validated);
export const zipcodeAnswered = (zipcodeAnswered: boolean) =>
  action(OrderTypes.SET_ZIPCODE, zipcodeAnswered);
