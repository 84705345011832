import api from '../services/api';
import { store } from '../store';
import * as BookletsActions from '../store/ducks/booklets/actions';
import { IBooklet } from '../store/ducks/booklets/types';

const { dispatch } = store;

export const getAllBooklets = async (shopIdDst: number) => {
  dispatch(BookletsActions.loadRequest());

  return api
    .get<void, IBooklet[]>(`/booklets/${shopIdDst}`)
    .then((booklets) => dispatch(BookletsActions.loadSuccess(booklets)))
    .catch(() => dispatch(BookletsActions.loadFailure()));
};
