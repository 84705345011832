import { Col, Container, Row } from 'react-bootstrap';

import PrivacyPolicyPopup from '@/resources/components/privacyPolicyPopup/PrivacyPolicyPopup';
import { usePageBreakpoints } from '@/resources/hooks/usePageBreakpoints';

import { useNavbarContext } from '../../../../hooks/useNavbarContext';
import { useWebContext } from '../../../../hooks/useWebContext';
import ActiveCategoriesList from '../../../navbar/ActiveCategoriesList';
import CartLink from '../../../navbar/CartLink';
import Logo from '../../../navbar/logo/Logo';
import SearchInput from '../../../navbar/SearchInput';
import SearchLink from '../../../navbar/SearchLink';
import DefaultStore from '../../../navbar/stores/DefaultStore';

function Mobile() {
  const { order, configs } = useWebContext();
  const { pathname } = useNavbarContext();
  const { breakpoints } = usePageBreakpoints();

  return (
    <div className="d-flex flex-column d-md-none align-self-stretch w-100">
      <div style={{ backgroundColor: configs.color }}>
        <Container className="d-flex justify-content-center p-0" fluid>
          <Row className="justify-content-center w-100 py-2">
            <Col {...breakpoints} className="px-lg-0 d-flex flex-column">
              <div className="d-flex align-items-center justify-content-between">
                <ActiveCategoriesList isMobile iconSize="40" />

                <Logo style={{ maxWidth: '150px' }} />
                <CartLink size="30" />
              </div>

              <div className="d-flex align-items-center position-relative w-100 mt-2">
                {pathname.includes('/search') ? <SearchInput /> : <SearchLink />}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="d-flex align-items-center p-1"
        style={{ backgroundColor: configs.navbar_footer_color }}
      >
        <DefaultStore />
      </div>

      {order && <PrivacyPolicyPopup />}
    </div>
  );
}

export default Mobile;
