import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function SearchLink() {
  return (
    <Link to={'/search/'} className="position-relative w-100">
      <input
        style={{ height: '40px' }}
        className="form-control form-control-sm search-input"
        placeholder="Pesquisar: Nome, marca"
      />
      <FaSearch
        size="20"
        color="#333"
        className="position-absolute"
        style={{ right: '7px', bottom: 0, top: 0, margin: 'auto 0' }}
      />
    </Link>
  );
}

export default SearchLink;
