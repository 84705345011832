import { Modal } from 'react-bootstrap';

import DeliveryDiscountItem from '@/resources/components/cart/shipment/address/components/delivery/shippingDiscountModal/DeliveryDiscountItem';
import CustomModal from '@/resources/components/global/CustomModal';
import { IDeliveryDiscountData } from '@/store/ducks/deliveryDiscount/types';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  deliveryDiscountData: IDeliveryDiscountData;
}

function ProductShippingRulesModal({ show, setShow, deliveryDiscountData }: Props) {
  return (
    <CustomModal
      id="rules-modal"
      show={show}
      onHide={() => setShow(false)}
      centered={true}
      size="lg"
      className="modal-rounded"
    >
      <Modal.Header closeButton className=" h5">
        Regulamento &quot;COMPRE E GANHE FRETE GRÁTIS&quot;
      </Modal.Header>

      <Modal.Body>
        <DeliveryDiscountItem discount={deliveryDiscountData} />
      </Modal.Body>
    </CustomModal>
  );
}

export default ProductShippingRulesModal;
