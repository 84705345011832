import { PageContext } from '../../components/contexts/PageContext';
import { useTemplateByPage } from '../../hooks/useTemplateByPage';
import Default from '../../themes/cart/default/Default';

function Cart() {
  const template = useTemplateByPage('cart');

  const renderCartTemplate = () => {
    switch (template?.template_name) {
      default:
        return <Default />;
    }
  };

  return <PageContext.Provider value={{ template }}>{renderCartTemplate()}</PageContext.Provider>;
}

export default Cart;
