import './specs.scss';

import { Col, Row } from 'react-bootstrap';

import { ISpecGroup } from '@/resources/themes/product/default/Default';

import withoutImage from '../../../assets/images/without-image.png';

interface Props {
  specs: { size: string | undefined; groups: ISpecGroup[] }[];
  chosenSpec: { size: string | undefined; group: ISpecGroup } | null;
  handleChangeSpec: ({
    size,
    group,
  }: {
    size: string | undefined;
    group: ISpecGroup | null;
  }) => void;
}

function Specs({ chosenSpec, specs, handleChangeSpec }: Props) {
  return (
    <>
      {chosenSpec && (
        <Row className="mt-2">
          {specs.every((spec) => spec.size) && (
            <Col xs={12}>
              <h6 className="font-weight-bold">Tamanho</h6>

              <ul className="size-list list-inline list-unstyled">
                {specs?.map(({ size }) => {
                  return (
                    <li
                      key={`size-group-${size}`}
                      className={`size-group list-inline-item spec-item ${
                        chosenSpec.size === size ? 'active' : ''
                      }`}
                      onClick={() => handleChangeSpec({ size, group: null })}
                    >
                      <button className="btn">{size}</button>
                    </li>
                  );
                })}
              </ul>
            </Col>
          )}

          <Col xs={12}>
            <h6 className="font-weight-bold">Cor</h6>

            <ul className="color-list list-inline list-unstyled">
              {specs
                ?.filter((spec) => spec.size === chosenSpec?.size)
                ?.map(({ size, groups }) => {
                  return groups
                    ?.sort((a, b) => (a.color > b.color ? 1 : -1))
                    ?.map((group) => {
                      const disabled = size !== group.size ? 'disabled' : '';
                      const active =
                        chosenSpec.group.fullProductId === group.fullProductId ? 'active' : '';

                      return (
                        <li
                          key={`spec-group-${group.fullProductId}`}
                          className={`size-group list-inline-item spec-item py-1 ${active} ${disabled}`}
                          onClick={() => handleChangeSpec({ size, group })}
                        >
                          <button className="btn">
                            <div className="color-wrapper">
                              <img src={group.images?.[0] ?? withoutImage} alt="" />
                              <span>{group.color}</span>
                            </div>
                          </button>
                        </li>
                      );
                    });
                })}
            </ul>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Specs;
