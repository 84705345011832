import { useCategoryContext } from '@/resources/hooks/useCategoryContext';

import BrandItem from './BrandItem';

function AllBrandsCategory() {
  const { brands, brandsFiltered } = useCategoryContext();

  const sortBrandsByFilters = () => {
    const allBrands = brands.slice();

    const brandsInters = allBrands.filter((brandInfo) => {
      return !brandsFiltered.includes(brandInfo.brand);
    });

    return brandsInters;
  };

  return (
    <>
      {sortBrandsByFilters()?.map((brandInfo) => {
        return <BrandItem brand={brandInfo.brand} key={brandInfo.brand} />;
      })}
    </>
  );
}

export default AllBrandsCategory;
