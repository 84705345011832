import { IOrder } from '@/store/ducks/order/types';

export const useBeginCheckout = (order: IOrder) => {
  if (!window.gtag) return;

  window.gtag('event', 'begin_checkout', {
    currency: 'BRL',
    value: order.cart.items_total,
    items: order.cart.products.map((product) => {
      return {
        item_id: product.product_id,
        item_name: product.title,
        quantity: product.quantity,
      };
    }),
  });
};
