import { Reducer } from 'redux';

import { ClientTypes, IClientState } from './types';

const DEFAULT_STATE: IClientState = {
  cpfAnswered: false,
};

const reducer: Reducer<IClientState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ClientTypes.SET_CPF_ANSWERED:
      return { ...state, cpfAnswered: action.payload };
    default:
      return state;
  }
};

export default reducer;
