import './address.scss';

import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Calendar } from 'react-calendar';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ListSchedules from '@/resources/components/global/address/components/ListSchedules';
import { getAllDeliveryIntegrations } from '@/routes/integrations';
import { ApplicationState } from '@/store';
import { IIntegration } from '@/store/ducks/integrations/types';

import { getAddresses, IAddressResponse } from '../../../../routes/addresses';
import { ClientTypes, isFinalCustomer } from '../../../../services/clients';
import { useWebContext } from '../../../hooks/useWebContext';
import ListAddresses from './components/ListAddresses';
import ListDeliveryMethods from './components/ListDeliveryMethods';
import ListStores from './components/ListStores';
import SearchAddress from './components/SearchAddress';

interface Props {
  showModal: boolean;
  step: IAddressModalStep;
  showSchedule: boolean;
  handleClose: () => void;
  setShowModal: (show: boolean) => void;
}

export type IAddressModalStep =
  | 'type'
  | 'address'
  | 'addAddress'
  | 'stores'
  | 'scheduling'
  | 'calendar';

function AddressModal({ showModal, step, showSchedule, handleClose, setShowModal }: Props) {
  const { order } = useWebContext();

  if (!order) return <></>;

  const { id: orderId, client, shipment, dst_shop_id } = order || {};

  const { setShowIdenfificationModal } = useWebContext();
  const { finalCustomer } = isFinalCustomer(client.type);

  const [modalStep, setModalStep] = useState<IAddressModalStep>('type');

  const [show, setShow] = useState<boolean>(showModal);
  const [calendarDate, setCalendarDate] = useState<Date | null>(null);

  const [clientAddresses, setClientAddresses] = useState<IAddressResponse[]>([]);

  const selectIntegrations = useSelector<ApplicationState, IIntegration[]>(
    (state) => state.integrations.data,
  );

  const getStoreIntegrations = () => {
    return selectIntegrations.filter((integration) => integration.type === 'in-store');
  };

  const handleShowSchedule = (deliveryType?: string, validAddress: boolean = true) => {
    const isToShow = deliveryType === 'google-maps' || !deliveryType;

    if (showSchedule && isToShow && validAddress) setModalStep('scheduling');
    else {
      setModalStep('type');
      setShowModal(false);
    }
  };

  const setShipmentType = (type: 'delivery' | 'shop') => {
    if (type === 'delivery' && client.type === ClientTypes.ANONYMOUS) {
      toast('Indentifique-se para escolher essa opção!', { type: 'warning' });
      setShowModal(false);
      setShowIdenfificationModal(true);

      return;
    }

    if (type === 'delivery') setModalStep('address');
    if (type === 'shop') setModalStep('stores');
  };

  const renderModalTitleByStep = (step: IAddressModalStep) => {
    switch (step) {
      case 'type':
        return 'Escolha a forma de entrega';
      case 'stores':
        return 'Escolha a loja de retirada';
      case 'address':
        return 'Escolha seu endereço';
      case 'addAddress':
        return 'Adicionar um novo endereço';
      case 'scheduling':
        return 'Agende o horário de entrega do seu pedido';
      default:
        return '';
    }
  };

  const handleCalendarChange = (someDate: Date) => {
    const day = someDate.toISOString().split('T').shift();
    const timeNow = new Date().toTimeString().split(':');
    const date = new Date(`${day}T${timeNow[0]}:${timeNow[1]}`);

    setCalendarDate(date);
    setModalStep('scheduling');
  };

  const renderModalContent = (step: IAddressModalStep) => {
    switch (step) {
      case 'type':
        return (
          <ListDeliveryMethods
            integrations={selectIntegrations}
            setShipmentType={setShipmentType}
          />
        );
      case 'address':
        return (
          <ListAddresses addresses={clientAddresses} handleShowSchedule={handleShowSchedule} />
        );
      case 'addAddress':
        return (
          <SearchAddress
            orderId={orderId}
            clientId={client.id}
            setClientAddresses={setClientAddresses}
            setModalStep={setModalStep}
          />
        );
      case 'stores':
        return (
          <ListStores
            integrations={getStoreIntegrations()}
            handleShowSchedule={handleShowSchedule}
          ></ListStores>
        );
      case 'scheduling':
        return (
          <ListSchedules
            orderId={orderId}
            shopIdDst={dst_shop_id}
            shipment={shipment}
            calendarDate={calendarDate}
            setShowModal={setShowModal}
            setModalStep={setModalStep}
          />
        );
      case 'calendar':
        return (
          <div className="d-flex justify-content-center">
            <Calendar
              onChange={(value: Date) => handleCalendarChange(value)}
              value={calendarDate}
              minDate={new Date()}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (client.id && !finalCustomer && showModal)
      getAddresses(client.id).then((addresses) => setClientAddresses(addresses));
  }, [client.id, finalCustomer, showModal]);

  useEffect(() => setShow(showModal), [showModal, setShowModal]);

  useEffect(() => setModalStep(step), [step]);

  useEffect(() => {
    if (step === 'type' && showModal) {
      getAllDeliveryIntegrations(orderId);
    }
  }, [showModal]);

  const arrowActionByStep = {
    address: () => setModalStep('type'),
    addAddress: () => setModalStep('address'),
    calendar: () => setModalStep('scheduling'),
    stores: () => setModalStep('type'),
    scheduling: (type: string | null) =>
      type === 'shop' ? setModalStep('stores') : setModalStep('address'),
  };

  return (
    <Modal
      show={show}
      animation={false}
      centered={true}
      onHide={() => {
        handleClose();
        setShowModal(false);
        setModalStep('type');
      }}
    >
      <Modal.Header closeButton className="p-2 d-flex align-items-center">
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div className="d-flex w-100 align-items-center">
            {modalStep !== 'type' && (
              <IoArrowBackCircle
                size="18pt"
                color="#333"
                className="mr-1 cursor-pointer"
                onClick={() => {
                  arrowActionByStep[modalStep](shipment.type);
                }}
              />
            )}

            <h6 className="mb-0">{renderModalTitleByStep(modalStep)}</h6>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body className="p-2">
        <>{renderModalContent(modalStep)}</>
      </Modal.Body>

      {modalStep === 'address' && (
        <Modal.Footer className="p-2 border-top-0">
          <Button
            variant="success"
            size="sm"
            className="w-100"
            onClick={() => {
              setModalStep('addAddress');
            }}
          >
            Adicionar endereço
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default AddressModal;
