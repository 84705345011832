import { memo, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { isLightOrDark } from '@/services/lightOrDark';

interface Props {
  subtitle: string;
  show: boolean;
  setShow: (show: boolean) => void;
}

function FilterSubTitle({ subtitle, show, setShow }: Props) {
  const { configs } = useWebContext();

  const bgType = isLightOrDark(configs.bg_color);

  useEffect(() => setShow(show), [show]);

  return (
    <div className={`d-flex align-items-center justify-content-between bg-color-${bgType}`}>
      <span className="filter-subtitle">{subtitle}</span>
      <span className="collapse-icon" onClick={() => setShow(!show)}>
        {show ? <IoIosArrowDown /> : <IoIosArrowUp />}
      </span>
    </div>
  );
}

export default memo(FilterSubTitle);
