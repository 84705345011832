import { FunctionComponent, ReactNode } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { generateRandomKey } from '../../../services/global';
import BadgeLoad from './BadgeLoad';
import ButtonLoad from './ButtonLoad';
import CartProductLoad from './CartProductLoad';
import ImageLoad from './ImageLoad';
import TextLoad from './TextLoad';

interface ShipmentCardLoadProps {
  badgeWidth: string;
  children: ReactNode;
}

const ShipmentCardLoad: FunctionComponent<ShipmentCardLoadProps> = ({ children, badgeWidth }) => {
  return (
    <Card className="shipment-card">
      <Card.Header className="p-0">
        <BadgeLoad width={badgeWidth} styles={{ top: 5 }} />
      </Card.Header>

      <Card.Body>
        <div className="left">{children}</div>

        <div className="right my-auto">
          <div className="actions">
            <ButtonLoad size="xs" width="55px" styles={{ borderRadius: '1rem' }} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

function CartPageLoad() {
  const emptyArray = new Array(3).fill('');

  return (
    <Row>
      <Col xs={12} md={8}>
        <Card className="shipment-card mb-3 mb-sm-2">
          <ul className="list-unstyled">
            {emptyArray.map(() => (
              <CartProductLoad key={generateRandomKey()} />
            ))}
          </ul>
        </Card>
      </Col>

      <Col xs={12} md={4}>
        <div className="shipment mb-2 mt-4">
          <ShipmentCardLoad badgeWidth="105px">
            <div className="avatar">
              <ImageLoad width="40px" height="40px" styles={{ borderRadius: '100%' }} />
            </div>

            <div className="info">
              <TextLoad width="105px" />
              <TextLoad width="150px" />
            </div>
          </ShipmentCardLoad>

          <ShipmentCardLoad badgeWidth="70px">
            <div className="d-flex flex-column">
              <div className="shipment-type mb-1">
                <BadgeLoad width="100px" />
              </div>

              <div className="shipment-address flex-column">
                <BadgeLoad width="200px" />
                <BadgeLoad width="100px" styles={{ margin: '.125rem 0 .125rem 1rem' }} />
                <BadgeLoad width="60px" />
              </div>
            </div>
          </ShipmentCardLoad>

          <ShipmentCardLoad badgeWidth="167px">
            <div className="d-flex flex-column align-items-start">
              <BadgeLoad width="65px" styles={{ marginBottom: '.125rem' }} />
              <BadgeLoad width="220px" />
            </div>
          </ShipmentCardLoad>
        </div>

        <div className="my-2">
          <div className="d-flex align-items-center">
            <TextLoad width="170px" />
          </div>

          <Card>
            <Card.Body className="pt-1 pl-1">
              <BadgeLoad width="80%" />
            </Card.Body>
          </Card>
        </div>

        <Card className="cart-resume my-2">
          <Card.Body>
            <h3 className="discount">
              <BadgeLoad width="70px" />
              <BadgeLoad width="50px" />
            </h3>

            <h3 className="delivery-rate my-1">
              <BadgeLoad width="100px" />
              <BadgeLoad width="60px" />
            </h3>

            <hr className="my-1" />

            <h3 className="total mb-2">
              <BadgeLoad width="35px" />
              <TextLoad width="90px" />
            </h3>

            <ButtonLoad width="100%" />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default CartPageLoad;
