import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryParam } from '@/resources/hooks/useQueryParam';
import { translateHighlightType } from '@/services/highlights';

import { getMultipleHighlightGroup } from '../../../routes/categories';
import { cleanCategoryGroup } from '../../../store/ducks/categories/actions';
import { PageContext } from '../../components/contexts/PageContext';
import { useTemplateByPage } from '../../hooks/useTemplateByPage';
import { useWebContext } from '../../hooks/useWebContext';
import Default from '../../themes/category/default/Default';

function Category() {
  const history = useHistory();
  const dispatch = useDispatch();
  const template = useTemplateByPage('category');

  const { order, selectBase64 } = useWebContext();
  const { id, names, base64 } = useParams<{
    id: string;
    names: string;
    base64: string;
  }>();

  const [highlightId, setHighlightId] = useState<number>();

  // @ts-ignore
  const type: 'category' | 'group' = window.location.pathname.split('/')[1] ?? 'category';

  const renderCategoryTemplate = () => {
    switch (template?.template_name) {
      default:
        return (
          <Default
            id={highlightId}
            shopId={order?.dst_shop_id ?? null}
            type={translateHighlightType(type)}
          />
        );
    }
  };

  const brands = useQueryParam('brands');

  useEffect(() => {
    const decodedId = +id || atob(id);

    setHighlightId(+decodedId);
    // eslint-disable-next-line
  }, [id, names, base64, selectBase64, history, type]);

  useEffect(() => {
    if (highlightId && order?.dst_shop_id) {
      getMultipleHighlightGroup({
        loading: true,
        id: highlightId,
        shopId: order.dst_shop_id,
        type: translateHighlightType(type),
        brands: brands?.split(',') || undefined,
      });
    }

    return () => {
      dispatch(cleanCategoryGroup());
    };
    // eslint-disable-next-line
  }, [highlightId, order?.dst_shop_id, dispatch]);

  return (
    <PageContext.Provider value={{ template }}>{renderCategoryTemplate()}</PageContext.Provider>
  );
}

export default Category;
