import { Reducer } from 'redux';

import { IProductList, IProductListsState, ProductListsTypes } from './types';

const initialState: IProductList = {
  products: [],
  error: false,
  loading: true,
};

const DEFAULT_STATE: IProductListsState = {
  offers: initialState,
  bestSellers: initialState,
  clientOffers: initialState,
  randomOffers: initialState,
  superOffers: initialState,
  giftClub: initialState,
  freeShipping: initialState,
  category: initialState,
  group: initialState,
};

const reducer: Reducer<IProductListsState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ProductListsTypes.SET_OFFERS:
      return { ...state, offers: action.payload };
    case ProductListsTypes.SET_BEST_SELLERS:
      return { ...state, bestSellers: action.payload };
    case ProductListsTypes.SET_CLIENT_OFFERS:
      return { ...state, clientOffers: action.payload };
    case ProductListsTypes.SET_RANDOM_OFFERS:
      return { ...state, randomOffers: action.payload };
    case ProductListsTypes.SET_GIFT_CLUB:
      return { ...state, giftClub: action.payload };
    case ProductListsTypes.SET_SUPER_OFFERS:
      return { ...state, superOffers: action.payload };
    case ProductListsTypes.SET_FREE_SHIPPING:
      return { ...state, freeShipping: action.payload };
    case ProductListsTypes.SET_CATEGORY:
      return { ...state, category: action.payload };
    case ProductListsTypes.SET_GROUP:
      return { ...state, group: action.payload };
    default:
      return state;
  }
};

export default reducer;
