import { BiChevronRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import { getHighlightLink } from '@/services/highlights';

import { ICategory } from '../../../store/ducks/categories/types';

interface Props {
  color: string;
  className: string;
  category: ICategory;
  isInDropdown?: boolean;
  type: 'category' | 'group';
}

function CategoryLink({ color, category, className, isInDropdown = false, type }: Props) {
  return (
    <li className={className}>
      <Link style={{ color }} className="d-block py-1" to={getHighlightLink(type, category)}>
        <div className="d-flex align-items-center justify-content-between">
          <span
            className="mr-1"
            style={
              isInDropdown
                ? { overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100% - 24px)' }
                : {}
            }
          >
            {category.webapp_name ?? category.name}
          </span>

          {isInDropdown && <BiChevronRight size="18" className="ml-auto" />}
        </div>
      </Link>
    </li>
  );
}

export default CategoryLink;
