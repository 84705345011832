import { useWindowSize } from './useWindowSize';

export const useDeviceWindow = () => {
  const { width } = useWindowSize();

  const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  const mobileWidthThreshold = 768;

  return width < mobileWidthThreshold || hasTouch ? { device: 'mobile' } : { device: 'desktop' };
};
