import { toast, TypeOptions } from 'react-toastify';

import { ApplicationState, store } from '../store';
import { IAttendanceType, ICart, IOrder, MethodType } from '../store/ducks/order/types';
import { IProduct } from '../store/ducks/products/types';

export enum OrderStatus {
  OPEN = 1,
  WAITING_SELLER = 2,
  IN_ATTENDANCE = 3,
  CLOSED = 4,
  ABANDONED = 5,
  WAITING_CLIENT = 6,
  CLOSED_ABANDONED = 7,
  ROBOT_POST_SALE = 8,
  SELLER_POST_SALE = 9,
  CANCELED = 10,
  DELIVERY = 11,
  SEPARATED = 12,
}

export enum MedicineStripe {
  BLACK = 1,
  RED = 2,
}

interface IcanChangeOrder {
  type: 'item' | 'pedido' | 'cliente' | 'forma de entrega' | 'pagamento';
  action: 'adicionado' | 'alterado' | 'removido' | 'finalizado' | 'enviado';
}

const ucFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

const masks = {
  cpf: { regex: /(\d{3})(\d{3})(\d{3})(\d{2})/, replacer: '$1.$2.$3-$4' },
  cpfOvershadow: { regex: /(\*{3})(\d{3})(\d{3})(\*{2})/, replacer: '$1.$2.$3-$4' },
  cpnj: { regex: /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, replacer: '$1.$2.$3/$4-$5' },
  phone: { regex: /(\d{2})(\d{2})(\d{5}|\d{4})(\d{4})/, replacer: '($2) $3-$4' },
  phoneOvershadow: { regex: /(\d{2})(\d{2})(\*{5})(\*{2})(\d{2})/, replacer: '($2) $3-$4$5' },
  zipcode: { regex: /(\d{5})(\d{3})/, replacer: '$1-$2' },
  number: { regex: /\D+/g },
  numberShadow: { regex: /[^\d*]/g },
  bold: { regex: /(\*)([^*]+?)(\1)/g, replacer: '$2' },
  italic: { regex: /(_)([^_]+?)(\1)/g, replacer: '$2' },
  mono: { regex: /(`{3})([^*]+?)(\1)/g, replacer: '$2' },
  line: { regex: /(~)([^~]+?)(\1)/g, replacer: '$2' },
};

export const canUseWebappInStatuses = ({ status }: { status: number }) => {
  return [1, 2, 3, 5, 6].includes(status);
};

export enum OrderDiscountRoles {
  VOUCHER = 1,
  MANAGER = 2,
  SELLER = 3,
  REWARD = 4,
}

export const hasManagerDiscount = (order: IOrder | null) => {
  return !!order?.discounts.some((discount) => discount.role === OrderDiscountRoles.MANAGER);
};

const getMessageByStatus = (status: number) => {
  switch (status) {
    case OrderStatus.ROBOT_POST_SALE:
    case OrderStatus.SELLER_POST_SALE:
      return 'O seu pedido já está em separação.';
    default:
      return null;
  }
};

export const canChangeOrder = ({
  type,
  action,
}: IcanChangeOrder): {
  isValid: boolean;
  showIsCartBlockedModal: boolean;
  blockOnlyProduts?: boolean;
} => {
  const state: ApplicationState = store.getState();
  const options: { type: TypeOptions; autoClose: number } = { type: 'warning', autoClose: 4000 };

  const status: number = state.order.data?.status ?? 1;
  const shopIsOpen: boolean = state.shopConfig.data.is_open;
  const shopSchedule: string = state.shopConfig.data.schedule;
  const createdInERP = state.order.data?.integration_code;
  const isPaid: boolean = state.order.data?.is_paid ?? false;
  const isCartBlocked: boolean = !canUseWebappInStatuses({ status }) || !!createdInERP;
  const editCart: boolean = state.settings.configs.client_cart_edit_outside_time;
  const closeOrder: boolean = state.settings.configs.client_finish_order_outside_time;
  const managerDiscount = hasManagerDiscount(state.order.data);

  if (shopIsOpen === false && editCart === false)
    toast(
      `${ucFirst(type)} não pode ser ${action} pois a loja está fechada. ${
        shopSchedule ? `Horário de funcionamento: ${shopSchedule.replace('-', ' às ')}` : ''
      }`,
      options,
    );
  else if (shopIsOpen === false && closeOrder === false && action === 'finalizado')
    toast(
      `${ucFirst(type)} não pode ser ${action} pois a loja está fechada. ${
        shopSchedule ? `Horário de funcionamento: ${shopSchedule.replace('-', ' às ')}` : ''
      }`,
      options,
    );
  else if (isPaid)
    toast(`${ucFirst(type)} não pode ser ${action} pois o pedido já foi pago.`, options);
  else if (createdInERP)
    toast(`${ucFirst(type)} não pode ser ${action} pois o pedido já foi criado.`, options);
  else if (isCartBlocked) {
    const message = getMessageByStatus(status);

    if (message) toast(message, options);

    return { isValid: false, showIsCartBlockedModal: true };
  } else if (managerDiscount) {
    if (['item', 'pedido', 'cliente'].includes(type)) {
      toast(`${ucFirst(type)} não pode ser ${action} pois o pedido possui desconto.`, options);
    }

    return { isValid: false, showIsCartBlockedModal: false, blockOnlyProduts: true };
  } else return { isValid: true, showIsCartBlockedModal: false };

  return { isValid: false, showIsCartBlockedModal: false };
};

export const canFinishOrder = (shipmentStep: 1 | 2 | 3 | 4) => {
  if (shipmentStep === 1)
    toast('Se identifique antes de finalizar o pedido!', {
      type: 'warning',
    });
  else if (shipmentStep === 2)
    toast('Escolha a forma de entrega antes de finalizar o pedido!', {
      type: 'warning',
    });
  else if (shipmentStep === 3)
    toast('Escolha as opções de pagamento antes de finalizar o pedido!', {
      type: 'warning',
    });
  else return true;

  return false;
};

const translate = (status: number | undefined) => {
  switch (status) {
    case 1:
      return 'em Atendimento robô';
    case 2:
      return 'Aguardando Atendente';
    case 3:
      return 'em Atendimento';
    case 4:
      return 'Finalizado';
    case 5:
      return 'Abandonado';
    case 6:
      return 'Aguardando Cliente';
    case 7:
      return 'Fechado por abandono';
    case 8:
      return 'em Separação/robô';
    case 9:
      return 'em Separação';
    case 11:
      return 'em Delivery';
    case 12:
      return 'Separado';
    default:
      return 'default';
  }
};

export const translateOrderStatus = (status: number) => translate(status);

export const maskPhone = (identification: string, overshadow?: boolean) => {
  const pattern = !overshadow ? masks.phone.regex : masks.phoneOvershadow.regex;

  const mask = new RegExp(pattern, 'g');

  const replacer = !overshadow ? masks.phone.replacer : masks.phoneOvershadow.replacer;

  return identification.replace(mask, replacer);
};

// eslint-disable-next-line func-names
export const delay = (function () {
  let timer: any = 0;

  // eslint-disable-next-line func-names
  return function (callback: (...args: any[]) => void, ms: number) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);

    return timer;
  };
})();

export const generateRandomKey = (): string =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const revertMoneyMaskToFloat = (quantity: string) =>
  parseFloat(quantity.toString().replace(',', '.'));

export const breakInParts = ({
  products,
  breakLimit = 10,
}: {
  products: IProduct[];
  breakLimit?: number;
}): { brokenProducts: IProduct[][] } => {
  let count = 0;
  const brokenProducts: IProduct[][] = [];

  products?.forEach((_, index) => {
    const length = index + 1;

    if (length % breakLimit === 0) {
      const getProducts = products.slice(breakLimit * count, length);

      brokenProducts.push(getProducts);
      count += 1;
    }

    if (length === products.length) {
      brokenProducts.push(products.slice(breakLimit * count));
    }
  });

  return { brokenProducts };
};

export const slug = (
  str: string,
  convert: 'space-to-hifen' | 'hifen-to-space' = 'space-to-hifen',
): string => {
  const cleanStr = str
    .replace(/%/g, '%25')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return convert === 'hifen-to-space' ? cleanStr.replace(/-/g, ' ') : cleanStr.replace(/ /g, '-');
};

export const maskZipcode = (zipcode: string) => {
  if (!zipcode) return '';

  const pattern = masks.zipcode.regex;
  const mask = new RegExp(pattern, 'g');

  return zipcode.replace(/\D+/g, '').slice(0, 8).replace(mask, masks.zipcode.replacer);
};

export const formatAddressNumber = (number?: string | null) => {
  if (!number) return 'S/N';

  const mask = number.substr(0, number.length - 1).replace(/\d/g, 'x');
  const lastNumber = number.substr(-1);

  return mask + lastNumber;
};

export const getFullAddress = (
  address: {
    number?: string | null;
    reference?: string | null;
    complement?: string | null;
    neighborhood?: string | null;
    zipcode?: string | null;
    street?: string | null;
  },
  mask?: boolean,
) => {
  const formattedNumber = mask ? formatAddressNumber(address.number) : address.number;

  const number = address.number ? formattedNumber : 'S/N';
  const reference = address.reference ? `- ${address.reference}` : '';
  const complement = address.complement ? `- ${address.complement}` : '';
  const neighborhood = address.neighborhood ? `- ${address.neighborhood}` : '';
  const zipcode = address.zipcode ? ` - ${maskZipcode(address.zipcode)}` : '';
  const street = address.street ? `${address.street},` : '';

  const fullAddress = `${street} ${number} ${neighborhood} ${zipcode} ${complement} ${reference}`;
  const maskedAddress = `${street} ${number} ${neighborhood}`;

  return mask ? maskedAddress : fullAddress;
};

export const maskDocument = (document: string, overshadow?: boolean) => {
  const cpfPattern = !overshadow ? masks.cpf.regex : masks.cpfOvershadow.regex;
  const cnpjPattern = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

  if (!document || typeof document === 'undefined') return '';

  const cpf = new RegExp(cpfPattern, 'g');
  const cnpj = new RegExp(cnpjPattern, 'g');

  const documentFormatted = document.replace(
    overshadow ? masks.numberShadow.regex : masks.number.regex,
    '',
  );

  return documentFormatted.length <= 11
    ? documentFormatted.replace(cpf, masks.cpf.replacer)
    : documentFormatted.slice(0, 14).replace(cnpj, masks.cpnj.replacer);
};

export const maskCpf = (cpf: string) => {
  const cpfPattern = masks.cpf.regex;

  const cpfRegExp = new RegExp(cpfPattern, 'g');

  return cpf.replace(masks.number.regex, '').slice(0, 11).replace(cpfRegExp, masks.cpf.replacer);
};

export const translatePaymentMethod = (paymentMethod: MethodType) => {
  const translated = {
    pix: 'Pix',
    bill: 'Boleto',
    debit: 'Débito',
    cash: 'Dinheiro',
    picpay: 'Picpay',
    credit: 'Crédito',
    'bank-transfer': 'Transferência Bancária',
    'multi-pay': 'Multi-Pagamentos',
    'meal-voucher': 'Vale Refeição',
    'food-voucher': 'Vale Alimentação',
    convention: 'Convênio',
  };

  return translated[paymentMethod] ?? paymentMethod;
};

export const removeMask = (str: string) => str.replace(/[^\d]/g, '');

const getCpfCnpjRegex = (length: number) => {
  const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  return new RegExp(array.map((num) => `${num}{${length}}`).join('|'));
};

const iterateCpfSum = (acc: number, curr: number, index: number, array: number[]) => {
  const length = array.length + 1;

  const newAcc = index === 1 ? acc * length : acc;

  return newAcc + curr * (length - index);
};

const iterateCpnjSum = (acc: number, curr: number, index: number, array: number[]) => {
  const nuns = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  const newIndex = array.length === 12 ? index + 1 : index;

  const nunsIndex = nuns[newIndex] ?? 0;

  return acc + curr * nunsIndex;
};

const validateCpfDigit = (subString: string, checkerDigit: string) => {
  const array = subString.split('').map(Number);
  const sum = array.reduce(iterateCpfSum);
  const remnant = (sum * 10) % 11;
  const digit = remnant === 10 || remnant === 11 ? 0 : remnant;

  return digit === +checkerDigit;
};

const validateCnpjDigit = (subString: string, checkerDigit: string) => {
  const array = subString.split('').map(Number);
  const sum = array.reduce(iterateCpnjSum, 0);
  const remnant = sum % 11;
  const digit = remnant < 2 ? 0 : 11 - remnant;

  return digit === +checkerDigit;
};

export const validateCpf = (cpf: string) => {
  const regex = getCpfCnpjRegex(11);

  if (regex.test(cpf)) return false;

  const subStrings = [cpf.substring(0, 9), cpf.substring(0, 10)];

  return subStrings.every((str, index) => validateCpfDigit(str, cpf[index + 9] ?? ''));
};

export const validateCnpj = (cnpj: string) => {
  const regex = getCpfCnpjRegex(14);

  if (cnpj.length !== 14 || regex.test(cnpj)) return false;

  const subStrings = [cnpj.substring(0, 12), cnpj.substring(0, 13)];

  return subStrings.every((str, index) => validateCnpjDigit(str, cnpj[index + 12] ?? ''));
};

export const validateCpfOfCnpj = (cpfOrCnpj: string) => {
  const formattedcpfOrCnpj = cpfOrCnpj.replace(masks.number.regex, '');

  if (formattedcpfOrCnpj.length === 11) return validateCpf(formattedcpfOrCnpj);

  if (formattedcpfOrCnpj.length === 14) return validateCnpj(formattedcpfOrCnpj);

  return false;
};

export const getCartSubtotal = (cart: ICart) => {
  return cart.products.reduce((sum, product) => {
    if (!product.is_available) return sum;

    const productWeight = product.see_as_unit ? product.average_weight : 1;
    const item = product.subtotal * product.quantity * productWeight;

    return sum + item;
  }, 0);
};

export const roundUp = (number: number) => {
  return Math.ceil(Math.round(number * 100)) / 100;
};

export const getCartDiscounts = (cart: ICart) => {
  const totalOfDiscounts = cart.products.reduce((sum, product) => {
    if (!product.is_available) return sum;

    const productWeight = product.see_as_unit ? product.average_weight : 1;
    const itemDiscount = roundUp(product.subtotal * product.quantity * productWeight);

    return roundUp(sum + roundUp(itemDiscount - product.subtotal));
  }, 0);

  const cartDiscount = cart.discount ?? 0;

  return roundUp(totalOfDiscounts + cartDiscount);
};

export const maskMoney = (number: number, showSymbol: boolean, symbol?: string) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: symbol ?? 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...(showSymbol && { style: 'decimal' }),
  });

  return formatter.format(number);
};

export const getOrderMinValue = (order: IOrder | null) => {
  const type = order?.shipment.delivery?.type;

  if (!type) return 0;

  return Number(
    order?.shipment.attendance_types?.[type as keyof IAttendanceType]?.order_min_value ?? 0,
  );
};

export const unmaskNumber = (number: string) => {
  return number.replace(masks.number.regex, '');
};

export const translatedPlaces: any = {
  route: 'street',
  country: 'country',
  postal_code: 'zipcode',
  street_number: 'number',
  sublocality_level_1: 'neighborhood',
  administrative_area_level_2: 'city',
  administrative_area_level_1: 'state',
};

export const removeMarkdown = (text: string) => {
  const formats = [masks.bold, masks.italic, masks.mono, masks.line];

  const textFormatted = formats.reduce((textFormated, { regex, replacer }) => {
    return textFormated.replace(regex, replacer);
  }, text);

  return textFormatted;
};
