import { Reducer } from 'redux';

import { DeliveryDiscountTypes, IDeliveryDiscountState } from './types';

const DEFAULT_STATE: IDeliveryDiscountState = {
  data: [],
  error: false,
  loading: false,
};

const reducer: Reducer<IDeliveryDiscountState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case DeliveryDiscountTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case DeliveryDiscountTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload };
    case DeliveryDiscountTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    default:
      return state;
  }
};

export default reducer;
