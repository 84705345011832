/**
 * Action types
 */
export enum IntegrationTypes {
  LOAD_REQUEST = '@integration/LOAD_REQUEST',
  LOAD_SUCCESS = '@integration/LOAD_SUCCESS',
  LOAD_FAILURE = '@integration/LOAD_FAILURE',
  SET_DELIVERY = '@integration/SET_DELIVERY',
  SET_STORES = '@integration/SET_STORES',
}

/**
 * Data Types
 */
type GroupingType = 'communication' | 'general' | 'payment' | 'delivery' | 'packages' | 'erp';
type IntegrationType = 'in-store' | 'own-delivery-manual' | 'google-maps';

export interface IIntegration {
  id: number;
  type: IntegrationType;
  grouping: GroupingType;
  shop_id: number;
  data: IIntegrationData;
}

interface IIntegrationData {
  description: string | null;
  name: string | null;
  order_min_value: number;
}

export interface IIntegrationState {
  readonly error: boolean;
  readonly loading: boolean;
  readonly data: IIntegration[];
}
