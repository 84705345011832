import { Reducer } from 'redux';

import { IIntegrationState, IntegrationTypes } from './types';

const DEFAULT_STATE: IIntegrationState = {
  data: [],
  error: false,
  loading: false,
};

const reducer: Reducer<IIntegrationState> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case IntegrationTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case IntegrationTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload };
    case IntegrationTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    default:
      return state;
  }
};

export default reducer;
