import './wholeSales.scss';

import { CSSProperties } from 'react';

import { useWebContext } from '@/resources/hooks/useWebContext';

import { IProduct } from '../../../../store/ducks/products/types';
import { useFormatMoney } from '../../../hooks/useFormatMoney';
import { useTextColor } from '../../../hooks/useTextColor';

interface Props {
  fullWidth?: boolean;
  product: IProduct | null;
  marginTop?: 0 | 1 | 2 | 3 | 4 | 5;
}

function WholeSalesPrice({ product, fullWidth = false, marginTop = 1 }: Props) {
  const { configs, order } = useWebContext();
  const backgroundColor =
    configs.color === '#ffffff' || configs.color === '#fff' ? '#25d366' : configs.color;
  const color = useTextColor(backgroundColor);

  const wholesales = product?.alerts.find((alert) => alert.type === 'wholesales');
  const wholeSalesPrice = useFormatMoney(wholesales?.price ?? 0);

  const fixMargin = { margin: fullWidth ? 'auto' : '' };
  const fixWidth: CSSProperties = fullWidth ? { width: '100%' } : {};

  const hasClientValidation = () => {
    if (configs.use_clients_wholesales && order?.client.use_wholesale_price) {
      return true;
    }

    return !configs.use_clients_wholesales;
  };

  const showWholesale =
    wholesales &&
    wholesales?.min_quantity &&
    wholesales.min_quantity > 0 &&
    configs.show_wholesales_price &&
    hasClientValidation();

  return (
    <>
      {showWholesale && (
        <div
          style={{ backgroundColor, color, ...fixMargin, ...fixWidth }}
          className={`whole-sales mt-${marginTop}`}
          title={`A partir de ${wholesales.min_quantity} unid. R$ ${wholeSalesPrice}`}
        >
          <span>A partir de </span>
          <strong>{wholesales.min_quantity} </strong>
          <span>unid. </span>
          <small>R$ </small>
          <strong>{wholeSalesPrice}</strong>
        </div>
      )}
    </>
  );
}

export default WholeSalesPrice;
