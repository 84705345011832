import { Reducer } from 'redux';

import { IProductsGroupByCategoryState, ProductsTypes } from './types';

const defaultState: IProductsGroupByCategoryState = {
  data: [],
  error: false,
  loading: false,
  productLoading: false,
  chosenProduct: undefined,
  specProducts: [],
};

const reducer: Reducer<IProductsGroupByCategoryState> = (state = defaultState, action) => {
  switch (action.type) {
    case ProductsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ProductsTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: [...state.data, ...action.payload.data],
      };
    case ProductsTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    case ProductsTypes.SET_PRODUCT_LOADING:
      return { ...state, productLoading: true };
    case ProductsTypes.SET_CHOSEN_PRODUCT:
      return { ...state, chosenProduct: action.payload, productLoading: false };
    case ProductsTypes.REMOVE_CHOSEN_PRODUCT:
      return { ...state, chosenProduct: undefined };
    case ProductsTypes.SET_SPEC_PRODUCTS:
      return { ...state, specProducts: action.payload, productLoading: false };
    case ProductsTypes.CLEAN_DATA:
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
        chosenProduct: undefined,
        eanProducts: [],
      };
    default:
      return state;
  }
};

export default reducer;
